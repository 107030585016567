import { Flex, Text } from "@mantine/core";
import { isNil } from "@zozia/std";
import * as React from "react";

import { type DeliveryType, type OrderStatus } from "../../graphql/graphql";
import { Section } from "../../session/components/Section";
import { EstimatedDeliveryTime } from "./EstimatedDeliveryTime";

type EstimatedDeliveryTimeSectionProps = {
  deliveryOptionType: DeliveryType;
  estimatedDeliveryTime: number;
  lastUpdated: number;
  orderStatus: OrderStatus;
};

export const EstimatedDeliveryTimeSection = ({
  deliveryOptionType,
  estimatedDeliveryTime,
  lastUpdated,
  orderStatus,
}: EstimatedDeliveryTimeSectionProps) => {
  if (
    isNil(orderStatus) ||
    ["Disputed", "Canceled", "Rejected"].includes(orderStatus)
  ) {
    return null;
  }

  const isPickup = deliveryOptionType === "Pickup";

  const shouldRenderEstimatedDeliveryTime =
    estimatedDeliveryTime && lastUpdated;

  const isDelivered = ["Delivered", "Done", "ReadyToPickUp"].includes(
    orderStatus,
  );

  const title = isDelivered
    ? ["Delivered", "Done"].includes(orderStatus)
      ? "Dostarczone"
      : "Gotowe do odbioru"
    : `Szacowany czas ${isPickup ? "odbioru" : "dostawy"}`;

  return !!shouldRenderEstimatedDeliveryTime ? (
    <Section title={title} inset="clear">
      <Flex justify="center">
        <EstimatedDeliveryTime
          orderStatus={orderStatus}
          estimatedDeliveryTime={estimatedDeliveryTime}
          lastUpdated={lastUpdated}
          isPickup={isPickup}
          isDelivered={isDelivered}
        />
      </Flex>
    </Section>
  ) : (
    <Section title={title} inset="clear">
      <Text className="text-center">
        Oczekiwanie na ustawienie terminu dostawy
      </Text>
    </Section>
  );
};
