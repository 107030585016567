import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useEventListener } from "@mantine/hooks";
import {
  IconChevronRight,
  IconPackage,
  IconPaperBag,
  IconRecycle,
  IconShoppingBag,
} from "@tabler/icons-react";
import cn from "classnames";
import { motion } from "framer-motion";
import { close } from "ionicons/icons";
import * as React from "react";

import { useLocationDeliveryPackingOptionsQuery } from "../../graphql/graphql";

export const LocationDeliveryPackingSelector2 = ({
  value,
  locationId,
  onChange,
  error,
}) => {
  const [_value, setValue] = React.useState(value);
  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const {
    data,
    isLoading,
    refetch: refetchDeliveryPackingOptions,
  } = useLocationDeliveryPackingOptionsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.locationDeliveryPackingOptions || [],
      enabled: !!locationId,
    },
  );

  const selectedOption = data?.find((option) => option.id === _value);

  return (
    <div className="relative">
      <LoadingOverlay visible={isLoading} />

      {isLoading ? (
        <DeliveryPackingOption
          name="Apple Pay"
          icon={<IconPackage />}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
        />
      ) : (
        <DeliveryPackingOption
          name={selectedOption?.name}
          error={error}
          icon={getIcon(selectedOption?.name)}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
          description={selectedOption?.description}
        />
      )}

      <IonModal
        ref={modalRef}
        initialBreakpoint={0.7}
        breakpoints={[0, 0.7]}
        onIonModalWillPresent={() => {
          refetchDeliveryPackingOptions();
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  modalRef.current.dismiss();
                }}
              >
                <IonIcon size="large" icon={close} color="black" />
              </IonButton>
            </IonButtons>
            <IonTitle>Metody pakowania</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Stack className="px-4">
            {data?.map((option, idx) => {
              const icon = getIcon(option.name);
              let rightSection;

              switch (option.name) {
                case "ApplePay":
                  break;
                case "Card":
                case "Online":
                  break;
                case "Cash":
                  rightSection = <IconChevronRight />;
                  break;
                default:
              }

              return (
                <motion.div
                  key={option.id}
                  style={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, delay: idx * 0.2 }}
                >
                  <DeliveryPackingOption
                    onClick={() => {
                      setValue(option.id);
                      onChange({ deliveryPackingOptionId: option.id });
                      modalRef.current.dismiss();
                    }}
                    description={option.description}
                    key={option.id}
                    name={option.name}
                    rightSection={rightSection}
                    icon={icon}
                  />
                </motion.div>
              );
            })}
          </Stack>
        </IonContent>
      </IonModal>
    </div>
  );
};

type DeliveryPackingOptionProps = {
  onClick?: () => void;
  name: string;
  description?: string;
  icon: React.ReactElement;
  rightSection?: React.ReactNode;
  error?: string;
};
const DeliveryPackingOption = ({
  onClick = () => {},
  name,
  error,
  icon,
  description,
  rightSection,
}: DeliveryPackingOptionProps) => {
  const onClickHandler = async () => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });

    onClick();
  };
  const ref = useEventListener("click", onClickHandler);
  return (
    <Group
      className={cn("border border-solid rounded-md py-4 pl-4 pr-2", {
        "border-red-500": error,
      })}
      role="button"
      ref={ref}
    >
      <Group
        justify="space-between"
        wrap="nowrap"
        className="w-full"
        align="start"
      >
        <div className="justify-start min-w-[30px]">{icon}</div>
        <Stack gap={0} className="w-full grow">
          <Text size="sm" fw={600}>
            Pakowanie
          </Text>
          <Text
            size="xs"
            className={cn({
              "text-gray-400": !name,
            })}
          >
            {name || "Wybierz metodę pakowania"}
          </Text>
          {description ? (
            <Text size="xs" className="text-gray-400 shrink-0">
              {description}
            </Text>
          ) : null}
          {error ? (
            <Text size="xs" c="red" className="shrink-0">
              {error}
            </Text>
          ) : null}
        </Stack>
        <div className="self-center">{rightSection ? rightSection : null}</div>
      </Group>
    </Group>
  );
};

const getIcon = (type: string = "") => {
  const name = type.toLowerCase();
  if (name.includes("torba")) {
    return <IconPaperBag />;
  } else if (name.includes("recykling")) {
    return <IconRecycle />;
  } else {
    return <IconShoppingBag />;
  }
};
