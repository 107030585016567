import { type ImageAnimationWrapperProps, cropImageTo } from "@zozia/ui";
import { motion } from "framer-motion";
import * as React from "react";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";

const cropImageToCoverSize = cropImageTo(200, window.outerWidth);

const LocationCoverImageAnimationWrapper = ({
  children,
}: ImageAnimationWrapperProps) => {
  return (
    <motion.div
      style={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
    >
      {children}
    </motion.div>
  );
};

type LocationCoverImageProps = {
  image?: { src?: string; blurhash?: string };
};

const onLoaded = () => {};

export const LocationCoverImage = ({ image }: LocationCoverImageProps) => {
  return (
    <AutoAlignImage
      radius="none"
      animation={LocationCoverImageAnimationWrapper}
      onLoaded={onLoaded}
      blurhash={image.blurhash}
      src={
        image.src
          ? cropImageToCoverSize(image.src)
          : `https://picsum.photos/375/200?random=${Math.random()}`
      }
    />
  );
};
