import pino from "pino";

export const logger = pino({
  browser: { asObject: true },
});

export class Logger {
  constructor({ name }) {
    this.logger = logger.child({ name });
  }

  info(message) {
    this.logger.info(message);
  }
}
