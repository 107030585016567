import { ActionIcon, Group, Text } from "@mantine/core";
import { usePrevious } from "@mantine/hooks";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import * as React from "react";

import { Count } from "../../locations/components/Count/Count";

type ChangeQuantityProps = {
  onIncrease: (quantity: number) => void;
  onDecrease: (quantity: number) => void;
  initialValue?: number;
  isLoading: boolean;
};

export const ChangeQuantity = ({
  initialValue,
  onIncrease,
  onDecrease,
  isLoading,
}: ChangeQuantityProps) => {
  const previousValue = usePrevious(initialValue);

  React.useEffect(() => {
    if (typeof initialValue === "number") {
      if (initialValue > 0) {
        setValue(initialValue);
      }

      if (initialValue > 0 && initialValue !== previousValue) {
        setValue(initialValue);
      }
    }
  }, [initialValue, previousValue]);

  const [value, setValue] = React.useState(initialValue || 1);

  const increase = () => {
    setValue((v) => {
      const nextValue = v + 1;

      onIncrease(nextValue);
      return nextValue;
    });
  };

  const decrease = () => {
    setValue((v) => {
      const nextValue = v - 1;

      onDecrease(nextValue);

      return nextValue;
    });
  };

  return (
    <div className="flex justify-end">
      <Group className="relative rounded-full border-[1.5px] border-solid border-blue-300">
        <ActionIcon
          size="xs"
          c="black"
          variant="subtle"
          onClick={decrease}
          disabled={isLoading}
          className="mx-1"
        >
          <IconMinus />
        </ActionIcon>
        {value === 0 ? null : (
          <Text fw={400}>
            <Count value={value} disableInitialAnimation />
          </Text>
        )}
        <ActionIcon
          size="xs"
          c="black"
          variant="subtle"
          disabled={isLoading}
          onClick={increase}
          className="mx-1"
        >
          <IconPlus />
        </ActionIcon>
      </Group>
    </div>
  );
};
