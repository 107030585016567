import { IonProgressBar } from "@ionic/react";
import { LoadingOverlay, Stack, Text } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { Time } from "@zozia/ui";
import cn from "classnames";
import * as React from "react";

import { OrderStatus } from "../../graphql/graphql";
import css from "./EstimatedDeliveryTime.module.css";

type EstimatedDeliveryTimeProps = {
  estimatedDeliveryTime: number;
  lastUpdated: number;
  isPickup: boolean;
  isDelivered?: boolean;
  orderStatus: OrderStatus;
};

export const EstimatedDeliveryTime = ({
  estimatedDeliveryTime,
  orderStatus,
  lastUpdated,
  isPickup,
  isDelivered,
}: EstimatedDeliveryTimeProps) => {
  const [percentage, setPercentage] = React.useState(0);
  const interval = useInterval(() => {
    const start = lastUpdated;
    const end = estimatedDeliveryTime;
    const today = new Date().getTime();

    const total = end - start;

    const current = today - start;

    const calculatedPercentage = (current / total) * 100;

    const nextPercentage =
      calculatedPercentage === 0 ? 1 : calculatedPercentage;

    setPercentage(nextPercentage);

    if (percentage >= 100) interval.stop();
  }, 1000);

  React.useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  const estimatedDeliveryTimeIsToday = new Date(
    +estimatedDeliveryTime,
  ).getDate();

  const today = new Date().getDate();

  const isToday = estimatedDeliveryTimeIsToday === today;

  const isLoading = percentage === 0;

  const isTimeDone = percentage >= 100 || estimatedDeliveryTime < Date.now();

  React.useEffect(() => {
    if (isTimeDone) {
      interval.stop();

      setPercentage(100);
    }
  }, [isTimeDone]);

  const orderIsDelivered = isDelivered || isTimeDone;

  return (
    <Stack
      data-testid="EstimatedDeliveryTime"
      className={cn("w-full", css.estimatedDeliveryTime)}
    >
      <LoadingOverlay
        data-testid="EstimatedDeliveryTime-LoadingOverlay"
        visible={isLoading}
      />
      {isLoading ? null : (
        <>
          <Stack gap={2} align="center">
            <div>
              {isToday ? (
                <>
                  <span>Dziś o </span>
                  <Time as="span" format="time" value={estimatedDeliveryTime} />
                </>
              ) : (
                <Time
                  as="span"
                  format="datetime"
                  value={estimatedDeliveryTime}
                />
              )}
            </div>
            {orderIsDelivered ? (
              <Text c="gray" size="xs">
                {["Delivered", "Done"].includes(orderStatus) && "Dostarczone"}
                {orderStatus === "ReadyToPickUp" && "Gotowe do odbioru"}
              </Text>
            ) : null}
          </Stack>
          {orderIsDelivered ? null : (
            <IonProgressBar value={percentage / 100} buffer={0} />
          )}
        </>
      )}
    </Stack>
  );
};
