import { Button, Text } from "@mantine/core";
import { IconShoppingCartFilled } from "@tabler/icons-react";
import { motion } from "framer-motion";
import * as React from "react";

import { useCart } from "../CartContext";

type ShowCartFloatingButton = {
  onClick: () => void;
};

export const ShowCartFloatingButton = ({
  onClick,
  ...rest
}: ShowCartFloatingButton) => {
  const { cart } = useCart();
  return (
    <Button
      size="lg"
      radius="xl"
      onClick={onClick}
      component={motion.div}
      {...rest}
    >
      <IconShoppingCartFilled size="32" />

      <Text component="span" size="sm" fw="bold">
        {`${cart?.items?.length ?? 0} Zobacz koszyk`}
      </Text>
    </Button>
  );
};
