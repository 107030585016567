import { Flex, Paper, Text } from "@mantine/core";
import * as React from "react";

export const Card = ({ bgColor, onClick, text }) => {
  return (
    <Paper
      shadow="md"
      p="sm"
      radius="md"
      onClick={onClick}
      className="h-[100px] justify-center"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <Flex className="justify-center items-center h-full">
        <Text c="white" ta="center">
          {text}
        </Text>
      </Flex>
    </Paper>
  );
};
