import {
  IonButtons,
  IonContent,
  IonHeader,
  IonNavLink,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { Alert, Button, Stack, Text } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { IconAlertCircle, IconX } from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { useObservableState } from "observable-hooks";
import * as React from "react";

import { location$ } from "../../../../geolocation/epics";
import { useMyAddressesQuery } from "../../../../graphql/graphql";
import { DeliveryMap } from "../../../../session/pages/adresses/SetDeliveryAddress/DeliveryMap";
import { AdditionalAddressInformationPage } from "./AdditionalAddressInformationPage";

type MapPageProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
};
const zoomOptions = {
  zoomControl: !isPlatform("hybrid"),
};
export const MapPage = ({ forwardRef }: MapPageProps) => {
  const currentLocation = useObservableState(location$, null);

  const { data } = useMyAddressesQuery({
    type: "DeliveryAddress",
  });

  React.useEffect(() => {
    if (data && data.myAddresses.length > 0) {
      const values = data.myAddresses[0].address;
      setLastUserDefinedPosition(values);
    }
  }, [data]);

  const [userForcedPosition, setUserForcedPosition] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [mapLoaded, setMapLoaded] = React.useState(false);

  React.useEffect(() => {
    if (currentLocation || userForcedPosition) {
      const latitude = userForcedPosition?.lat || currentLocation.latitude;
      const longitude = userForcedPosition?.lng || currentLocation.longitude;
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
      )
        .then((res) => res.json())
        .then((data) => {
          const { address: posibleAddress } = data;

          const accurateAddress = tryToGetMostAccurateAddress(posibleAddress);
          setAddress(accurateAddress);
        });
    }
  }, [currentLocation, userForcedPosition]);

  const [lastUserDefinedPosition, setLastUserDefinedPosition] =
    React.useState();

  useTimeout(
    () => {
      setMapLoaded(true);
    },
    100,
    { autoInvoke: true },
  );

  const lastSavedAddress =
    lastUserDefinedPosition &&
    tryToGetMostAccurateAddress(lastUserDefinedPosition);

  const canTreatingAsSameLocation =
    lastSavedAddress?.primary === address?.primary;

  const onDragEnd = React.useCallback((latLng) => {
    setUserForcedPosition(latLng);
  }, []);
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Button
              size="compact-xs"
              color="black"
              variant="transparent"
              onClick={() => forwardRef.current.dismiss()}
            >
              <IconX />
            </Button>
          </IonButtons>
          <IonTitle>Adres dostawy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {!canTreatingAsSameLocation && lastSavedAddress ? (
          <Alert
            variant="light"
            color="yellow"
            icon={<IconAlertCircle />}
            className="mx-2 mb-2"
          >
            Wyglądaa na to, że jesteś w innym miejscu niż ostatnio. Zapisana
            lokalizacja to{" "}
            <b>
              {lastSavedAddress.primary} {lastSavedAddress.secondary}
            </b>
          </Alert>
        ) : null}
        {/* <div className="relative h-[300px]"> */}
        {currentLocation && mapLoaded ? (
          <DeliveryMap
            zoomOptions={zoomOptions}
            onDragEnd={onDragEnd}
            position={currentLocation}
          />
        ) : null}
        {/* </div> */}
        {address ? (
          <div className="ion-padding">
            <Stack align="center" gap={2}>
              <Text fw={600} size="lg">
                {address.primary}
              </Text>
              <Text>{address.secondary}</Text>
              <IonNavLink
                className="w-full"
                routerDirection="forward"
                component={() => (
                  <AdditionalAddressInformationPage
                    selectedAddress={address}
                    userForcedPosition={userForcedPosition}
                    currentLocation={currentLocation}
                    modalMapRef={forwardRef}
                  />
                )}
              >
                <Button
                  fullWidth
                  mt={10}
                  onClick={() => {
                    if (currentLocation || userForcedPosition) {
                      const lat =
                        userForcedPosition?.lat || currentLocation.latitude;
                      const lng =
                        userForcedPosition?.lng || currentLocation.longitude;
                    }
                  }}
                >
                  Przejdź dalej
                </Button>
              </IonNavLink>
            </Stack>
          </div>
        ) : null}
      </IonContent>
    </>
  );
};
