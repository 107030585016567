import { cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AutoAlignImage } from "../AutoAlignImage";
import { Snap } from "../Snap";
import { SnapItem } from "../SnapItem";

type ProductImageCarouselProps = {
  items: { url: string }[];
};

const cropImageToDesiredSize = cropImageTo(350);

export const ProductImageCarousel = ({ items }: ProductImageCarouselProps) => {
  const ref = React.useRef<HTMLDivElement>();

  return (
    <>
      <Snap ref={ref} style={{ marginTop: "var(--ion-safe-area-top)" }}>
        {items.map((image) => (
          <SnapItem key={image.url} width="full" height="inherit">
            <AutoAlignImage
              src={cropImageToDesiredSize(image.url)}
              alt="Product image"
              style={{ width: 350, height: 350, objectFit: "scale-down" }}
              skeletonProps={{
                className: "mx-auto",
              }}
            />
          </SnapItem>
        ))}
      </Snap>

      <div className="flex justify-center">
        {items.map((image, i) => (
          <div key={image.url} className="flex justify-center">
            <a
              className="mx-2 h-2 w-2 rounded-full bg-gray-800"
              onClick={() => {
                ref.current.scrollLeft = 390 * i;
              }}
            ></a>
          </div>
        ))}
      </div>
    </>
  );
};
