import { CapacitorHttp } from "@capacitor/core";
import { WritableAtom, atom, useAtom, useAtomValue } from "jotai";
import type { PrimitiveAtom } from "jotai";
import { atomsWithTanstackQuery } from "jotai-tanstack-query";
import { atomWithObservable, atomWithReset, useUpdateAtom } from "jotai/utils";
import * as React from "react";

import { location$ } from "../geolocation/epics";
import { StoriesDocument } from "../graphql/graphql";
import { GlobalScope } from "./GlobalScope";

function atomWithToggle(
  initialValue?: boolean,
): WritableAtom<boolean, boolean[], void> {
  const anAtom = atom(initialValue, (get, set, nextValue?: boolean) => {
    const update = nextValue ?? !get(anAtom);
    set(anAtom, update);
  });

  return anAtom;
}

type Story = {
  id: string;
  location: {
    name: string;
    logo: string;
  };
  story: {
    id: string;
    reels: {
      id: string;
      source: string;
      type: string;
      addons: any;
      createdAt: string;
    }[];
  };
};

export const currentLocationAtom = atomWithObservable(() => location$, {
  initialValue: null,
});
currentLocationAtom.debugLabel = "currentLocationAtom";

export class Reel {
  reel = null;
  #isContentLoaded = false;

  constructor(reel) {
    this.reel = reel;
  }

  get id() {
    return this.reel.id;
  }

  loaded() {
    this.#isContentLoaded = true;
  }

  get createdAt() {
    return this.reel.createdAt;
  }

  get source() {
    return this.reel.source;
  }

  get isContentLoaded() {
    return this.#isContentLoaded;
  }
}

export const [, storiesAtoms] = atomsWithTanstackQuery((get) => {
  const currentLocation = get(currentLocationAtom);

  return {
    enabled: !!currentLocation,
    queryKey: ["Stories"],
    select: ({ data }) => {
      return data.stories.map((story, index) => ({
        index,
        ...story,
        story: {
          ...story.story,
          reels: story.story.reels.sort(
            (a, b) => Number(a.createdAt) - Number(b.createdAt),
          ),
        },
      }));
    },
    queryFn: async () => {
      try {
        const { data } = await CapacitorHttp.request({
          url: process.env.DATA_SERVICE,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            query: StoriesDocument,
            variables: {
              input: {
                lat: currentLocation?.[0],
                lng: currentLocation?.[1],
              },
            },
          }),
        });
        return data;
      } catch (e) {}
    },
  };
});
storiesAtoms.debugLabel = "StoriesAtom";

export const activeStoryAtom = atom<PrimitiveAtom<Story> | null>(null);
activeStoryAtom.debugLabel = "ActiveStoryAtom";

export const reelYAtom = atomWithReset({ y: 0 });
reelYAtom.debugLabel = "reelYAtom";

export const isReelsVisibleAtom = atom(false);
isReelsVisibleAtom.debugLabel = "isReelsVisibleAtom";

export const currentReelAtom = atom<Reel>(null);
currentReelAtom.debugLabel = "CurrentReelAtom";

export const storyReelSizeAtom = atom({
  height: window.innerHeight * 0.7,
  top: (window.innerHeight * 0.3) / 2,
  width: window.innerWidth,
});

export const isLongPressAtom = atomWithToggle(false);
export const isPressAtom = atom(false);

export const useUpdateCurrentReel = () => {
  return useUpdateAtom(currentReelAtom);
};

export const useCurrentReelValue = () => {
  return useAtomValue(currentReelAtom);
};
export const useStoryReelSize = () => {
  return useAtomValue(storyReelSizeAtom, GlobalScope);
};

export const useIsReelsVisible = () => {
  return useAtom(isReelsVisibleAtom, GlobalScope);
};

export const useIsReelsVisibleValue = () => {
  return useAtomValue(isReelsVisibleAtom, GlobalScope);
};

export const useUpdateIsReelsVisible = () => {
  return useUpdateAtom(isReelsVisibleAtom, GlobalScope);
};

export const useActiveStoryValue = () => {
  return useAtomValue(activeStoryAtom, GlobalScope);
};

export const useReelY = () => {
  return useAtom(reelYAtom, GlobalScope);
};

export const useUpdateReelY = () => {
  return useUpdateAtom(reelYAtom, GlobalScope);
};

const isClosing = atom((get) => Math.abs(get(reelYAtom).y) > 10);

export const useIsClosingStart = () => {
  return useAtomValue(isClosing, GlobalScope);
};

export const useReelYValue = () => {
  return useAtomValue(reelYAtom, GlobalScope);
};

export const useUpdateActiveStory = () => {
  return useUpdateAtom(activeStoryAtom, GlobalScope);
};

export const useActiveStory = () => {
  return useAtom(activeStoryAtom, GlobalScope);
};

const isSwippingAtom = atom(false);

export const useIsSwipping = () => {
  return useAtom(isSwippingAtom, GlobalScope);
};

export const useIsSwippingValue = () => {
  return useAtomValue(isSwippingAtom, GlobalScope);
};

export const useUpdateIsSwipping = () => {
  return useUpdateAtom(isSwippingAtom, GlobalScope);
};
