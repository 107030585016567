import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import * as React from "react";

export const initialize = async () => {
  try {
    if (isInitialized) return;

    await GoogleAuth.initialize({
      clientId:
        "19293718339-9qa1ko6paik4b93cmug71vh4hpqsp66n.apps.googleusercontent.com",
      scopes: ["email", "profile"],
      grantOfflineAccess: true,
    });
    isInitialized = true;
  } finally {
    emitChange();
  }
};

let isInitialized = false;
let listeners = [];

export const googleAuthStore = {
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return isInitialized;
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}

export const useIsGoogleAuthInitialized = () => {
  React.useEffect(() => {
    initialize();
  }, []);

  return React.useSyncExternalStore(
    googleAuthStore.subscribe,
    googleAuthStore.getSnapshot,
  );
};
