import { useIonRouter } from "@ionic/react";
import * as React from "react";

type CurrentTabViewManagerContextType = {
  isTabsDisabled: boolean;
  setIsTabsDisabled: (isTabsDisabled: boolean) => void;
  setNavigatingToTab: (tab: string) => void;
  navigatingToTab: string;
  activeTab: string;
};

const CurrentTabViewManagerContext =
  React.createContext<CurrentTabViewManagerContextType>({
    isTabsDisabled: null,
    setIsTabsDisabled: () => {},
    setNavigatingToTab: () => {},
    navigatingToTab: null,
    activeTab: "discovery",
  });

export const CurrentTabViewManager = ({ children }) => {
  const router = useIonRouter();
  const [isTabsDisabled, setIsTabsDisabled] = React.useState(null);
  const [navigatingToTab, setNavigatingToTab] = React.useState(null);

  const pathname = router.routeInfo.pathname.split("/");
  const currentTabFromLocation =
    pathname.length < 3 ? "discovery" : pathname[2];
  const activeTab = currentTabFromLocation;

  return (
    <CurrentTabViewManagerContext.Provider
      value={{
        isTabsDisabled,
        setIsTabsDisabled,
        setNavigatingToTab,
        navigatingToTab,
        activeTab,
      }}
    >
      {children}
    </CurrentTabViewManagerContext.Provider>
  );
};

export const useCurrentTabViewManager = () => {
  return React.useContext(CurrentTabViewManagerContext);
};
