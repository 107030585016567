import * as React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { Price } from "@zozia/ui";
import { LoadingOverlay, Text } from "@mantine/core";

import { useLocationDeliveryPackingOptionsQuery } from "../../graphql/graphql";

export const LocationDeliveryPackingSelector = ({
  value,
  locationId,
  onChange,
}) => {
  const [_value, setValue] = React.useState(value);

  const { data, isLoading } = useLocationDeliveryPackingOptionsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.locationDeliveryPackingOptions || [],
      enabled: !!locationId,
    },
  );

  const selectedOptionDescription =
    data?.find(({ id }) => id === _value)?.description || "";

  return (
    <div className="relative">
      <LoadingOverlay visible={isLoading} />
      <IonSelect
        value={_value}
        interface="action-sheet"
        label="Metoda pakowania"
        placeholder="Torba papierowa"
        onIonChange={(e) => {
          onChange({
            deliveryPackingOptionId: e.detail.value,
          });
          setValue(e.detail.value);
        }}
      >
        {data?.map((option) => (
          <IonSelectOption key={option.id} value={option.id}>
            {option.name} <Price value={option.packingPrice} />
          </IonSelectOption>
        ))}
      </IonSelect>
      {!!_value ? (
        <Text c="gray" size="xs">
          {selectedOptionDescription}
        </Text>
      ) : null}
    </div>
  );
};
