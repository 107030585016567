import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { Text } from "@mantine/core";
import { Header } from "@zozia/ui";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { useSession } from "../useSession";

export const LoginCompletedPage = () => {
  const { snapshot } = useSession();

  const history = useHistory();
  const ref = React.useRef<ReturnType<typeof setTimeout>>();

  const redirectToDiscovery = () => {
    ref.current = setTimeout(() => {
      history.replace("/tabs/discovery");
    }, 1000);
  };

  React.useEffect(() => {
    if (snapshot) {
      redirectToDiscovery();
    }
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [snapshot]);

  return (
    <IonPage>
      <IonContent>
        <div className="flex flex-col overflow-hidden">
          <main className="flex-grow">
            <section className="relative">
              <div className="mx-auto max-w-6xl px-4 sm:px-6">
                <div className="pb-12 pt-32 md:pb-20 md:pt-40">
                  <div className="pb-12 text-center md:pb-16">
                    <Header innerClassNames="text-4xl">Zozia</Header>
                  </div>
                  <div className="flex flex-col items-center ">
                    <IonSpinner className="mx-auto" name="crescent" />
                    <Text className="pt-4">Pobieranie danych...</Text>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </IonContent>
    </IonPage>
  );
};
