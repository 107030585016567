import {
  SignInWithApple,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  AuthConnect,
  AuthResult,
  ProviderOptions,
  TokenType,
} from "@ionic-enterprise/auth";
import { isPlatform } from "@ionic/react";
import { TokensStore } from "@zozia/oidc";

import { logger } from "../../../lib/logger";
import { KeycloakProvider } from "./KeycloakProvider";
import { getSession, setSession } from "./session-vault";
import { Session, clearSession, storeSession } from "./vault";

const mobileSchema = "com.zozia.app://";

const isMobile = isPlatform("hybrid");
const options: ProviderOptions = {
  audience: "https://io.ionic.demo.ac",
  clientId: "zozia-mobile-production",
  discoveryUrl:
    "https://auth2.zozia.app/realms/zozia-prod/.well-known/openid-configuration",
  scope: "openid email profile",
  logoutUrl: isMobile
    ? `${mobileSchema}tabs/login`
    : `${window.location.origin}/tabs/login`,
  redirectUri: isMobile
    ? `${mobileSchema}tabs/login-completed`
    : `${window.location.origin}/tabs/login-completed`,
};
const provider = new KeycloakProvider();

const setupAuthConnect = async (): Promise<void> => {
  return AuthConnect.setup({
    platform: isMobile ? "capacitor" : "web",
    logLevel: "DEBUG",
    ios: { webView: "private" },
    web: { uiMode: "popup", authFlow: "PKCE" },
  });
};

const performRefresh = async (
  authResult: AuthResult,
): Promise<AuthResult | undefined> => {
  let newAuthResult: AuthResult | undefined;
  if (await AuthConnect.isRefreshTokenAvailable(authResult)) {
    try {
      newAuthResult = await AuthConnect.refreshSession(provider, authResult);
      setSession(newAuthResult);
    } catch (error) {
      await clearSession();
    }
  } else {
    await clearSession();
  }

  return newAuthResult;
};

const getAuthResult = async (): Promise<AuthResult | undefined> => {
  let authResult = await getSession();
  logger.debug("Auth result", { authResult });
  if (authResult && (await AuthConnect.isAccessTokenExpired(authResult))) {
    logger.debug("Access token expired, attempting refresh");
    authResult = await performRefresh(authResult);
  }

  return authResult;
};

const getAccessToken = async (): Promise<string | void> => {
  const authResult = await getAuthResult();
  return authResult?.accessToken;
};

const isAuthenticated = async (): Promise<boolean> => {
  return !!(await getAuthResult());
};

const login = async (logoutUrl = ""): Promise<void> => {
  const logoutUri = logoutUrl.startsWith("/")
    ? logoutUrl.slice(1)
    : `/${logoutUrl}`;
  const authOptions = {
    ...options,
    redirectUri: logoutUrl
      ? `${mobileSchema}${logoutUri}`
      : options.redirectUri,
  };
  const authResult = await AuthConnect.login(provider, authOptions);

  const { refresh_expires_in } = JSON.parse(authResult.rawResult);
  const tokens = TokensStore.mapTokens<Session>({
    access_token: authResult.accessToken,
    refresh_token: authResult.refreshToken,
    refresh_expires_in,
    expires_in: authResult.expiresIn,
  });

  await storeSession(tokens);
};

const loginWithApple = async () => {
  let options: SignInWithAppleOptions = {
    clientId: "com.zozia.app.client",
    redirectURI: isMobile
      ? `${mobileSchema}tabs/login-completed`
      : `${window.location.origin}/tabs/login-completed`,
    scopes: "email name",
    state: "12345",
    nonce: "nonce",
  };

  const { response: appleSignInResponse } =
    await SignInWithApple.authorize(options);

  const { identityToken, givenName, familyName, email } = appleSignInResponse;

  const appleFederatedLinkResponse = await fetch(
    `${process.env.DATA_SERVICE_REST}/user/link/apple`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${identityToken}`,
      },
    },
  );

  const appleFederatedLinkResponseJson =
    await appleFederatedLinkResponse.json();

  if (appleFederatedLinkResponseJson.error) {
    if (appleFederatedLinkResponseJson.error.message === "No email found") {
      console.log("User disabled apple email sharing");
    }
  }

  const keycloakTokenExchangeResponse = await fetch(
    "https://auth2.zozia.app/realms/zozia-prod/protocol/openid-connect/token",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: Object.entries({
        client_id: "zozia-mobile-production",
        grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
        subject_token: identityToken,
        subject_issuer: "apple",
        subject_token_type: "urn:ietf:params:oauth:token-type:id_token",
        user_profile: JSON.stringify({
          name: { firstName: givenName || "", lastName: familyName || "" },
          email: email || "",
        }),
      })
        .map(([key, value]) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&"),
    },
  );

  const keycloakTokenExchangeResponseJson =
    await keycloakTokenExchangeResponse.json();

  const tokens = TokensStore.mapTokens<Session>(
    keycloakTokenExchangeResponseJson,
  );

  await storeSession(tokens);
};

const loginWithGoogle = async () => {
  const googleSignInResponse = await GoogleAuth.signIn();

  const { authentication, email, givenName, familyName } = googleSignInResponse;
  console.log(googleSignInResponse);

  const googleFederatedLinkResponse = await fetch(
    `${process.env.DATA_SERVICE_REST}/user/link/google`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authentication.idToken}`,
      },
    },
  );

  const googleFederatedLinkResponseJson =
    await googleFederatedLinkResponse.json();

  console.log(googleFederatedLinkResponseJson);

  const response = await fetch(
    process.env.NODE_ENV === "production"
      ? "https://auth2.zozia.app/realms/zozia-prod/protocol/openid-connect/token"
      : `${process.env.DATA_SERVICE_REST}/user/federated/login/google`,
    {
      method: "POST",
      headers:
        process.env.NODE_ENV === "production"
          ? {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          : {
              Authorization: `Bearer ${authentication.idToken}`,
            },
      body:
        process.env.NODE_ENV === "production"
          ? Object.entries({
              client_id: "zozia-mobile-production",
              grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
              subject_token: authentication.accessToken,
              subject_issuer: "google",
              subject_token_type:
                "urn:ietf:params:oauth:token-type:access_token",
              user_profile: JSON.stringify({
                name: {
                  firstName: givenName || "",
                  lastName: familyName || "",
                },
                email: email || "",
              }),
            })
              .map(([key, value]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
              })
              .join("&")
          : JSON.stringify({
              client_id: "zozia-mobile-production",
              grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
              subject_token: authentication.accessToken,
              subject_issuer: "google",
              subject_token_type:
                "urn:ietf:params:oauth:token-type:access_token",
              user_profile: JSON.stringify({
                name: {
                  firstName: givenName || "",
                  lastName: familyName || "",
                },
                email: email || "",
              }),
            }),
    },
  );

  const json = await response.json();

  const tokens = TokensStore.mapTokens<Session>(json);

  storeSession(tokens);
};

const logout = async (): Promise<void> => {
  await clearSession();
  TokensStore.setTokens(null);
};

const getUser = async (): Promise<string | void> => {
  const authResult = await getSession();
  if (authResult) {
    const user = (await AuthConnect.decodeToken(
      TokenType.id,
      authResult,
    )) as any;
    return user;
  }
};

export {
  getAccessToken,
  getUser,
  isAuthenticated,
  login,
  loginWithApple,
  loginWithGoogle,
  logout,
  setupAuthConnect,
};
