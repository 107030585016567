import { usePrevious } from "@mantine/hooks";
import { useAnimate } from "framer-motion";
import * as React from "react";

export const Count = ({ value, disableInitialAnimation }) => {
  const [scope, animate] = useAnimate();
  const previousValue = usePrevious(value);
  const ref = React.useRef(disableInitialAnimation);
  const [isAnimating, setIsAnimating] = React.useState(false);
  const r = React.useRef("+1");

  React.useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }

    setIsAnimating(true);

    const isAdding = value > (previousValue || 0);
    r.current = isAdding ? "+1" : "-1";
    animate(
      scope.current,
      {
        opacity: 1,
        y: isAdding ? -20 : 20,
      },
      {
        onComplete: () => {
          setIsAnimating(false);
        },
      },
    );
  }, [value]);

  return (
    <div className="flex items-center">
      <div className="absolute flex items-center">
        <div ref={scope} key={value}>
          {isAnimating ? r.current : null}
        </div>
      </div>
      <div>{value}</div>
    </div>
  );
};
