import { AddressType, useMyAddressesQuery } from "../../graphql/graphql";

export const useUserAddress = (type: AddressType) => {
  const { data, isLoading } = useMyAddressesQuery(
    {
      type: type,
    },
    {
      select: (data) => ({
        ...data.myAddresses?.[0]?.address,
        id: data.myAddresses?.[0]?.id,
      }),
    },
  );

  return { isLoading, address: data };
};
