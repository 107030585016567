import { Preferences } from "@capacitor/preferences";
import { AuthResult } from "@ionic-enterprise/auth";
import {
  DeviceSecurityType,
  IdentityVaultConfig,
  VaultType,
} from "@ionic-enterprise/identity-vault";

import { provisionBiometricPermission } from "./device";
import { createVault } from "./vault-factory";

//canUlock

//export to own model?
type UnlockMode =
  | "Biometrics"
  | "BiometricsWithPasscode"
  | "SystemPasscode"
  | "CustomPasscode"
  | "SecureStorage";

type VaultUnlockType = Pick<IdentityVaultConfig, "type" | "deviceSecurityType">;

type CallbackMap = {
  onSessionChange?: (session: AuthResult | undefined) => void;
  onVaultLock?: () => void;
  onPasscodeRequested?: (
    isPasscodeSetRequest: boolean,
    onComplete: (code: string) => void,
  ) => void;
};

const keys = { session: "session", mode: "last-unlock-mode" };
const vault = createVault();

const initializeVault = async (): Promise<void> => {
  await vault.initialize({
    key: "com.zozia.app",
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
    lockAfterBackgrounded: 5000,
    shouldClearVaultAfterTooManyFailedAttempts: true,
    customPasscodeInvalidUnlockAttempts: 2,
    unlockVaultOnLoad: false,
  });

  vault.onLock(() => {
    session = undefined;
    if (callbackMap.onVaultLock) callbackMap.onVaultLock();
  });

  vault.onPasscodeRequested((isPasscodeSetRequest, onComplete) => {
    if (callbackMap.onPasscodeRequested)
      callbackMap.onPasscodeRequested(isPasscodeSetRequest, onComplete);
  });
};

let session: AuthResult | undefined;
const callbackMap: CallbackMap = {};

const clearSession = async (): Promise<void> => {
  session = undefined;
  await vault.clear();
  await setUnlockMode("SecureStorage");
  if (callbackMap.onSessionChange) callbackMap.onSessionChange(null);
};

const getSession = async (): Promise<AuthResult | undefined> => {
  try {
    if (!session)
      session = (await vault.getValue<AuthResult>(keys.session)) || undefined;
    return session;
  } catch (error) {
    throw error;
  }
};

const restoreSession = async (): Promise<AuthResult | undefined> => {
  let s = (await vault.getValue<AuthResult>(keys.session)) || undefined;
  if (s) {
    s = JSON.parse(s);
  }
  session = s;
  if (callbackMap.onSessionChange) callbackMap.onSessionChange(s);
  return s;
};

const setSession = async (s: AuthResult): Promise<void> => {
  try {
    await vault.setValue(keys.session, s);
    if (callbackMap.onSessionChange) callbackMap.onSessionChange(s);
  } catch (error) {
    throw error;
  }
};

const getUnlockModeConfig = async (
  unlockMode: UnlockMode,
): Promise<VaultUnlockType> => {
  switch (unlockMode) {
    case "Biometrics":
      await provisionBiometricPermission();
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.Biometrics,
      };
    case "BiometricsWithPasscode":
      await provisionBiometricPermission();
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.Both,
      };
    case "SystemPasscode":
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.SystemPasscode,
      };
    case "CustomPasscode":
      return {
        type: VaultType.CustomPasscode,
        deviceSecurityType: DeviceSecurityType.None,
      };
    case "SecureStorage":
    default:
      return {
        type: VaultType.SecureStorage,
        deviceSecurityType: DeviceSecurityType.None,
      };
  }
};

const canUnlock = async (): Promise<boolean> => {
  const { value } = await Preferences.get({ key: keys.mode });
  return (
    (value || "SecureStorage") !== "SecureStorage" &&
    !(await vault.isEmpty()) &&
    (await vault.isLocked())
  );
};

const setUnlockMode = async (unlockMode: UnlockMode) => {
  const { type, deviceSecurityType } = await getUnlockModeConfig(unlockMode);
  await vault.updateConfig({ ...vault.config!, type, deviceSecurityType });
  await Preferences.set({ key: keys.mode, value: unlockMode });
};

const getUnlockMode = async (): Promise<UnlockMode> => {
  const { value } = await Preferences.get({ key: keys.mode });
  return (value as UnlockMode | null) || "SecureStorage";
};

const registerCallback = <T extends keyof CallbackMap>(
  topic: T,
  cb: CallbackMap[T],
): void => {
  callbackMap[topic] = cb;
};

const unregisterCallback = <T extends keyof CallbackMap>(topic: T): void => {
  callbackMap[topic] = undefined;
};

export {
  vault,
  clearSession,
  getSession,
  restoreSession,
  setSession,
  canUnlock,
  setUnlockMode,
  getUnlockMode,
  registerCallback,
  initializeVault,
  unregisterCallback,
};
