import * as React from "react";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import { PaymentSheetEventsEnum } from "@capacitor-community/stripe";

import { useSetupIntentQuery } from "../../graphql/graphql";

export const useManagePaymentMethods = () => {
  const { data } = useSetupIntentQuery(null, {
    select: (data) => data.setupIntent,
  });
  const { stripe } = useCapacitorStripe();

  React.useEffect(() => {
    if (!stripe) return;
    Object.keys(PaymentSheetEventsEnum).forEach((key) => {
      const eventName = PaymentSheetEventsEnum[key];
      stripe.addListener(eventName, (value: any) => {
        console.log(`[Event:${eventName}] ${value}`);
      });
    });
  }, [stripe]);

  const openPaymentMethodsModal = async () => {
    if (data && stripe) {
      await stripe.createPaymentFlow({
        setupIntentClientSecret: data.setupIntent,
        customerEphemeralKeySecret: data.ephemeralKey,
        customerId: data.customer,
        merchantDisplayName: "Papuga Zoo",
        returnURL: "com.zozia.app://tabs/discovery",
      });

      await stripe.presentPaymentFlow();
    }
  };

  return { openPaymentMethodsModal };
};
