import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonNav,
  IonNavLink,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { Badge, Button, Card, Group, Loader, Stack, Text } from "@mantine/core";
import { useEventListener, useTimeout } from "@mantine/hooks";
import {
  IconBuildingStore,
  IconChevronRight,
  IconTruckDelivery,
} from "@tabler/icons-react";
import { Money } from "@zozia/money";
import { Price } from "@zozia/ui";
import cn from "classnames";
import { motion } from "framer-motion";
import { arrowBackOutline, close } from "ionicons/icons";
import L from "leaflet";
import * as React from "react";
import { Circle, Marker, useMap } from "react-leaflet";

import {
  DeliveryType,
  useLocationDeliveryOptionsQuery,
  useShopLocationQuery,
} from "../../graphql/graphql";
import {
  DeliveryMap2,
  FitBounds,
} from "../../session/pages/adresses/SetDeliveryAddress/DeliveryMap";
import { useCart } from "../CartContext";
import css from "./DeliveryCircle.module.css";

export const LocationDeliveryOptionSelector2 = ({
  value,
  error,
  locationId,
  onChange,
}) => {
  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const {
    data: deliveryOptions,
    isLoading,
    refetch: refetchDeliveryOptions,
  } = useLocationDeliveryOptionsQuery(
    {
      locationId,
    },
    {
      select: (data) => {
        if (!data.locationDeliveryOptions) {
          return [];
        }

        return data.locationDeliveryOptions.map((option) => {
          return {
            ...option,
            deliveryPrice: Money.fromFractionlessAmount(
              option.deliveryPrice,
              option.currency as Uppercase<string>,
            ),
          };
        });
      },
      enabled: !!locationId,
    },
  );

  const selectedOption = deliveryOptions?.find((option) => option.id === value);

  return (
    <div className="relative">
      {selectedOption ? (
        <DeliveryPackingOption
          name={selectedOption?.description}
          icon={getIcon(selectedOption?.type)}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
        />
      ) : (
        <DeliveryPackingOption
          error={error}
          icon={<IconTruckDelivery />}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
        />
      )}

      <IonModal
        ref={modalRef}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onIonModalWillPresent={() => {
          refetchDeliveryOptions();
        }}
      >
        <IonNav
          root={() => (
            <DeliveryPackingOptionListPage
              isLoading={isLoading}
              deliveryOptions={deliveryOptions}
              onChange={onChange}
              modalRef={modalRef}
            />
          )}
        />
      </IonModal>
    </div>
  );
};

const zoomOptions = {
  zoomControl: !isPlatform("hybrid"),
};

type DeliveryPackingOptionMapProps = {
  modalRef: React.RefObject<HTMLIonModalElement>;
  deliveryOptions: any[];
  onSelect: (option: any) => void;
};

const DeliveryPackingOptionMap = ({
  modalRef,
  onSelect,
  deliveryOptions,
}: DeliveryPackingOptionMapProps) => {
  const { cart } = useCart();
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const { data } = useShopLocationQuery(
    { id: cart.location.id },
    { enabled: !!cart && !!cart.location && !!cart.location.id },
  );
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [missingAreaOptions, setMissingAreaOptions] = React.useState(0);

  useTimeout(
    () => {
      setMapLoaded(true);
    },
    100,
    { autoInvoke: true },
  );

  React.useEffect(() => {
    modalRef.current.setCurrentBreakpoint(1);
  }, []);

  const useUserDeliveryAddress =
    (cart?.deliveryAddressCopy?.address?.address
      ? cart?.deliveryAddressCopy?.address?.address
      : cart?.deliveryAddressCopy?.address) || {};

  const sortedDeliveryAreas = deliveryOptions.sort(
    (a, b) => b.radius - a.radius,
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              icon={arrowBackOutline}
              style={{ "--color": "black" }}
            ></IonBackButton>
          </IonButtons>
          <IonTitle>Zasięg dostawcy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data?.location?.latitude &&
        data?.location?.longitude &&
        mapLoaded &&
        useUserDeliveryAddress.latitude ? (
          <DeliveryMap2
            classNames="h-full"
            zoomOptions={zoomOptions}
            position={{
              latitude: data.location.latitude,
              longitude: data.location.longitude,
            }}
          >
            {sortedDeliveryAreas.map((option, idx) => {
              return (
                <DeliveryCircle
                  idx={idx}
                  key={option.id}
                  isSelected={selectedArea?.id === option.id}
                  option={option}
                  onClick={() => setSelectedArea(option)}
                  center={[data.location.latitude, data.location.longitude]}
                  userPosition={[
                    useUserDeliveryAddress.latitude,
                    useUserDeliveryAddress.longitude,
                  ]}
                  onMissingArea={() => setMissingAreaOptions((o) => o + 1)}
                />
              );
            })}
            <Marker
              position={L.latLng(
                data.location.latitude,
                data.location.longitude,
              )}
              icon={L.icon({
                iconUrl: require("./shopMarker.svg"),
                iconSize: [64, 64],
                iconAnchor: [32, 32],
              })}
            />
            <FitBounds
              bounds={[
                [
                  useUserDeliveryAddress.latitude,
                  useUserDeliveryAddress.longitude,
                ],
                [data.location.latitude, data.location.longitude],
              ]}
            />
            <Marker
              icon={L.icon({
                iconUrl: require("./userMarker.svg"),
                iconSize: [64, 64],
                iconAnchor: [32, 32],
              })}
              position={L.latLng(
                useUserDeliveryAddress.latitude,
                useUserDeliveryAddress.longitude,
              )}
            />
          </DeliveryMap2>
        ) : null}
        <div className="absolute bottom-0 px-8 pb-8 w-full z-[9999]">
          {missingAreaOptions === sortedDeliveryAreas.length ? (
            <Group>
              <Card shadow="sm" radius="md" withBorder>
                <Text fw={500} ta="center">
                  Niestety jeszcze nie dostarczamy pod Twój adres
                </Text>
              </Card>
            </Group>
          ) : (
            <>
              <Group grow>
                {deliveryOptions
                  .sort((a, b) => a.radius - b.radius)
                  .map((option) => {
                    return (
                      <motion.div
                        initial={false}
                        key={option.id}
                        animate={
                          option.id === selectedArea?.id ? "active" : "inactive"
                        }
                        variants={{
                          active: {
                            scale: 1.1,
                          },
                          inactive: {
                            scale: 1,
                          },
                        }}
                      >
                        <Card shadow="sm" radius="md" withBorder>
                          <Group justify="space-between" mb="xs" mx="xs">
                            <Text fw={500}>{option.description}</Text>
                            <Price value={option.deliveryPrice.toNumber()} />
                          </Group>
                          {option.id === selectedArea?.id ? (
                            <Badge>Wybrano</Badge>
                          ) : null}
                        </Card>
                      </motion.div>
                    );
                  })}
              </Group>
              <Group justify="center" mt={25}>
                <Button
                  radius="xl"
                  size="md"
                  fullWidth
                  classNames={{
                    root: "p-0",
                  }}
                  disabled={!selectedArea}
                  onClick={() => {
                    onSelect(selectedArea);
                    modalRef.current.dismiss();
                  }}
                >
                  Potwierdź
                </Button>
              </Group>
            </>
          )}
        </div>
      </IonContent>
    </>
  );
};

const DeliveryCircle = ({
  idx,
  isSelected,
  option,
  onClick,
  center,
  userPosition,
  onMissingArea,
}) => {
  const map = useMap();
  const ref = React.useRef(null);

  const classes = cn({
    [css.animation_pulse_0]: idx === 1,
    [css.animation_pulse_1]: idx === 0,
  });

  React.useEffect(() => {
    const dist = map.distance(
      new L.LatLng(userPosition[0], userPosition[1]),
      ref.current.getLatLng(),
    );

    if (dist < option.radius) {
      onClick();
    } else {
      onMissingArea();
    }
  }, [option]);

  React.useEffect(() => {
    if (isSelected) {
      ref.current._path.classList.add(css.animation);
    } else {
      ref.current._path.classList.remove(css.animation);
    }
  }, [isSelected]);

  return (
    <Circle
      ref={ref}
      radius={option.radius}
      fillOpacity={0.4}
      center={center}
      className={classes}
    />
  );
};

const DeliveryPackingOptionListPage = ({
  deliveryOptions,
  isLoading,
  modalRef,
  onChange,
}) => {
  const hasAnyDeliveryByOwner = deliveryOptions?.some(
    (option) => option.type === "DeliveryByOwner",
  );

  const deliveryMethods = deliveryOptions?.filter(
    (option) => option.type !== "DeliveryByOwner",
  );

  const deliveryMethodsByOwner = deliveryOptions?.filter(
    (option) => option.type === "DeliveryByOwner",
  );

  return (
    <Stack>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                modalRef.current.dismiss();
              }}
            >
              <IonIcon size="large" icon={close} color="black" />
            </IonButton>
          </IonButtons>
          <IonTitle>Metody dostawy</IonTitle>
        </IonToolbar>
      </IonHeader>
      {isLoading ? (
        <Group justify="center" className="mt-8">
          <Loader />{" "}
        </Group>
      ) : null}

      <Stack className="px-4">
        {hasAnyDeliveryByOwner ? (
          <IonNavLink
            routerDirection="forward"
            component={() => (
              <DeliveryPackingOptionMap
                modalRef={modalRef}
                deliveryOptions={deliveryMethodsByOwner}
                onSelect={({ id }) => {
                  onChange({ deliveryOptionId: id });
                  modalRef.current.dismiss();
                }}
              />
            )}
          >
            <DeliveryPackingOption
              name="Dostawa"
              rightSection={<IconChevronRight />}
              icon={<IconTruckDelivery />}
            />
          </IonNavLink>
        ) : null}
        {deliveryMethods?.map((option) => {
          const icon = getIcon(option.type);

          return (
            <DeliveryPackingOption
              key={option.id}
              onClick={() => {
                onChange({ deliveryOptionId: option.id });
                modalRef.current.dismiss();
              }}
              name={option.description}
              icon={icon}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

type DeliveryPackingOptionProps = {
  onClick?: () => void;
  name?: string;
  description?: string;
  icon: React.ReactElement;
  rightSection?: React.ReactNode;
  error?: string;
};
const DeliveryPackingOption = ({
  onClick = () => {},
  name,
  icon,
  error,
  description,
  rightSection,
}: DeliveryPackingOptionProps) => {
  const onClickHandler = async () => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });

    onClick();
  };
  const ref = useEventListener("click", onClickHandler);
  return (
    <Group
      className={cn("border border-solid rounded-md py-4 pl-4 pr-2", {
        "border-red-500": error,
      })}
      role="button"
      ref={ref}
    >
      <Group
        justify="space-between"
        wrap="nowrap"
        className="w-full"
        align="start"
      >
        <div className="justify-start min-w-[30px]">{icon}</div>
        <Stack gap={0} className="w-full grow">
          <Text size="sm" fw={600}>
            Dostawa
          </Text>
          <Text
            size="xs"
            className={cn({
              "text-gray-400": !name,
            })}
          >
            {name || "Wybierz metodę dostawy"}
          </Text>
          {description ? (
            <Text size="xs" className="text-gray-400 shrink-0">
              {description}
            </Text>
          ) : null}
          {error ? (
            <Text size="xs" c="red" className="shrink-0">
              {error}
            </Text>
          ) : null}
        </Stack>
        <div className="self-center">{rightSection ? rightSection : null}</div>
      </Group>
    </Group>
  );
};

const getIcon = (type: DeliveryType) => {
  switch (type) {
    case "Pickup":
      return <IconBuildingStore />;
    case "DeliveryByOwner":
    default:
      return <IconTruckDelivery />;
  }
};
