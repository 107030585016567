import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { Button, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconX } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { useSetCartItemNoteMutation } from "../../../graphql/graphql";
import { useCart } from "../../CartContext";

type NoteModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  onDidDismiss?: () => void;
  selectedItem: any;
};

export const NoteModal = ({
  forwardRef,
  onDidDismiss,
  selectedItem,
}: NoteModalProps) => {
  const queryClient = useQueryClient();
  const { cart } = useCart();

  const { mutate } = useSetCartItemNoteMutation({
    onMutate: (newValue) => {
      forwardRef.current.dismiss();
      const { shoppingCarts } = queryClient.getQueryData(["ShoppingCarts"]);

      const thisCart = shoppingCarts.find((c) => c.id === cart.id);

      thisCart.items = thisCart.items.map((item) => {
        if (item.id === selectedItem.id) {
          item.note = newValue.note;
        }
        return item;
      });
      queryClient.setQueryData(["ShoppingCarts"], (old) => {
        return {
          shoppingCarts: old.shoppingCarts.map((c) => {
            if (c.id === cart.id) {
              return thisCart;
            }
            return c;
          }),
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["ShoppingCarts"] });
    },
  });

  const form = useForm({
    initialValues: {
      note: "",
    },
  });

  React.useEffect(() => {
    form.setFieldValue("note", selectedItem?.note || "");
  }, [selectedItem]);

  const handleSubmit = async (values) => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });
    mutate({
      cartItemId: selectedItem.id,
      note: values.note,
    });
  };

  return (
    <IonModal
      ref={forwardRef}
      initialBreakpoint={0.8}
      breakpoints={[0, 0.8]}
      style={{ "--border-radius": "20px" }}
      onDidDismiss={() => {
        onDidDismiss?.();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Button
              size="compact-xs"
              color="black"
              variant="transparent"
              onClick={() => forwardRef.current.dismiss()}
            >
              <IconX />
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="px-4">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea
              label={
                <Text component="h4" className="mt-0" fw={600}>
                  Dodaj notatkę dla sprzedawcy
                </Text>
              }
              {...form.getInputProps("note")}
              classNames={{
                input:
                  "border-2 border-solid border-black rounded-md placeholder-gray-600",
              }}
              rows={4}
              placeholder="Dodaj instrukcje dotyczące dojrzałości, rozmiaru, daty przydatności etc. (opcjonalne)"
            />
            <Button
              type="submit"
              radius="xl"
              size="md"
              fullWidth
              className="my-4"
            >
              Zapisz notatkę
            </Button>
          </form>
        </div>
      </IonContent>
    </IonModal>
  );
};
