import { IonItem, IonLabel, IonList } from "@ionic/react";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import {
  Avatar,
  Badge,
  Center,
  Loader,
  Skeleton,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useTranslation } from "@zozia/react-i18n";
import { range } from "@zozia/std";
import { LoadMore, cropImageTo } from "@zozia/ui";
import { useObservableState } from "observable-hooks";
import * as React from "react";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";
import { location$ } from "../../../geolocation/epics";
import { useInfiniteSearchQuery } from "../../../graphql/graphql";
import { Card } from "./Card";

const cropImageToAvatar = cropImageTo(100);

type PageContentProps = {
  searchText: string;
  setSearchText: (searchText: string) => void;
};

export const PageContent = ({
  searchText,
  setSearchText,
}: PageContentProps) => {
  const { t } = useTranslation("common");
  const currentLocation = useObservableState(location$, null);
  console.log({ currentLocation });
  const { data, isInitialLoading, fetchNextPage, hasNextPage } =
    useInfiniteSearchQuery(
      {
        input: {
          text: searchText,
          pos: [currentLocation?.latitude, currentLocation?.longitude],
        },
      },
      {
        enabled:
          !!searchText &&
          !!currentLocation?.latitude &&
          !!currentLocation?.longitude,
        staleTime: 1000 * 60 * 5,
        getNextPageParam: (last) =>
          last.search.results.length === 20
            ? {
                input: {
                  text: searchText,
                  pos: [currentLocation?.latitude, currentLocation?.longitude],
                  page: last.search.page + 1,
                },
              }
            : undefined,
      },
    );

  const fetchNextSearchPage = async () => {
    await fetchNextPage();
  };

  if (currentLocation === null) {
    return (
      <Stack mx="md">
        {range(3).map((item) => (
          <Stack key={item}>
            <Title order={3}>
              <Skeleton height={20} />
            </Title>
            <Carousel
              slideSize="33.333333%"
              slideGap="md"
              align="start"
              withControls={false}
              slidesToScroll={3}
            >
              {range(6).map((item) => (
                <Carousel.Slide key={item}>
                  <Skeleton width={100} height={100} />
                </Carousel.Slide>
              ))}
            </Carousel>
          </Stack>
        ))}
      </Stack>
    );
  }

  if (isInitialLoading || currentLocation === null) {
    return (
      <IonList>
        {range(10).map((item) => (
          <IonItem key={item}>
            <IonLabel>
              <IonLabel>
                <Skeleton height={20} />
              </IonLabel>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  }

  if (!data) {
    return (
      <Stack mx="md">
        <Title order={3}>Zwierzęta</Title>
        <Carousel
          slideSize="33.333333%"
          slideGap="md"
          align="start"
          withControls={false}
          slidesToScroll={3}
        >
          {["Żwirki", "Karma dla psa", "Akwaria", "Zabawki"].map((item) => (
            <Carousel.Slide key={item}>
              <Card
                key={item}
                bgColor="#3b99dd"
                text={item}
                onClick={() => {
                  setSearchText(item);
                }}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
        <Title order={3}>Zdrowa żywność</Title>
        <Carousel
          slideSize="33.333333%"
          slideGap="md"
          align="start"
          withControls={false}
          slidesToScroll={3}
        >
          {["Orzechy", "Karmel", "Przyprawy", "Szuszone owoce"].map((item) => (
            <Carousel.Slide key={item}>
              <Card
                key={item}
                bgColor="#F69F59"
                text={item}
                onClick={() => {
                  setSearchText(item);
                }}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Stack>
    );
  }

  if (data?.pages.flatMap((p) => p.search.results)?.length === 0) {
    return (
      <div className="ml-2">
        <Title order={3}>Nie znaleziono produktów</Title>
      </div>
    );
  }

  return (
    <>
      <Stack gap={4}>
        {data?.pages.map((page, idx) => (
          <React.Fragment key={idx}>
            {page.search.results.map((item) => (
              <IonItem
                key={item.id}
                routerLink={`/tabs/discovery/search/by-products/${item.id}`}
                style={{
                  "--min-height": "80px",
                }}
              >
                <Avatar slot="start">
                  <AutoAlignImage
                    src={cropImageToAvatar(item.image)}
                    alt="Product image"
                    style={{ width: 38, height: 38 }}
                    className="rounded-lg object-cover"
                  />
                </Avatar>
                <Stack gap="sm" ml="sm">
                  <Text
                    size="sm"
                    className="line-clamp-2"
                    style={{ height: "2lh" }}
                  >
                    {item.name}
                  </Text>
                  <Badge size="xs">
                    {t("search.offerCount", { count: item.offerCount })}
                  </Badge>
                </Stack>
              </IonItem>
            ))}
          </React.Fragment>
        ))}
      </Stack>
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextSearchPage}>
          <div style={{ padding: 30 }}>
            {
              <Center style={{ height: "100%" }}>
                <Loader />
              </Center>
            }
          </div>
        </LoadMore>
      ) : null}
    </>
  );
};
