import { cropImageTo } from "@zozia/ui";
import { motion } from "framer-motion";
import * as React from "react";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";

const cropImageToLogoSize = cropImageTo(148, 168);

type DiscoveryLocationCardLogoImageAnimationWrapperProps = {
  children: React.ReactNode;
  style: React.CSSProperties;
};

const DiscoveryLocationCardLogoImageAnimationWrapper = ({
  children,
  style,
}: DiscoveryLocationCardLogoImageAnimationWrapperProps) => {
  return (
    <motion.div
      style={{ ...style, opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
    >
      {children}
    </motion.div>
  );
};

export const DiscoveryLocationCardLogoImage = ({ alt, src, blurhash }) => {
  return (
    <AutoAlignImage
      onLoaded={() => {}}
      src={src ? cropImageToLogoSize(src) : null}
      blurhash={blurhash}
      style={{ width: 168, height: 148 }}
      animation={DiscoveryLocationCardLogoImageAnimationWrapper}
      alt={alt}
    />
  );
};
