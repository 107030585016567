import * as React from "react";

import { useMeQuery } from "../graphql/graphql";
import { useSession } from "./useSession";

export const useUser = () => {
  const [user, setUser] = React.useState(null);
  const { snapshot } = useSession();

  const { data: userData, refetch } = useMeQuery(undefined, {
    enabled: !!snapshot,
  });

  React.useEffect(() => {
    async function init() {
      const { givenName, familyName, ...restInfo } = snapshot.user;

      setUser({
        ...restInfo,
        givenName,
        familyName,
      });
    }

    if (snapshot) {
      init();
    } else {
      setUser(null);
    }
  }, [snapshot]);

  const constructedUser = user
    ? {
        ...user,
        ...(userData?.me || {}),
      }
    : null;

  return [constructedUser, { refetch }] as const;
};
