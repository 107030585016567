import { createRefreshAccessToken, isAccessTokenExpired } from "@zozia/oidc";
import * as React from "react";

import { logout } from "./utils/auth";
import { getSnapshot, storeSession, subscribe } from "./utils/vault";

const refreshAccessToken = createRefreshAccessToken({
  clientID: "zozia-mobile-production",
});

export const useSession = () => {
  const snapshot = React.useSyncExternalStore(subscribe, getSnapshot);

  const getAccessToken = async () => {
    if (!snapshot) {
      return null;
    }

    const accessToken = snapshot?.accessToken;

    const { expired } = isAccessTokenExpired({ exp: snapshot.user.exp });
    if (expired) {
      try {
        const tokens = await refreshAccessToken({
          refreshToken: snapshot.refreshToken,
        });

        if (tokens.error) {
          await logout();
          return null;
        }

        await storeSession(tokens);

        return tokens.accessToken;
      } catch (error) {
        await logout();
        return null;
      }
    } else {
      return accessToken;
    }
  };

  return {
    snapshot,
    getAccessToken,
  };
};
