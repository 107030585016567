import { PaymentSheetEventsEnum } from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import { IonContent } from "@ionic/react";
import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import * as React from "react";
import { match } from "react-router-dom";

import { RouteBackModal } from "../../../components/RouteBackModal";
import { useCart } from "../../cart/CartContext";
import { useSetupIntentQuery } from "../../graphql/graphql";
import { useUser } from "../useUser";

export const CreateSetupIntent = ({ children }) => {
  const { data } = useSetupIntentQuery(null, {
    select: (data) => data.setupIntent,
  });
  const { stripe } = useCapacitorStripe();
  const openedRef = React.useRef(false);

  React.useEffect(() => {
    if (!stripe) return;
    Object.keys(PaymentSheetEventsEnum).forEach((key) => {
      const eventName = PaymentSheetEventsEnum[key];
      stripe.addListener(eventName, (value: any) => {
        console.log(`[Event:${eventName}] ${value}`);
      });
    });
  }, [stripe]);

  React.useEffect(() => {
    const run = async () => {
      if (data && stripe && !openedRef.current) {
        openedRef.current = true;
        const a = await stripe.createPaymentSheet({
          setupIntentClientSecret: data.setupIntent,
          customerEphemeralKeySecret: data.ephemeralKey,
          customerId: data.customer,
          merchantDisplayName: "Papuga Zoo",
          returnURL: "com.zozia.app://tabs/discovery",
        });

        await stripe.presentPaymentSheet();
      }
    };
    run();
  }, [data, stripe]);

  return (
    <>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      {children}
    </>
  );
};

type CreateNewPaymentMethodModalProps = {
  isOpen: boolean;
  removeNestedOutlet: () => void;
  prevMatch: match;
  open: () => void;
};

export const CreateNewPaymentMethodModal = ({
  opened,
  removeNestedOutlet = () => {},
  prevMatch,
  nestedRouteComponentProps,
}: CreateNewPaymentMethodModalProps) => {
  const { openCartButton, closeCartButton } = useCart();
  const [user, { refetch }] = useUser();

  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const form = useForm({
    initialValues: {
      phoneNumber: user?.phoneNumber || "",
      verificationCode: "",
    },
  });

  React.useEffect(() => {
    if (opened) {
      closeCartButton();
    }
    return openCartButton;
  }, [opened]);

  return (
    <RouteBackModal
      ref={modalRef}
      prevMatch={prevMatch}
      removeNestedOutlet={removeNestedOutlet}
      nestedRouteComponentProps={nestedRouteComponentProps}
    >
      <IonContent>
        <CreateSetupIntent>
          <form
            className="mt-2 px-8"
            onSubmit={form.onSubmit(async (values) => {
              console.log(values);
            })}
          >
            <Button
              type="submit"
              fullWidth
              radius="lg"
              style={{
                fontWeight: 400,
              }}
              className="my-4"
            >
              Gotowe
            </Button>
          </form>
        </CreateSetupIntent>
      </IonContent>
    </RouteBackModal>
  );
};
