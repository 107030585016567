import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useEventListener } from "@mantine/hooks";
import {
  IconChevronRight,
  IconCoins,
  IconCreditCard,
} from "@tabler/icons-react";
import { IconApplePay, IconBlikPay } from "@zozia/icons";
import cn from "classnames";
import { motion } from "framer-motion";
import * as React from "react";

import { MobileSafeAnimatePresence } from "../../../components/MobileSafeAnimatePresence";
import {
  LocationPaymentMethodType,
  useLocationPaymentMethodsQuery,
} from "../../graphql/graphql";

export const LocationPaymentMethodSelector2 = ({
  value,
  locationId,
  error,
  onChange,
}) => {
  const [_value, setValue] = React.useState(value);
  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const {
    data,
    isLoading,
    refetch: refetchPaymentMethods,
  } = useLocationPaymentMethodsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.locationPaymentMethods.filter(Boolean) || [],
      enabled: !!locationId,
    },
  );

  const selectedOption = data?.find((option) => option.id === _value);

  return (
    <div className="relative">
      <MobileSafeAnimatePresence>
        <LoadingOverlay visible={isLoading} />
      </MobileSafeAnimatePresence>
      {isLoading ? (
        <PaymentMethodSelectorOption
          name="Apple Pay"
          icon={<IconApplePay />}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
        />
      ) : (
        <PaymentMethodSelectorOption
          error={error}
          name={selectedOption?.name}
          icon={getIcon(selectedOption?.type)}
          onClick={() => modalRef.current.present()}
          rightSection={<IconChevronRight />}
        />
      )}

      <IonModal
        ref={modalRef}
        initialBreakpoint={0.75}
        breakpoints={[0, 0.75, 1]}
        onIonModalWillPresent={() => {
          refetchPaymentMethods();
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Metody płatności</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <Stack className="px-4">
            {data?.map((option, idx) => {
              const icon = getIcon(option.type);
              let rightSection;

              switch (option.type) {
                case "ApplePay":
                  break;
                case "Card":
                case "Online":
                  break;
                case "Cash":
                  rightSection = <IconChevronRight />;
                  break;
                default:
              }

              return (
                <motion.div
                  key={option.id}
                  style={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, delay: idx * 0.05 }}
                >
                  <PaymentMethodSelectorOption
                    onClick={async () => {
                      setValue(option.id);
                      await onChange({ paymentMethodId: option.id });
                      await modalRef.current.dismiss();
                    }}
                    key={option.id}
                    name={option.name}
                    rightSection={rightSection}
                    icon={icon}
                  />
                </motion.div>
              );
            })}
          </Stack>
        </IonContent>
      </IonModal>
    </div>
  );
};

type PaymentMethodSelectorOptionProps = {
  onClick?: () => void;
  name: string;
  icon: React.ReactElement;
  rightSection?: React.ReactNode;
  error?: string;
};
const PaymentMethodSelectorOption = ({
  onClick = () => {},
  name,
  icon,
  error,
  rightSection,
}: PaymentMethodSelectorOptionProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickHandler = async () => {
    setIsLoading(true);
    try {
      await Haptics.impact({
        style: ImpactStyle.Light,
      });

      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  const ref = useEventListener("click", onClickHandler);

  return (
    <Group
      className={cn("border border-solid rounded-md py-4 pl-4 pr-2 relative", {
        "border-red-500": error,
      })}
      role="button"
      ref={ref}
    >
      <LoadingOverlay visible={isLoading} />
      <Group justify="space-between" wrap="nowrap" className="w-full">
        <div className="min-w-[30px]">{icon}</div>
        <Stack gap={0} className="w-full grow">
          <Text size="sm" fw={600}>
            Płatność
          </Text>
          <Text
            size="xs"
            className={cn({
              "text-gray-400": !name,
            })}
          >
            {name || "Wybierz metodę płatności"}
          </Text>
          {error ? (
            <Text size="xs" c="red" className="shrink-0">
              {error}
            </Text>
          ) : null}
        </Stack>
        <div className="self-center">{rightSection ? rightSection : null}</div>
      </Group>
    </Group>
  );
};

const getIcon = (type: LocationPaymentMethodType) => {
  switch (type) {
    case "ApplePay":
      return <IconApplePay width={40} />;
    case "Blik":
      return <IconBlikPay width={40} />;
    case "Card":
    case "Online":
      return <IconCreditCard />;
    case "Cash":
      return <IconCoins />;
    default:
      return <IconCoins />;
  }
};
