import { IonContent, IonModal, useIonRouter } from "@ionic/react";
import { ActionIcon, Button, Stack, Text, Title } from "@mantine/core";
import { useDisclosure, usePrevious } from "@mantine/hooks";
import {
  IconExclamationCircle,
  IconMinus,
  IconPlus,
  IconShoppingCartPlus,
} from "@tabler/icons-react";
import cn from "classnames";
import { motion } from "framer-motion";
import * as React from "react";

import { MobileSafeAnimatePresence } from "../../../../components/MobileSafeAnimatePresence";
import { useSession } from "../../../session";
import { login } from "../../../session/utils/auth";
import { Count } from "../Count/Count";

type AddToCartProps = {
  onIncrease: (quantity: number) => void;
  onDecrease: (quantity: number) => void;
  initialValue?: number;
};

export const AddToCart = ({
  initialValue,
  onIncrease,
  onDecrease,
}: AddToCartProps) => {
  const [opened, { open, close }] = useDisclosure();
  const previousValue = usePrevious(initialValue);
  const { snapshot } = useSession();

  const router = useIonRouter();
  const [isModalOpened, { toggle, close: closeModal }] = useDisclosure();
  const { routeInfo } = useIonRouter();

  React.useEffect(() => {
    if (typeof initialValue === "number") {
      if (initialValue > 0) {
        open();
        setValue(initialValue);
      } else {
        close();
      }

      if (initialValue > 0 && initialValue !== previousValue) {
        setValue(initialValue);
      }
    } else {
      close();
    }
  }, [initialValue, previousValue]);

  const [value, setValue] = React.useState(initialValue || 1);

  const increase = () => {
    setValue((v) => {
      const nextValue = v + 1;

      onIncrease(nextValue);
      return nextValue;
    });
  };

  const decrease = () => {
    setValue((v) => {
      const nextValue = v - 1;

      onDecrease(nextValue);

      if (nextValue === 0) {
        close();
      }

      return nextValue;
    });
  };

  const openIncreaseDecrease = () => {
    open();
    setValue(1);
    onIncrease(1);
  };

  const isAddToCartDisabled = !!snapshot && opened;

  return (
    <>
      <div className={cn("flex w-[150px] items-center justify-end")}>
        {isAddToCartDisabled ? (
          <>
            <motion.div
              initial={{ x: 0 }}
              animate={{ x: -25 }}
              exit={{ x: 40 }}
              key="1"
            >
              <ActionIcon radius="xl" size="lg" onClick={decrease}>
                <IconMinus />
              </ActionIcon>
            </motion.div>
            {value === 0 ? null : (
              <motion.div animate={{ x: -13 }} exit={{ opacity: 0 }}>
                <MobileSafeAnimatePresence>
                  <Count value={value} disableInitialAnimation />
                </MobileSafeAnimatePresence>
              </motion.div>
            )}
          </>
        ) : null}
        <div className="relative">
          <motion.div
            initial={{ x: 0 }}
            layoutId="cartIndicator"
            className="absolute left-3 top-3 h-2 w-2 rounded-full bg-blue-500"
          ></motion.div>
          {isAddToCartDisabled ? (
            <motion.div key="2" layoutId="cartButton" className="z-20">
              <ActionIcon radius="xl" size="lg" onClick={increase}>
                <IconPlus />
              </ActionIcon>
            </motion.div>
          ) : (
            <>
              <motion.div key="2" layoutId="cartButton" className="z-20">
                <ActionIcon
                  radius="xl"
                  size="lg"
                  onClick={() => {
                    if (snapshot) {
                      openIncreaseDecrease();
                    } else {
                      toggle();
                    }
                  }}
                >
                  <IconShoppingCartPlus />
                </ActionIcon>
              </motion.div>
            </>
          )}
        </div>
      </div>
      <IonModal
        isOpen={isModalOpened}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.5]}
        onIonModalDidDismiss={closeModal}
      >
        <IonContent>
          <Stack align="center" className="mt-8" gap={0}>
            <IconExclamationCircle size="65" strokeWidth={1} />
            <Title order={3}>Logowanie wymagane</Title>
            <Text className="text-center" c="gray">
              Dodanie produktów do koszyka wymaga konta uzytkownika
            </Text>

            <Stack align="stretch" className="mt-4 w-11/12">
              <Button
                fullWidth
                radius="lg"
                style={{
                  fontWeight: 400,
                }}
                onClick={async () => {
                  closeModal();
                  router.push("/tabs/login");
                }}
              >
                Logowanie
              </Button>
              <Button
                fullWidth
                radius="lg"
                style={{
                  fontWeight: 400,
                }}
                onClick={toggle}
              >
                Anuluj
              </Button>
            </Stack>
          </Stack>
        </IonContent>
      </IonModal>
    </>
  );
};
