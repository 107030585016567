import { IonRouterOutlet } from "@ionic/react";
import { useDisclosure } from "@mantine/hooks";
import * as React from "react";
import {
  Route,
  type match,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

type ModalAsRouteProps = {
  prevMatch: match;
  presentingElement?: HTMLElement | null;
  routes: Record<string, React.ComponentType<{ prevMatch: match }>>;
};

export const ModalAsRoute = ({
  routes,
  prevMatch,
  presentingElement,
}: ModalAsRouteProps) => {
  const location = useLocation();
  useRouteMatch;

  const loc = location?.location || location;

  const [matchedPathname, setMatchPathname] = React.useState(
    !!Object.keys(routes).find((path) => {
      return loc.pathname.startsWith(path) || loc.pathname === path;
    }),
  );
  const [
    isNestedOutletRendered,
    { close: removeNestedOutlet, open: renderNestedOutlet },
  ] = useDisclosure(matchedPathname);
  // TODO: fix this
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  React.useEffect(() => {
    const path = Object.keys(routes).find((path) => {
      return loc.pathname.startsWith(path) || loc.pathname === path;
    });

    if (path) {
      setMatchPathname(true);
      renderNestedOutlet();
    } else {
      setMatchPathname(false);
      removeNestedOutlet();
    }
  }, [loc]);

  React.useEffect(() => {
    if (!isNestedOutletRendered) {
      setMatchPathname(false);
    }
  }, [isNestedOutletRendered]);

  if (!matchedPathname) {
    return null;
  }

  return matchedPathname ? (
    <IonRouterOutlet>
      {Object.entries(routes).map(([path, Component]) => {
        const NestedRouteComponent = Array.isArray(Component)
          ? Component[0]
          : Component;
        const nestedRouteComponentProps = Array.isArray(Component)
          ? Component[1]
          : {};
        const pageProps = Array.isArray(Component) ? Component[2] : {};
        return (
          <Route
            path={path}
            key={path}
            render={() => {
              return (
                <NestedRouteComponent
                  prevMatch={prevMatch}
                  presentingElement={presentingElement}
                  opened={isNestedOutletRendered}
                  removeNestedOutlet={removeNestedOutlet}
                  open={renderNestedOutlet}
                  nestedRouteComponentProps={nestedRouteComponentProps}
                  pageProps={pageProps}
                />
              );
            }}
          />
        );
      })}
    </IonRouterOutlet>
  ) : null;
};
