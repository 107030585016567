import * as React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { LoadingOverlay } from "@mantine/core";

import { useLocationPaymentMethodsQuery } from "../../graphql/graphql";

export const LocationPaymentMethodSelector = ({
  value,
  locationId,
  onChange,
}) => {
  const [_value, setValue] = React.useState(value);

  const { data, isLoading } = useLocationPaymentMethodsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.locationPaymentMethods.filter(Boolean) || [],
      enabled: !!locationId,
    },
  );

  return (
    <div className="relative">
      <LoadingOverlay visible={isLoading} />
      <IonSelect
        value={_value}
        interface="action-sheet"
        label="Metoda płatności"
        placeholder="Płatność online"
        onIonChange={(e) => {
          onChange({
            paymentMethodId: e.detail.value,
          });
          setValue(e.detail.value);
        }}
      >
        {data?.map((option) => (
          <IonSelectOption key={option.id} value={option.id}>
            {option.name}
          </IonSelectOption>
        ))}
      </IonSelect>
      {/* {!!_value ? (
        <Text c="gray" size="xs">
          {selectedOptionDescription}
        </Text>
      ) : null} */}
    </div>
  );
};
