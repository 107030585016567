import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Indicator } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { motion, useAnimation } from "framer-motion";
import * as React from "react";

import { OptimizedOfferCard } from "../../../locations/components/ProductCardList/ProductCardList";

type ReplacementOfferCardProps = {
  offer: any;
  onClick: () => void;
  selected: boolean;
};

export const ReplacementOfferCard = ({
  offer,
  onClick,
  selected,
}: ReplacementOfferCardProps) => {
  const controls = useAnimation();

  return (
    <motion.div animate={controls}>
      <Indicator
        onClick={async () => {
          onClick();
          await Haptics.impact({
            style: ImpactStyle.Light,
          });
          await controls.start(
            {
              opacity: 0.8,
            },
            { duration: 0.1 },
          );
          await controls.start(
            {
              opacity: 1,
            },
            { duration: 0.1 },
          );
        }}
        size={30}
        offset={15}
        label={<IconCheck size="16" />}
        withBorder
        color="white"
        style={{
          "--mantine-color-body": "var(--mantine-primary-color-filled)",
          ...(selected
            ? {
                "--indicator-color": "var(--mantine-primary-color-filled)",
              }
            : {}),
        }}
      >
        <OptimizedOfferCard
          offer={offer}
          root="replacementModalContent"
          onClick={() => {}}
        />
      </Indicator>
    </motion.div>
  );
};
