import { RefresherEventDetail } from "@ionic/react";
import { useParams } from "react-router-dom";

import {
  useCategoriesFromProductsQuery,
  useLocationQuery,
  useOffersShowcaseQuery,
} from "../../graphql/graphql";

export const useLocationData = () => {
  const { locationId } = useParams<{ locationId: string }>();

  const {
    data: locationQueryResult,
    isLoading: isLoadingLocationQuery,
    refetch: refetchLocation,
  } = useLocationQuery({ id: locationId });
  const {
    data: productsByLocationIdResult,
    isLoading: isLoadingProductsByLocationIdQuery,
    refetch: refetchLocationProducts,
  } = useOffersShowcaseQuery({ locationId });

  const {
    data: categoriesFromProductsResult,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
  } = useCategoriesFromProductsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.categoriesFromProducts,
    },
  );

  return {
    location: locationQueryResult?.location,
    offers:
      productsByLocationIdResult?.offersShowcase.filter(
        (p) => p.offers.length > 0,
      ) ?? [],
    categories: categoriesFromProductsResult,
    isLoadingLocation: isLoadingLocationQuery,
    isLoadingProducts: isLoadingProductsByLocationIdQuery,
    isCategoriesLoading,
    refetchLocation,
    refetchLocationProducts,
    refreshLocationPage: async (event: CustomEvent<RefresherEventDetail>) => {
      await Promise.all([
        refetchLocation(),
        refetchLocationProducts(),
        refetchCategories(),
      ]);
      event.detail.complete();
    },
  };
};
