import { Card, Text, Title } from "@mantine/core";
import cn from "classnames";
import * as React from "react";

import css from "./SummarySection.module.css";

type SummarySectionProps = {
  title: React.ReactNode;
  description?: string;
  className?: string;
  children: React.ReactNode;
  inset?: boolean | "1" | "2" | "3" | "4" | "clear";
  classNames?: {
    section?: string;
  };
};
export const SummarySection = ({
  title,
  description,
  children,
  className,
  classNames,
  inset,
}: SummarySectionProps) => {
  const isInsetNumber = typeof inset === "string";
  return (
    <>
      <div className={css.pattern}></div>
      <Card
        className={className}
        classNames={{
          root: "bg-white -mx-4 px-8 rounded-b-none",
        }}
      >
        <Card.Section className={cn(classNames?.section)}>
          <Title order={3}>{title}</Title>
          {description ? <Text size="sm">{description}</Text> : null}
        </Card.Section>
        <div
          className={
            inset
              ? isInsetNumber
                ? cn({
                    "-mx-1": parseInt(inset, 10) === 1,
                    "-mx-2": parseInt(inset, 10) === 2,
                    "-mx-3": parseInt(inset, 10) === 3,
                    "-mx-4": parseInt(inset, 10) === 4,
                  })
                : "-mx-8"
              : null
          }
        >
          {inset === "clear" ? (
            <Card.Section>{children}</Card.Section>
          ) : (
            children
          )}
        </div>
      </Card>
    </>
  );
};
