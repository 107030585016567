import { Stack, Text } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import * as React from "react";

import { useSession } from "../useSession";

function str_pad_left(string: string, pad: string, length: number) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export const SessionDebug = () => {
  const { snapshot } = useSession();
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [remainingRefreshTime, setRemainingRefreshTime] = React.useState(0);

  const checkToken = async () => {
    const dateTimeNowMs = Date.now();

    if (snapshot) {
      const tokenExp = snapshot.user.exp * 1000;
      const issuedAt = snapshot.user.iat * 1000;

      const refreshTokenExp =
        issuedAt + Math.round(snapshot.refreshExpiresIn * 1000);

      const isTokenExpired = tokenExp < dateTimeNowMs;
      const isRefreshTokenExpired = refreshTokenExp < dateTimeNowMs;

      if (isTokenExpired) {
      } else {
        setRemainingTime(Math.round((tokenExp - dateTimeNowMs) / 1000));
      }

      if (isRefreshTokenExpired) {
      } else {
        setRemainingRefreshTime(
          Math.round((refreshTokenExp - dateTimeNowMs) / 1000),
        );
      }
    }
  };

  const interval = useInterval(checkToken, 1000);

  React.useEffect(() => {
    if (snapshot) {
      interval.start();
    }
    return interval.stop;
  }, [interval, snapshot]);

  const expirationTokenMinutes = Math.floor(remainingTime / 60);
  const expirationTokenSeconds = remainingTime - expirationTokenMinutes * 60;

  const expirationRefreshTokenMinutes = Math.floor(remainingRefreshTime / 60);
  const expirationRefreshTokenSeconds =
    remainingRefreshTime - expirationRefreshTokenMinutes * 60;

  return (
    <Stack>
      <Text>
        Token wygaśnie za:{" "}
        {`${expirationTokenMinutes}:${str_pad_left(expirationTokenSeconds.toString(), "0", 2)}`}
      </Text>
      <Text>
        Token odświeżania wygaśnie za:{" "}
        {`${expirationRefreshTokenMinutes}:${str_pad_left(expirationRefreshTokenSeconds.toString(), "0", 2)}`}
      </Text>
      <pre>{JSON.stringify(snapshot, null, 2)}</pre>
    </Stack>
  );
};
