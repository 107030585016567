import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { CapacitorStripeProvider } from "@capacitor-community/stripe/dist/esm/react/provider";
import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import { IonApp, IonRouterOutlet, IonSpinner, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.layer.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import * as React from "react";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import { CurrentTabViewManager } from "./CurrentTabViewManager";
import { TabBar } from "./TabBar";
import { SplashContainer } from "./components/SplashContainer/SplashContainer";
import { queryClient } from "./features/api";
import { CartPage } from "./features/cart";
import { CartProvider } from "./features/cart/CartProvider";
import { LocationCartPage } from "./features/cart/pages/LocationCartPage";
import { ListLocationsPageByProductsPage } from "./features/discovery";
import { Discovery } from "./features/discovery/components/Discovery";
import { SearchPage } from "./features/discovery/pages/SearchPage";
import {
  CategorySearchPage,
  LocationDetailPage,
  ProductDetailPage,
} from "./features/locations";
import { OrderPage } from "./features/orders/pages/OrderPage";
import AuthProvider from "./features/session/AuthProvider";
import { AccountPage } from "./features/session/pages/AccountPage";
import { LoginCompletedPage } from "./features/session/pages/LoginCompleted";
import { LoginPage } from "./features/session/pages/LoginPage";
import { NotificationsPage } from "./features/session/pages/NotificationsPage";
import { PaymentsPage } from "./features/session/pages/PaymentsPage";
import { ProfilePage } from "./features/session/pages/ProfilePage";
import { GlobalScope } from "./features/stories";
import "./global.css";
import "./theme/variables.css";

const logCurrentPage = () => {
  FirebaseAnalytics.setScreenName({
    screenName: document.title,
  });
  FirebaseAnalytics.logEvent({
    name: "page_view",
  });
};

const AnalyticsComponent = () => {
  const history = useHistory();
  React.useEffect(() => {
    logCurrentPage(); // log the first page visit
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);
  return null;
};

const Tabs = () => {
  const { url } = useRouteMatch();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route
          exact
          path={`${url}`}
          render={() => <Redirect to="/tabs/discovery" />}
        />
        <Route
          exact
          path={`${url}/order/:orderId`}
          render={() => <OrderPage />}
        />
        <Route exact path={`${url}/discovery`} render={() => <Discovery />} />
        <Route
          exact
          path={`${url}/discovery/search`}
          render={() => <SearchPage />}
        />
        <Route
          exact
          path={`${url}/discovery/search/by-products/:productId`}
          render={() => <ListLocationsPageByProductsPage />}
        />
        <Route
          exact
          path={`${url}/discovery/locations/:locationId`}
          render={() => <LocationDetailPage />}
        />
        <Route
          exact
          path={`${url}/discovery/locations/:locationId/cart`}
          render={() => <LocationCartPage />}
        />
        <Route
          exact
          path={`${url}/discovery/locations/:locationId/offer/:productId`}
          render={() => <ProductDetailPage />}
        />
        <Route
          exact
          path={`${url}/discovery/locations/:locationId/categories/:categoryId`}
          render={() => <CategorySearchPage />}
        />
        <Route
          exact
          path={`${url}/login-completed`}
          render={() => <LoginCompletedPage />}
        />
        <Route
          path={`${url}/settings/account`}
          render={() => <AccountPage />}
        />
        <Route
          path={`${url}/settings/payments`}
          render={() => <PaymentsPage />}
        />
        <Route
          exact
          path={`${url}/settings/notifications`}
          render={() => <NotificationsPage />}
        />
        <Route
          path={`${url}/profile`}
          render={() => (
            <React.Suspense fallback="Loading...">
              <ProfilePage />
            </React.Suspense>
          )}
        />
        <Route path={`${url}/cart`} render={() => <CartPage />} />
        <Route exact path={`${url}/login`} render={() => <LoginPage />} />
      </IonRouterOutlet>
      <TabBar slot="bottom" />
    </IonTabs>
  );
};

const InitialAppLoader = () => (
  <div className="flex h-screen items-center justify-center">
    <IonSpinner />
  </div>
);

const getPathnameFromCustomUrlScheme = (url: string) => {
  const [, secondPart] = url.split(":/");
  const startQueryParams = secondPart.indexOf("?");

  if (startQueryParams === -1) {
    return secondPart;
  }
  return secondPart.slice(0, startQueryParams);
};

const AppUrlListener = () => {
  const history = useHistory();

  React.useEffect(() => {
    CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      console.log("App opened with URL: " + event.url);
      const pathname = getPathnameFromCustomUrlScheme(event.url);

      // LOGIN COMPLETED
      if (pathname === "/tabs/login-completed") {
        history.push(pathname);
        return;
      }

      // LOGOUT COMPLETED
      if (pathname === "/tabs/login") {
        console.log("pushing to login");
        setTimeout(() => {
          history.push(pathname);
        }, 100);
      }
    });
  }, []);

  return null;
};

export const App = () => {
  return (
    <IonApp>
      <CapacitorStripeProvider publishableKey="pk_test_51OKKO1FGwC0cPrV18D0N3VoMPgd9TKP6wYdHamS11qG1Ov4MZ0qJN1opT5GUvZsARbIjpXF1JA57poZypJ02UE3z00MlMawYU7">
        <SplashContainer>
          <MantineProvider cssVariablesSelector="html">
            <QueryClientProvider client={queryClient}>
              <React.Suspense fallback={null}>
                {process.env.NODE_ENV === "production" ? null : (
                  <ReactQueryDevtools />
                )}
              </React.Suspense>

              <Provider
                initialValues={[[queryClientAtom, queryClient]] as const}
                scope={GlobalScope}
              >
                <AuthProvider>
                  <React.Suspense fallback={<InitialAppLoader />}>
                    <AppRouter />
                  </React.Suspense>
                </AuthProvider>
              </Provider>
            </QueryClientProvider>
          </MantineProvider>
        </SplashContainer>
      </CapacitorStripeProvider>
    </IonApp>
  );
};

const AppRouter = () => {
  return (
    <IonReactRouter>
      <AppUrlListener />
      <AnalyticsComponent />
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <CartProvider>
          <CurrentTabViewManager>
            <IonRouterOutlet>
              <Route path="/tabs" render={() => <Tabs />} />
              <Route exact path="/" render={() => <Redirect to="/tabs" />} />
            </IonRouterOutlet>
          </CurrentTabViewManager>
        </CartProvider>
      </QueryParamProvider>
    </IonReactRouter>
  );
};
