import { IonSkeletonText, IonThumbnail } from "@ionic/react";
import { motion } from "framer-motion";
import * as React from "react";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";

type StoryCircleTypes = {
  image?: string;
  title?: string;
  motionProps?: {
    layoutId?: string;
  };
  onClick?: () => void;
  children?: React.ReactNode;
};

export const StoryCircle = ({
  image,
  title,
  motionProps,
  children,
  onClick = () => {},
}: StoryCircleTypes) => {
  const Component = motionProps ? motion.div : "div";
  return (
    <div onClick={onClick} className="relative">
      <Component
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        {...motionProps}
      >
        <div className="p-1"></div>
      </Component>
      <div className="relative">
        <div
          className={`absolute -inset-[2.5px] rounded-full bg-gradient-to-r from-blue-600 to-white`}
        ></div>
        <div className="relative rounded-full bg-white p-1">
          {image ? (
            <AutoAlignImage
              rounded="full"
              onLoaded={() => {}}
              className="block rounded-full"
              src={image}
              placeholder={
                <IonThumbnail slot="start">
                  <IonSkeletonText className="rounded-full" animated />
                </IonThumbnail>
              }
              style={{
                objectFit: "cover",
                width: 56,
                height: 56,
              }}
            />
          ) : (
            children
          )}
        </div>
      </div>
      {title ? (
        <p className="mb-0 w-16 truncate pt-1 text-center text-xs">{title}</p>
      ) : null}
    </div>
  );
};
