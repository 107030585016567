import { isPlatform } from "@ionic/react";
import cn from "classnames";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import * as React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

type DeliveryMapProps = {
  position: {
    longitude: number;
    latitude: number;
  };
  classNames?: string;
  onDragEnd?: (latlng: L.LatLng) => void;
  zoomOptions: {
    zoomControl: boolean;
  };
  children?: React.ReactNode;
};

export const DeliveryMap = ({
  position,
  onDragEnd,
  children,
  classNames,
  ...props
}: DeliveryMapProps) => {
  return (
    <div className={cn("relative h-[300px]", classNames)}>
      <MapContainer
        renderer={L.canvas({
          padding: 0.1,
        })}
        center={[51.9189046, 19.1343786]}
        zoomControl={!isPlatform("hybrid")}
        className="h-full"
        zoom={5}
        scrollWheelZoom={false}
        {...props}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <ResizeMap />
        <LocationMarker position={position} onDragEnd={onDragEnd} />
        {children}
      </MapContainer>
    </div>
  );
};

export const DeliveryMap2 = ({
  position,
  onDragEnd,
  children,
  classNames,
  ...props
}: DeliveryMapProps) => {
  return (
    <div className={cn("relative", classNames)}>
      <MapContainer
        center={[51.9189046, 19.1343786]}
        zoomControl={!isPlatform("hybrid")}
        className="h-full"
        zoom={12}
        scrollWheelZoom={false}
        {...props}
      >
        <RendererMapOptions padding={100} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          updateWhenIdle={false}
        />
        <ResizeMap />
        {children}
      </MapContainer>
    </div>
  );
};

type LocationMarkerProps = {
  bounds: [number, number][];
};

export function FitBounds({ bounds }: LocationMarkerProps) {
  const map = useMap();

  React.useEffect(() => {
    map.fitBounds(bounds, { padding: [150, 150] });
  }, [map]);

  return null;
}

export function LocationMarker({ position, onDragEnd }: LocationMarkerProps) {
  const [dynamicPosition, setDynamicPosition] = React.useState(
    L.latLng(position.latitude, position.longitude),
  );
  const [allowToCustomSetPosition, setAllowToCustomSetPosition] =
    React.useState(false);

  const map = useMapEvents({
    move() {
      if (allowToCustomSetPosition) {
        const latlng = map.getCenter();
        setDynamicPosition(latlng);
      }
    },
    zoomend() {
      if (onDragEnd) {
        setAllowToCustomSetPosition(true);
      }
    },
    dragend() {
      if (allowToCustomSetPosition) {
        const latlng = map.getCenter();
        onDragEnd?.(latlng);
      }
    },
  });

  React.useEffect(() => {
    map.flyTo([position.latitude, position.longitude], 18, {
      duration: 2,
    });
  }, [map]);

  return dynamicPosition === null ? null : (
    <Marker position={dynamicPosition}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

const RendererMapOptions = ({ padding }: { padding: number }) => {
  const map = useMap();
  // @ts-ignore
  map.getRenderer(map).options.padding = padding || 10;

  return null;
};

const ResizeMap = () => {
  const map = useMap();

  map.invalidateSize();
  return null;
};
