import cn from "classnames";
import * as React from "react";

type ButtonTypes = "primary" | "secondary" | "ghost";

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  type?: ButtonTypes;
  size: "lg" | "md" | "sm";
  [key: string]: any;
};

export const Button = ({
  children,
  className,
  type,
  size,
  ...props
}: ButtonProps) => {
  const buttonType = type || "primary";

  const classNames = cn(
    "border-0 font-body bg-gradient-to-r from-blue-500 to-teal-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
    {
      "bg-clip-text text-transparent": buttonType === "ghost",
      "text-white": buttonType === "primary",
      "h-[60px]": size === "lg",
    },
    className,
  );
  return (
    <button className={classNames} {...props}>
      {children}
    </button>
  );
};
