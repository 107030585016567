import {
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonRow,
  IonSkeletonText,
  IonText,
  RefresherEventDetail,
} from "@ionic/react";
import { Group } from "@mantine/core";
import { Price } from "@zozia/ui";
import * as React from "react";
import { useParams } from "react-router-dom";

import { ProductImageCarousel } from "../../../../components/ProductImageCarousel";
import { useOfferFromLocationQuery } from "../../../graphql/graphql";
import { AddToCart } from "../../../locations/components/AddToCart/AddToCart";
import { useCart } from "../../CartContext";

type OfferModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  presentingElement?: HTMLElement;
  selectedItem?: any;
};

export const OfferModal = ({
  forwardRef,
  presentingElement,
  selectedItem,
}: OfferModalProps) => {
  const params = useParams<{
    locationId: string;
    productId: string;
  }>();
  const {
    data: offerQueryResult,
    isLoading: isLoadingProductQuery,
    refetch: refetchOffer,
  } = useOfferFromLocationQuery(
    {
      id: selectedItem?.offer?.id,
    },
    {
      enabled: !!selectedItem?.id,
    },
  );

  const { getCartItem, removeItem, addItem, increaseItem, decreaseItem } =
    useCart();

  const cartItem = getCartItem(offerQueryResult?.offer.id);
  return (
    <IonModal ref={forwardRef} presentingElement={presentingElement}>
      <IonContent fullscreen>
        {isLoadingProductQuery ? (
          <IonSkeletonText style={{ height: 200 }} animated />
        ) : (
          <>
            <ProductImageCarousel
              items={offerQueryResult.offer.product.images}
            />
            <div className="p-4">
              <IonText>
                <h1 className="text-3xl">
                  {offerQueryResult.offer.product.name}
                </h1>
              </IonText>
              <IonText>
                {offerQueryResult.offer.lastPrice ? (
                  <Group justify="space-between">
                    <div className="py-2 text-4xl font-medium">
                      <Price value={offerQueryResult.offer.lastPrice.value} />
                    </div>
                    <AddToCart
                      initialValue={cartItem?.quantity}
                      onIncrease={(quantity) => {
                        if (quantity === 1) {
                          addItem({
                            offerId: offerQueryResult.offer.id,
                            locationId: params.locationId,
                            price: offerQueryResult.offer.lastPrice.value,
                          });
                        } else {
                          increaseItem(cartItem.id);
                        }
                      }}
                      onDecrease={async (quantity) => {
                        if (quantity <= 0) {
                          await removeItem(cartItem.id);
                        } else {
                          await decreaseItem(cartItem.id);
                        }
                      }}
                    />
                  </Group>
                ) : null}
              </IonText>
              {offerQueryResult.offer.variants.length > 0 ? (
                <IonText className="font-bold">
                  Warianty{" "}
                  {offerQueryResult.offer.variants
                    .map((variantGroup) => variantGroup.type)
                    .join("/")}
                </IonText>
              ) : null}
              <IonGrid className="text-s">
                <IonRow>
                  {offerQueryResult.offer.variants
                    .flatMap((variantGroup) => variantGroup.items)
                    .map((variant) => (
                      <IonCol
                        size="4"
                        key={variant.id}
                        style={{ color: "#3b82f6" }}
                      >
                        <IonRouterLink
                          routerLink={`/tabs/discovery/locations/${params.locationId}/offer/${variant.id}`}
                          className="text-xs text-slate-800"
                        >
                          <div className="border-color-inherit rounded-lg border border-solid p-2 text-center">
                            {variant.variant}
                          </div>
                        </IonRouterLink>
                      </IonCol>
                    ))}
                </IonRow>
              </IonGrid>
              <div
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: offerQueryResult.offer.product.description,
                }}
              />
            </div>
          </>
        )}
      </IonContent>
    </IonModal>
  );
};
