const fixed = function (number, digits) {
  if (digits == null) {
    digits = 0;
  }
  const radix = Math.pow(10, digits);
  return (Math.ceil(number * radix) / radix).toFixed(digits);
};
export const Distance = (function () {
  function Distance(distance, unit) {
    let radians, value, _, _ref;
    if (!(this instanceof Distance)) {
      return new Distance(distance, unit);
    }
    if (unit) {
      radians = distance / Distance.unit_conversion[unit];
      unit = unit;
    } else if (typeof distance === "string") {
      distance = distance.replace(/^\s+|\s+$/g, "").replace(/,/, ".");
      (_ref = distance.match(Distance.distance_regexp) || []),
        (_ = _ref[0]),
        (value = _ref[1]),
        (unit = _ref[2]);
      if (value) {
        radians = value / Distance.unit_conversion[unit];
      }
    }
    if (radians == null) {
      radians = distance * 1.0;
    }
    this.radians = radians;
    this.in_good_unit();
  }
  Distance.prototype.in_unit = function (unit) {
    this.unit = unit;
    return this;
  };
  Distance.prototype.in_good_unit = function () {
    if (this.radians < 1.1 / Distance.unit_conversion["km"]) {
      this.unit = "m";
    } else {
      this.unit = "km";
    }
    return this;
  };
  Distance.prototype.human_readable_in_units = function (
    large_unit,
    small_unit,
  ) {
    let dist, unit;
    unit = large_unit;
    dist = this.radians * Distance.unit_conversion[unit];
    if (dist < 1.1) {
      unit = small_unit;
      dist = this.radians * Distance.unit_conversion[unit];
    }
    return {
      distance_earth_radians: this.radians,
      distance: dist < 10 ? fixed(dist, 1) : fixed(dist),
      unit: unit,
      toString: function () {
        return "" + this.distance + " " + this.unit;
      },
    };
  };
  Distance.prototype.human_readable = function (system) {
    system || (system = this.system());
    switch (system) {
      case "customary":
        return this.human_readable_in_units("mi", "ft");
      default:
        return this.human_readable_in_units("km", "m");
    }
  };
  Distance.prototype.system = function () {
    return Distance.systems[this.unit];
  };
  Distance.prototype.valueOf = function () {
    return this.radians;
  };
  Distance.prototype.toString = function () {
    return (
      "" + this.radians * Distance.unit_conversion[this.unit] + " " + this.unit
    );
  };
  return Distance;
})();
if (typeof window !== "undefined" && window !== null) {
  window.Distance = Distance;
} else {
  module.exports = Distance;
}
Distance.between = function (A, B) {
  let A_lat, B_lat, d_lon, degrees_to_radians;
  degrees_to_radians = Math.PI / 180;
  A_lat = (A.lat || A[0] || 0) * degrees_to_radians;
  B_lat = (B.lat || B[0] || 0) * degrees_to_radians;
  d_lon =
    Math.abs((B.lon || B[1] || 0) - (A.lon || A[1] || 0)) * degrees_to_radians;
  return new Distance(
    Math.atan2(
      Math.sqrt(
        Math.pow(Math.cos(B_lat) * Math.sin(d_lon), 2.0) +
          Math.pow(
            Math.cos(A_lat) * Math.sin(B_lat) -
              Math.sin(A_lat) * Math.cos(B_lat) * Math.cos(d_lon),
            2.0,
          ),
      ),
      Math.sin(A_lat) * Math.sin(B_lat) +
        Math.cos(A_lat) * Math.cos(B_lat) * Math.cos(d_lon),
    ),
  );
};
Distance.unit_conversion = {
  km: 6372.8,
  m: 6372800,
  meter: 6372800,
  metres: 6372800,
  mi: 3959.9,
  ml: 3959.9,
  mile: 3959.9,
  miles: 3959.9,
  yd: 6969379,
  yard: 6969379,
  yards: 6969379,
  ft: 20908136,
  feet: 20908136,
};
Distance.systems = {
  km: "metric",
  m: "metric",
  meter: "metric",
  metres: "metric",
  mi: "customary",
  ml: "customary",
  mile: "customary",
  miles: "customary",
  yd: "customary",
  yard: "customary",
  yards: "customary",
  ft: "customary",
  feet: "customary",
};
Distance.distance_regexp = RegExp(
  "^([\\d\\.]+)\\s*(" + Object.keys(Distance.unit_conversion).join("|") + ")$",
);
