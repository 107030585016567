import { Button, Group, Text } from "@mantine/core";
import { Price } from "@zozia/ui";
import cn from "classnames";
import * as React from "react";

import { useCart } from "../CartContext";

type PayButtonProps = {
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const PayButton = ({
  onClick,
  isLoading,
  isDisabled,
}: PayButtonProps) => {
  const { totalSum } = useCart();

  return (
    <Button
      radius="xl"
      size="md"
      fullWidth
      loading={isLoading}
      disabled={isDisabled}
      classNames={{
        root: "p-0",
        label: "w-full",
      }}
      onClick={onClick}
    >
      <Group justify="space-between" className="w-full" wrap="nowrap">
        <div className="w-[90px] min-w-0"></div>
        <div>Zamów i zapłać</div>
        <div
          className={cn("w-[90px] border rounded-full py-1 px-2 mr-2 ", {
            "bg-gray-200": isDisabled,
            "bg-blue-400": !isDisabled,
          })}
        >
          <Price as={Text} fw="300" fz={12} value={totalSum} />
        </div>
      </Group>
    </Button>
  );
};
