import { Card, Group, Stack, Text } from "@mantine/core";
import { Img, Price, cropImageTo } from "@zozia/ui";
import * as React from "react";

import { useCart } from "../CartContext";
import { ChangeQuantity } from "./ChangeQuantity";

const cropImageToDesiredSize = cropImageTo(50, 50);

export const ShoppingCartItem = ({ item }) => {
  const { addItem, removeItem, increaseItem, decreaseItem } = useCart();

  return (
    <Card bg="#FFFFFF" radius="lg" padding="xs">
      <Group wrap="nowrap" align="start">
        {item?.offer?.product?.primaryImage?.url ? (
          <Img
            className="h-[50px] w-[50px] rounded-xl"
            src={cropImageToDesiredSize(item.offer.product.primaryImage.url)}
          />
        ) : null}
        <Stack className="-mt-1 w-full" gap={4}>
          <Text size="xs" fw="600">
            {item.offer.product.name}
          </Text>
          <Text size="xs">
            <Price value={item.price} />
          </Text>
          <Group wrap="nowrap" grow>
            <Text
              size="xs"
              td="underline"
              c="blue"
              onClick={() => removeItem(item.id)}
            >
              Usuń
            </Text>
            <ChangeQuantity
              initialValue={item.quantity}
              onIncrease={(quantity) => {
                if (quantity === 1) {
                  addItem({
                    offerId: item.offer.id,
                    locationId: location.id,
                    price: item.price,
                  });
                } else {
                  increaseItem(item.id);
                }
              }}
              onDecrease={async (quantity) => {
                if (quantity <= 0) {
                  await removeItem(item.id);
                } else {
                  await decreaseItem(item.id);
                }
              }}
            />
          </Group>
        </Stack>
      </Group>
    </Card>
  );
};
