import * as React from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";

import { useSession } from "./useSession";

const AuthInterceptorContext = React.createContext<{ api: AxiosInstance }>({
  api: axios,
});

export const AuthInterceptorProvider = ({ children }) => {
  const { getAccessToken, invalidate } = useSession();

  const instance = React.useRef(
    axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    }),
  );
  const api = instance.current;
  api.defaults.baseURL = process.env.DATA_SERVICE;

  api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const accessToken = await getAccessToken();
    if (accessToken) config!.headers!.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  api.interceptors.response.use(
    (response: AxiosResponse<any>) => response,
    async (error: any) => {
      if (error.response.status === 401) {
        await invalidate();
        return Promise.reject({ ...error, message: "Unauthorized session." });
      }
      return Promise.reject(error);
    },
  );

  return (
    <AuthInterceptorContext.Provider value={{ api }}>
      {children}
    </AuthInterceptorContext.Provider>
  );
};

export const useAuthInterceptor = () => {
  const { api } = React.useContext(AuthInterceptorContext);

  if (api === undefined) {
    throw new Error(
      "useAuthInterceptor must be used within an AuthInterceptorProvider",
    );
  }

  return { api };
};
