import * as React from "react";
import cn from "classnames";

import { SegmentContext } from "./context";

type SegmentProps = {
  children: React.ReactNode;
  value?: any;
};

export const Segment = ({ children, value }: SegmentProps) => {
  const [state, setState] = React.useState(value);

  const onClick = (value: any) => {
    console.log(value);
    setState(value);
  };
  return (
    <SegmentContext.Provider value={{ value: state, onClick }}>
      <ul
        className="scrollbar-hide mx-2 flex space-x-4 overflow-x-auto p-0"
        style={{ listStyleType: "none" }}
      >
        {children}
      </ul>
    </SegmentContext.Provider>
  );
};
