import { useDebouncedHandler } from "@zozia/ui";
import * as React from "react";

import { Searchbar } from "../../components/Searchbar";
import { PageContent } from "./PageContent";
import { PageLayout } from "./PageLayout";

export const SearchPage = () => {
  const [searchText, setSearchText] = React.useState("");

  const debouncedSetSearch = useDebouncedHandler((searchText) => {
    setSearchText(searchText);
  }, 350);

  return (
    <PageLayout>
      <Searchbar
        value={searchText}
        autofocus
        onChange={debouncedSetSearch}
        onClear={() => setSearchText("")}
      />
      <PageContent searchText={searchText} setSearchText={setSearchText} />
    </PageLayout>
  );
};
