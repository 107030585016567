import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IonModal, IonSkeletonText } from "@ionic/react";
import { Button, Group, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { range } from "@zozia/std";
import * as React from "react";

import { Snap } from "../../../../components/Snap";
import { SnapItem3 } from "../../../../components/SnapItem";
import {
  useRelatedProductsByCategoryQuery,
  useSetCartItemReplacementMutation,
} from "../../../graphql/graphql";
import { useCart } from "../../CartContext";
import css from "./ReplacementModal.module.css";
import { ReplacementOfferCard } from "./ReplacementOffer";

type ReplacementModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  onDidDismiss?: () => void;
  selectedItem: any;
};

type ReplacementType = "replacement" | "refund";

export const ReplacementModal = ({
  forwardRef,
  selectedItem,
  onDidDismiss,
}: ReplacementModalProps) => {
  const [replacementType, setReplacementType] =
    React.useState<ReplacementType>("replacement");
  const queryClient = useQueryClient();
  const { cart } = useCart();

  const { mutate } = useSetCartItemReplacementMutation({
    onMutate: ({ input }) => {
      forwardRef.current.dismiss();
      const { shoppingCarts } = queryClient.getQueryData(["ShoppingCarts"]);

      const thisCart = shoppingCarts.find((c) => c.id === cart.id);

      thisCart.items = thisCart.items.map((item) => {
        if (item.id === selectedItem.id) {
          item.replacement = {
            id: input.replacementId ? input.replacementId : null,
          };
        }
        return item;
      });

      queryClient.setQueryData(["ShoppingCarts"], (old) => {
        return {
          shoppingCarts: old.shoppingCarts.map((c) => {
            if (c.id === cart.id) {
              return thisCart;
            }
            return c;
          }),
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["ShoppingCarts"] });
    },
  });

  const form = useForm({
    initialValues: {
      replacementId: "",
      cartItemId: selectedItem?.id,
      replacementType: "SelfReplacement",
    },
  });

  React.useEffect(() => {
    form.setFieldValue("replacementId", selectedItem?.replacement?.id);
    form.setFieldValue("cartItemId", selectedItem?.id);
  }, [selectedItem]);

  const handleSubmit = async (values) => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });
    mutate({
      input: values,
    });
  };

  const isAllowedToRequestForReplacementsItem =
    !!cart && !!selectedItem?.offer?.product?.categoryId;

  const { data: relatedProductsQueryResult, fetchStatus } =
    useRelatedProductsByCategoryQuery(
      {
        input: {
          locationId: cart?.location?.id,
          categoryId: selectedItem?.offer?.product?.categoryId,
          take: 5,
        },
      },
      {
        enabled: isAllowedToRequestForReplacementsItem,
      },
    );

  const relatedOffersCount =
    relatedProductsQueryResult?.offertsFromCategory?.data?.length ?? 0;

  return (
    <IonModal
      ref={forwardRef}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      style={{
        "--border-radius": "20px",
        "--height": "auto",
      }}
      onDidDismiss={() => {
        onDidDismiss?.();
      }}
      onIonModalWillPresent={() => {
        setReplacementType("replacement");
      }}
      className={css.self}
    >
      <Stack className="p-4" id="replacementModalContent">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Text className="mt-4" size="sm">
            Jeżeli sprzedawca nie znajdzie produktu, powinien:
          </Text>
          <Group grow>
            <Button
              radius="xl"
              size="md"
              className="my-4"
              variant={
                replacementType === "replacement" ? "light" : "transparent"
              }
              onClick={async () => {
                await Haptics.impact({
                  style: ImpactStyle.Light,
                });
                setReplacementType("replacement");
              }}
            >
              Zamienić
            </Button>
            <Button
              radius="xl"
              size="md"
              className="my-4"
              variant={replacementType === "refund" ? "light" : "transparent"}
              onClick={async () => {
                await Haptics.impact({
                  style: ImpactStyle.Light,
                });
                setReplacementType("refund");
              }}
            >
              Zwrot
            </Button>
          </Group>
          {replacementType === "replacement" && (
            <Snap className="mx-1 gap-1">
              {fetchStatus === "fetching"
                ? range(4).map((i) => (
                    <SnapItem3 key={i} width="half">
                      <div className="h-[115px]">
                        <IonSkeletonText
                          animated
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                      <div className="py-1 px-3">
                        <div>
                          <IonSkeletonText className="line-clamp-2 min-h-[30px]" />
                          <IonSkeletonText className="h-[20px]" />
                        </div>
                      </div>
                    </SnapItem3>
                  ))
                : relatedProductsQueryResult?.offertsFromCategory.data.map(
                    (offer) => {
                      return (
                        <SnapItem3 key={offer.id} width="half">
                          <ReplacementOfferCard
                            offer={offer}
                            selected={form.values.replacementId === offer.id}
                            onClick={async () => {
                              await Haptics.impact({
                                style: ImpactStyle.Light,
                              });
                              if (form.values.replacementId !== offer.id) {
                                form.setFieldValue("replacementId", offer.id);
                              } else {
                                form.setFieldValue("replacementId", "");
                              }
                            }}
                          />
                        </SnapItem3>
                      );
                    },
                  )}
            </Snap>
          )}
          {(replacementType === "replacement" &&
            fetchStatus === "idle" &&
            relatedOffersCount === 0) ||
          (isAllowedToRequestForReplacementsItem &&
            relatedOffersCount === 0) ? (
            <Text className="text-center py-20">
              Brak podobnych produktów w tej kategorii
            </Text>
          ) : null}
          <Button
            type="submit"
            radius="xl"
            size="md"
            fullWidth
            className="my-4"
          >
            Zapisz
          </Button>
        </form>
      </Stack>
    </IonModal>
  );
};
