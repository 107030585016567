import * as React from "react";

type TimeProps = {
  value: number | Date | string;
  format: "date" | "time" | "datetime";
  as?: React.ElementType;
};

export const Time = ({ value, format = "date", as }: TimeProps) => {
  let options;

  switch (format) {
    case "date":
      options = { dateStyle: "medium" };
      break;
    case "time":
      options = { timeStyle: "short" };
      break;
    case "datetime":
      options = {
        dateStyle: "medium",
        timeStyle: "short",
      };
      break;
  }

  const _val = new Intl.DateTimeFormat("pl-PL", options).format(+value);
  return as ? React.createElement(as, {}, _val) : _val;
};
