import * as React from "react";
import { Stack, TextInput, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";

import { useCart } from "../../../cart/CartContext";
import {
  useAddUserInvoiceAddressMutation,
  useMyAddressesQuery,
} from "../../../graphql/graphql";
import { RouteBackModal } from "../../../../components/RouteBackModal";

export const SetInvoiceAddress = ({
  prevMatch,
  opened,
  open,
  removeNestedOutlet,
}) => {
  const { toggleCartButton } = useCart();
  const { mutateAsync: mutateAsyncSetInvoiceAddress, isLoading } =
    useAddUserInvoiceAddressMutation();

  const routeModalRef = React.useRef<HTMLIonModalElement>(null);

  const { data } = useMyAddressesQuery({
    type: "InvoiceAddress",
  });

  React.useEffect(() => {
    if (data && data.myAddresses.length > 0) {
      const values = data.myAddresses[0].address;
      form.setValues(values);
      form.resetDirty(values);
    }
  }, [data]);

  React.useEffect(() => {
    toggleCartButton();
    return () => {
      toggleCartButton();
    };
  }, []);

  const form = useForm({
    initialValues: {
      taxNumber: "",
      companyName: "",
      street: "",
      streetNumber: "",
      city: "",
      apartmentNumber: "",
      zipNumber: "",
      email: "",
      country: "Polska",
    },
  });

  const handleFormSubmit = async (values) => {
    await mutateAsyncSetInvoiceAddress({
      input: values,
    });
    form.resetDirty();
    routeModalRef.current.close();
  };

  return (
    <RouteBackModal
      ref={routeModalRef}
      clonseButton
      prevMatch={prevMatch}
      title="Adres do faktury"
      opened={opened}
      open={open}
      removeNestedOutlet={removeNestedOutlet}
      contentClassName="ion-padding"
    >
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack>
          <TextInput
            required
            label="NIP"
            {...form.getInputProps("taxNumber")}
          />
          <TextInput
            required
            label="Nazwa firmy"
            {...form.getInputProps("companyName")}
          />
          <TextInput label="Ulica" {...form.getInputProps("street")} />
          <Group grow wrap="nowrap">
            <TextInput
              label="Numer budynku"
              required
              placeholder="Np. 12"
              {...form.getInputProps("streetNumber")}
            />
            <TextInput
              label="Numer mieszkania"
              placeholder="Np. 2"
              {...form.getInputProps("apartmentNumber")}
            />
          </Group>
          <Group grow wrap="nowrap">
            <TextInput
              required
              label="Kod pocztowy"
              placeholder="Np. 12"
              {...form.getInputProps("zipNumber")}
            />
            <TextInput
              required
              label="Miasto"
              placeholder="Np. 2"
              {...form.getInputProps("city")}
            />
          </Group>
          <TextInput
            required
            label="E-mail do rachunku"
            {...form.getInputProps("email")}
          />
        </Stack>
        <Button
          type="submit"
          fullWidth
          mt={20}
          disabled={!form.isDirty()}
          loading={isLoading}
        >
          Zapisz
        </Button>
      </form>
    </RouteBackModal>
  );
};
