import * as React from "react";
import { createSafeContext } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { useReelsContext } from "./ReelsContext";

interface StoriesContextValue {
  state: {
    currentStoryIndex: number;
  };
  setCurrentStoryIndex: (index: number) => void;
}

const [StoriesProvider, useStoriesContext] =
  createSafeContext<StoriesContextValue>(
    "StoriesProvider component was not found in tree",
  );

export const Stories2 = ({ initialStoryIndex, reel, children }) => {
  const reelCtx = useReelsContext();

  reelCtx.reelStory.addReel(reel);
  const [state, setState] = useSetState({
    currentStoryIndex: initialStoryIndex,
  });
  const value = React.useMemo(
    () => ({
      state,
      setCurrentStoryIndex: (index: number) => {
        setState({ currentStoryIndex: index });
      },
    }),
    [state],
  );

  return <StoriesProvider value={value}>{children}</StoriesProvider>;
};

export { useStoriesContext };
