import { LiveUpdate } from "@capawesome/capacitor-live-update";

const state = {
  isLiveUpdateLoading: false,
  lastLiveUpdateVersion: null,
  updateReady: false,
};

let listeners = [];

export async function reload() {
  await LiveUpdate.reload();

  await LiveUpdate.ready();

  liveUpdateStore.setLiveUpdateReady(true);
}

export const initializeLiveUpdate = async () => {
  const result = await LiveUpdate.sync();
  if (result.nextBundleId) {
    await LiveUpdate.reload();

    await LiveUpdate.ready();
  }
};

export const liveUpdateStore = {
  setLiveUpdateReady(isReady: boolean) {
    state.updateReady = isReady;
    emitChange();
  },
  setLoading(isLoading: boolean) {
    state.isLiveUpdateLoading = isLoading;
    emitChange();
  },
  setLiveUpdateVersion(lastVersion: string) {
    state.lastLiveUpdateVersion = lastVersion;
    emitChange();
  },
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return state;
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
