import { Avatar, Group, Stack, Text } from "@mantine/core";
import { cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AutoAlignImage } from "../../../components/AutoAlignImage";
import { Snap } from "../../../components/Snap";
import { SnapItem2 } from "../../../components/SnapItem";
import { useListOffersByCategoryQuery } from "../../graphql/graphql";

const cropImageToAvatar = cropImageTo(100);

export const OffersFromCategory = ({ categoryId, locationId }) => {
  const { data } = useListOffersByCategoryQuery({
    input: {
      categoryId,
      locationId,
    },
  });

  return (
    <Group wrap="nowrap">
      <Snap className="space-x-3">
        {data?.listOffersByCategory.map((offer) => (
          <SnapItem2 key={offer.id} className="px-2">
            <Stack key={offer.id} align="center" gap={1} className="w-[80px]">
              <Avatar size="lg">
                <AutoAlignImage
                  src={cropImageToAvatar(offer.product.images?.[0]?.url)}
                  alt="product"
                />
              </Avatar>
              <Text size="xs" className="line-clamp-2">
                {offer.product.name}
              </Text>
            </Stack>
          </SnapItem2>
        ))}
      </Snap>
    </Group>
  );
};
