import { IonTitle } from "@ionic/react";
import cn from "classnames";
import * as React from "react";

import css from "./CondenseTitle.module.css";

type CondenseTitleProps = {
  children: React.ReactNode;
};

export const CondenseTitle = ({ children }: CondenseTitleProps) => {
  return (
    <>
      <IonTitle size="large" className={cn(css.root)}>
        {children}
      </IonTitle>
    </>
  );
};
