import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IonContent, IonModal } from "@ionic/react";
import { Button, PinInput, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useInterval } from "@mantine/hooks";
import { IconPhone } from "@tabler/icons-react";
import * as React from "react";
import InputMask from "react-input-mask";

import {
  useSendVerificationCodeMutation,
  useSetPhoneNumberMutation,
} from "../../graphql/graphql";
import { useUser } from "../../session/useUser";

const DynamicModalContext = React.createContext(null);

export const useDynamicModalContext = () => {
  const context = React.useContext(DynamicModalContext);
  if (!context) {
    throw new Error(
      "useDynamicModalContext must be used within a DynamicModalProvider",
    );
  }
  return context;
};

export const useDynamicModal = (Children, modalProps = {}) => {
  const context = React.useContext(DynamicModalContext);

  const present = async () => {
    context.setModalChildren(() => () => Children);
    context.setModalProps(modalProps);
    context.setIsOpened(true);
  };

  if (!context) {
    throw new Error(
      "useDynamicModal must be used within a DynamicModalProvider",
    );
  }
  return {
    ...context,
    present,
  };
};

export const DynamicModalProvider = ({ children }) => {
  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const [isOpened, setIsOpened] = React.useState(false);

  const [ModalChildren, setModalChildren] = React.useState(null);
  const [modalProps, setModalProps] = React.useState({});

  return (
    <DynamicModalContext.Provider
      value={{ modalRef, setModalChildren, setModalProps, setIsOpened }}
    >
      {children}
      {ModalChildren ? (
        <IonModal
          ref={modalRef}
          {...modalProps}
          isOpen={isOpened}
          onIonModalDidDismiss={() => setIsOpened(false)}
        >
          <ModalChildren />
        </IonModal>
      ) : null}
    </DynamicModalContext.Provider>
  );
};

export const SetUpPhoneNumberModal = () => {
  const { present } = useDynamicModal(
    React.useMemo(
      () => (
        <Stack className="p-4">
          <Stack align="center" className="mt-8" gap={0}>
            <IconPhone size="65" strokeWidth={1} />
            <Title order={3}>Numer telefonu</Title>
            <Text className="text-center" c="gray">
              Weryfikacja numeru telefonu jest wymagana do złożenia zamówienia
            </Text>
          </Stack>
          <SendVerificarionCodeForm />
        </Stack>
      ),
      [],
    ),
    {
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      style: { "--height": "auto" },
    },
  );
  return (
    <Button
      variant="subtle"
      onClick={async () => {
        await Haptics.impact({
          style: ImpactStyle.Light,
        });

        await present();
      }}
    >
      Dodaj
    </Button>
  );
};

const SendVerificarionCodeForm = () => {
  const [user, { refetch }] = useUser();
  const { modalRef } = useDynamicModalContext();
  const [
    isVerificationCodeBlockedDueAlreadySent,
    {
      open: blockVerificationCodeDueAlreadySent,
      close: unblockVerificationCodeDueAlreadySent,
    },
  ] = useDisclosure();

  const {
    mutateAsync: sendVerificationCodeMutation,
    isLoading: isLoadingSendVerificationCode,
    isSuccess: isSuccessSendVerificationCode,
  } = useSendVerificationCodeMutation();
  const [
    isVerificationCodeIntervalVisible,
    {
      close: removeVerificationCodeInterval,
      open: showVerificationCodeInterval,
    },
  ] = useDisclosure(isSuccessSendVerificationCode);

  const { mutateAsync: setPhoneNumber, isLoading: isLoadingSetPhoneNumber } =
    useSetPhoneNumberMutation();

  const form = useForm({
    initialValues: {
      phoneNumber: user?.phoneNumber || "",
      verificationCode: "",
    },
    transformValues: (values) => ({
      phoneNumber: values.phoneNumber.replace(/\b /g, ""),
      verificationCode: values.verificationCode,
    }),
    validate: {
      phoneNumber: (_value, _values, fieldName) => {
        const transformedField = form.getTransformedValues()[fieldName];
        return transformedField && !/^\+48[0-9]{9}$/.test(transformedField)
          ? "Nieprawidłowy numer telefonu"
          : null;
      },
    },
  });

  const isSendVerificationCodeDisabled =
    isVerificationCodeBlockedDueAlreadySent ||
    !form.isDirty() ||
    !form.isValid();

  const isVerificationCodeVisible = isSuccessSendVerificationCode;

  const isVerificationCodeInputVisible =
    isVerificationCodeIntervalVisible && isVerificationCodeVisible;

  return (
    <form
      className="mt-2"
      onSubmit={form.onSubmit(async (values) => {
        if (values.verificationCode) {
          await setPhoneNumber({
            input: {
              phoneNumber: values.phoneNumber,
              verificationCode: values.verificationCode,
            },
          });
          modalRef.current.dismiss();
        } else {
          blockVerificationCodeDueAlreadySent();
          showVerificationCodeInterval();
          await sendVerificationCodeMutation({
            phoneNumber: values.phoneNumber,
          });
        }
        refetch();
      })}
    >
      <InputMask
        mask="+48 999 999 999"
        maskChar=""
        {...form.getInputProps("phoneNumber")}
      >
        {(inputProps) => (
          <TextInput
            {...inputProps}
            width="50%"
            leftSection={<IconPhone />}
            placeholder="Twój numer telefonu"
          />
        )}
      </InputMask>
      <Button
        type="submit"
        fullWidth
        loading={isLoadingSendVerificationCode}
        disabled={isSendVerificationCodeDisabled}
        radius="lg"
        style={{
          fontWeight: 400,
        }}
        className="my-4"
      >
        Wyślij kod weryfikacyjny
      </Button>
      {isVerificationCodeVisible ? (
        <>
          {isVerificationCodeInputVisible ? (
            <VerificationCodeSendInterval
              onFinish={() => {
                unblockVerificationCodeDueAlreadySent();
                removeVerificationCodeInterval();
              }}
            />
          ) : null}
          <PinInput
            className="my-2"
            styles={{
              root: {
                "--group-justify": "center",
              },
            }}
            oneTimeCode
            length={4}
            {...form.getInputProps("verificationCode")}
          />
          <Text className="text-center" c="gray" size="sm">
            Kod weryfikacyjny
          </Text>
          <Button
            type="submit"
            fullWidth
            radius="lg"
            loading={isLoadingSetPhoneNumber}
            style={{
              fontWeight: 400,
            }}
            className="my-4"
          >
            Potwierdź numer telefonu
          </Button>
        </>
      ) : null}
    </form>
  );
};

const VerificationCodeSendInterval = ({ onFinish }) => {
  const [seconds, setSeconds] = React.useState(60);
  const interval = useInterval(() => setSeconds((s) => s - 1), 1000);

  React.useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  React.useEffect(() => {
    if (seconds <= 0) {
      onFinish();
      interval.stop();
    }
  }, [seconds]);

  return (
    <Text c="gray" className="text-center">
      Ponowne wysłanie kodu możliwe będzie za {seconds} sekund
    </Text>
  );
};
