import { StringParam, useQueryParam, withDefault } from "use-query-params";

export const useRedirectQuerySearch = () => {
  const [redirectUri, setValue] = useQueryParam(
    "redirect",
    withDefault(StringParam, ""),
  );

  return {
    redirectUri,
    updateQuery: setValue,
  };
};
