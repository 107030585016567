import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonToolbar,
  type RefresherEventDetail,
  createAnimation,
  useIonRouter,
} from "@ionic/react";
import { Chip, Segment, SegmentButton } from "@zozia/ui";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";

import {
  useBrandsFromCategoryQuery,
  useCategoriesFromParentQuery,
  useCategoriesFromProductsQuery,
  useOffertsFromCategoryQuery,
} from "../../graphql/graphql";
import { BrandSelect } from "../components/BrandSelect";
import { OptimizedOfferCard } from "../components/ProductCardList/ProductCardList";

export const CategorySearchPage = () => {
  const router = useIonRouter();
  const { params, url } = useRouteMatch<{
    locationId: string;
    categoryId: string;
  }>();

  const [selectedBrands, setSelectedBrands] = React.useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<string>();

  const { data: categoriesFromProductsResult } = useCategoriesFromProductsQuery(
    {
      locationId: params.locationId,
    },
  );

  const { data: categoriesFromParentResult } = useCategoriesFromParentQuery({
    input: {
      parentCategoryId: params.categoryId,
      locationId: params.locationId,
    },
  });

  const {
    data: productsFromCategoryQueryResult,
    isLoading,
    refetch: refetchProducts,
  } = useOffertsFromCategoryQuery({
    input: {
      filters: [
        {
          name: "brands",
          value: JSON.stringify(selectedBrands),
        },
      ],
      locationId: params.locationId,
      parentCategoryId: params.categoryId,
      categoryId: selectedCategory,
    },
  });

  return (
    <IonPage>
      <IonContent id="locationCategorySearchPage" fullscreen>
        <IonRefresher
          slot="fixed"
          onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
            await refetchProducts();
            event.detail.complete();
          }}
        >
          <IonRefresherContent
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        <div style={{ marginTop: "var(--ion-safe-area-top)" }}>
          <Segment value={params.categoryId}>
            {categoriesFromProductsResult?.categoriesFromProducts.map(
              (category) => {
                const urlToReplace = url.split("/").slice(0, -1).join("/");
                return (
                  <SegmentButton
                    key={category.id}
                    value={category.id}
                    onClick={() => {
                      router.push(
                        `${urlToReplace}/${category.id}`,
                        "none",
                        "replace",
                        {},
                        createAnimation,
                      );
                    }}
                  >
                    {category.name}
                  </SegmentButton>
                );
              },
            )}
          </Segment>
          <hr />
          <ul
            className="scrollbar-hide m-2 flex space-x-4 overflow-x-auto p-0"
            style={{ listStyleType: "none" }}
          >
            <BrandSelect
              text="Marki"
              onSubmit={(brands) => {
                setSelectedBrands(brands);
              }}
              onReset={() => {
                setSelectedBrands([]);
              }}
              ModalContent={({ onToggle, items }) => {
                const { data: brandsFromCategoryResult } =
                  useBrandsFromCategoryQuery({
                    input: {
                      locationId: params.locationId,
                      categoryId: params.categoryId,
                    },
                  });

                return (
                  <>
                    <IonHeader>
                      <IonToolbar className="font-bold">
                        Marki {items.length > 0 ? `(${items.length})` : null}
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <IonList>
                        {brandsFromCategoryResult?.brandsFromCategory.map(
                          (brand) => (
                            <IonItem key={brand.id}>
                              <IonCheckbox
                                slot="start"
                                checked={items.includes(brand.id)}
                                onIonChange={() => onToggle(brand.id)}
                              />
                              <IonLabel>
                                <h2>{brand.name}</h2>
                              </IonLabel>
                            </IonItem>
                          ),
                        )}
                      </IonList>
                    </IonContent>
                  </>
                );
              }}
            />
            {categoriesFromParentResult?.categoriesFromParent
              .filter((c) => c.id !== params.categoryId)
              .map((c) => (
                <li className="whitespace-nowrap" key={c.id}>
                  <Chip
                    key={c.id}
                    onClick={() => {
                      if (c.id === selectedCategory) {
                        setSelectedCategory(undefined);
                      } else {
                        setSelectedCategory(c.id);
                      }
                    }}
                    toggled={c.id === selectedCategory}
                  >
                    {c.name}
                  </Chip>
                </li>
              ))}
          </ul>
        </div>
        <span className="flex p-2">
          {productsFromCategoryQueryResult?.productsFromCategory.data.length ? (
            <>
              Znaleziono{" "}
              <PluralText
                strings={{ many: "produktów", one: "produkt", few: "produkty" }}
                value={
                  productsFromCategoryQueryResult?.productsFromCategory.data
                    .length
                }
              />
            </>
          ) : isLoading ? (
            <IonSkeletonText animated style={{ width: "50%" }} />
          ) : (
            "Brak produktów"
          )}
        </span>
        <div className="grid grid-cols-2 gap-2 p-1">
          {productsFromCategoryQueryResult?.productsFromCategory.data.map(
            (product) => (
              <OptimizedOfferCard
                key={product.id}
                offer={product}
                root="locationCategorySearchPage"
              />
            ),
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

type PluralTextProps = {
  strings: {
    many: string;
    one: string;
    few: string;
  };
  value: number;
};

const PluralText = ({ strings, value }: PluralTextProps) => {
  const pr = new Intl.PluralRules("pl-PL");
  return `${value} ${strings[pr.select(value)]}`;
};
