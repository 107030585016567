import { IonAlert } from "@ionic/react";
import { Button } from "@mantine/core";
import * as React from "react";

type RemoveCartButtonProps = {
  onSuccess: () => void;
};

export const RemoveCartButton = ({ onSuccess }: RemoveCartButtonProps) => {
  return (
    <>
      <Button variant="subtle" color="red" id="present-alert">
        Usuń koszyk
      </Button>
      <IonAlert
        header="Usuwanie koszyka"
        subHeader="Czy na pewno chcesz usunąć koszyk?"
        message="Usunięcie koszyka spowoduje usunięcie wszystkich produktów z koszyka i zamknięcie go."
        trigger="present-alert"
        buttons={[
          {
            text: "Nie",
            role: "cancel",
            handler: () => {
              console.log("Alert canceled");
            },
          },
          {
            text: "Tak",
            role: "confirm",
            handler: () => {
              onSuccess();
            },
          },
        ]}
      />
    </>
  );
};
