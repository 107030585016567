import { IonButtons, IonContent, IonHeader, IonToolbar } from "@ionic/react";
import { Avatar, Button, Divider, Group, Stack, Text } from "@mantine/core";
import { IconShoppingCart, IconX } from "@tabler/icons-react";
import { cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AppPage } from "../../../components/AppPage";
import { formatOrdinals } from "../../../lib/translateCartText";
import { useCart } from "../CartContext";
import css from "./LocationCartPage.module.css";

const cropImageToDesiredSize = cropImageTo(0, 38);

const CartLocationAvatar = ({ cart, isActive, ...props }) => {
  const ref = React.useRef();
  const wrapperRef = React.useRef();
  const { updateLocation } = useCart();

  React.useLayoutEffect(() => {
    if (ref.current && wrapperRef.current) {
      ref.current.style.left = wrapperRef.current.offsetLeft + "px";
      ref.current.style.width = wrapperRef.current.offsetWidth + "px";
    }
  }, [ref, wrapperRef, isActive]);

  return (
    <div
      ref={wrapperRef}
      onClick={() => {
        updateLocation(cart.location.id);
      }}
      {...props}
    >
      <Avatar
        src={cropImageToDesiredSize(
          cart.location.images.find((img) => img.size === "logo")?.url,
        )}
      />
      {isActive ? <div className={css.highlight} ref={ref} /> : null}
    </div>
  );
};
export const LocationCartPage = () => {
  const { cart, carts } = useCart();

  const hasMoreThenThreeCarts = carts.length > 2;

  const visibleCarts = hasMoreThenThreeCarts ? carts.slice(0, 2) : carts;

  return (
    <AppPage title={`Koszyk dla sklepu: `}>
      <IonContent className="ion-padding">
        <Group align="center" justify="center">
          <div className="flex-grow ml-[62px]"></div>

          {visibleCarts.map((visibleCart) => {
            return (
              <CartLocationAvatar
                key={visibleCart.id as unknown as string}
                cart={visibleCart}
                isActive={visibleCart.id === cart.id}
              />
            );
          })}
          {/* {hasMoreThenThreeCarts ? (
              <Stack gap={0} align="center">
                <IconShoppingCart size={20} />
                <Text style={{ fontSize: ".5rem" }}>
                  +{formatOrdinals(carts.length - 2)}
                </Text>
              </Stack>
            ) : null} */}
          <div className="flex-grow"></div>

          <Button
            color="black"
            variant="transparent"
            onClick={() => React.forwardRef.current.dismiss()}
          >
            <IconX />
          </Button>
        </Group>

        {cart.items?.map((item, idx) => (
          <div key={item.id}>
            <Group align="start" className="mb-4">
              <Avatar
                src={cropImageToDesiredSize(
                  item.offer.product?.primaryImage?.url || "",
                )}
                radius="xs"
              />
              <Text>{item.offer.product.name}</Text>
            </Group>
            {idx === cart.items.length - 1 ? null : (
              <Divider className="my-4" />
            )}
          </div>
        ))}
        <pre>{JSON.stringify(cart, null, 2)}</pre>
      </IonContent>
    </AppPage>
  );
};
