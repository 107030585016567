import { IonSearchbar, useIonViewDidEnter } from "@ionic/react";
import { useMergedRef } from "@mantine/hooks";
import * as React from "react";

const DELAY_AFTER_ANIMATION = 2500;
const PLACEHOLDERS = [
  "Orzechy nerkowca",
  "Olej lniany",
  "Mokra karma dla psa",
  "Wędki",
  "Kawa ziarnista",
  "Herbata zielona",
  "Sok z marchwi",
];

const getRandomDelayBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const setPlaceholder = (inputNode, placeholder) => {
  inputNode.setAttribute("placeholder", placeholder);
};

const animateLetters = (
  currentLetters,
  remainingLetters,
  inputNode,
  onAnimationEnd,
) => {
  if (!remainingLetters.length) {
    return (
      typeof onAnimationEnd === "function" &&
      onAnimationEnd(currentLetters.join(""), inputNode)
    );
  }

  currentLetters.push(remainingLetters.shift());

  setTimeout(
    () => {
      setPlaceholder(inputNode, currentLetters.join(""));
      animateLetters(
        currentLetters,
        remainingLetters,
        inputNode,
        onAnimationEnd,
      );
    },
    getRandomDelayBetween(40, 90),
  );
};

const animatePlaceholder = (inputNode, placeholder, onAnimationEnd) => {
  animateLetters([], placeholder.split(""), inputNode, onAnimationEnd);
};

const onAnimationEnd = (placeholder, inputNode) => {
  setTimeout(() => {
    let newPlaceholder =
      PLACEHOLDERS[Math.floor(Math.random() * PLACEHOLDERS.length)];

    do {
      newPlaceholder =
        PLACEHOLDERS[Math.floor(Math.random() * PLACEHOLDERS.length)];
    } while (placeholder === newPlaceholder);

    animatePlaceholder(inputNode, newPlaceholder, onAnimationEnd);
  }, DELAY_AFTER_ANIMATION);
};

type SearchbarProps = {
  onClick?: () => void;
  onChange?: (text: string) => void;
  onClear?: () => void;
  autofocus?: boolean;
  value: string;
};

export const Searchbar = React.forwardRef<HTMLInputElement, SearchbarProps>(
  (
    {
      value,
      onClick = () => {},
      onChange = () => {},
      onClear = () => {},
      autofocus = false,
    },
    ref,
  ) => {
    const innerRef = React.useRef(null);

    const mergedRef = useMergedRef(innerRef, ref);

    React.useEffect(() => {
      animatePlaceholder(innerRef.current, PLACEHOLDERS[0], onAnimationEnd);
    }, [ref]);

    useIonViewDidEnter(() => {
      if (autofocus) {
        innerRef.current.setFocus();
      }
    }, [autofocus]);

    const handleSearch = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      if (target) {
        onChange(target.value!.toLowerCase());
      }
    };

    return (
      <form
        action=""
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <IonSearchbar
          value={value}
          onClick={onClick}
          ref={mergedRef}
          onIonInput={handleSearch}
          onIonClear={onClear}
        />
      </form>
    );
  },
);
