import cn from "classnames";
import * as React from "react";

type SnapItemSize = "full" | "default" | "half";

type SnapItemProps = {
  children: React.ReactNode;
  className?: string;
  width?: SnapItemSize;
  height?: "default" | "inherit";
};

export const SnapItem = ({
  children,
  width = "default",
  height = "default",
  ...props
}: SnapItemProps) => {
  const classNames = cn(
    "mx-2 shrink-0 snap-center flex-col lg:max-h-40 lg:w-80",
    {
      "w-full": width === "full",
      "w-4/12": width === "half",
      "w-10/12": width === "default",
      "h-44": height === "default",
    },
  );
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export const SnapItem3 = ({ children, ...props }: SnapItemProps) => {
  const classNames = cn(
    "shrink-0 snap-start flex-col lg:max-h-40 w-4/12 scroll-ml-6",
  );
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export const SnapItem2 = ({ children, className }: SnapItemProps) => {
  const classNames = cn("shrink-0 snap-center flex-col", className);
  return <div className={classNames}>{children}</div>;
};
