import * as React from "react";
import cn from "classnames";

type ChipProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  toggled?: boolean;
  onClick?: () => void;
};

export const Chip = (props: ChipProps) => {
  const classNames = cn("flex items-center space-x-2 rounded-full px-3 py-1", {
    "bg-gray-200": !props.toggled,
    "bg-black text-white": props.toggled,
  });

  return (
    <div
      className="inline-flex"
      onClick={() => {
        props.onClick?.();
      }}
    >
      <span className={classNames}>
        <span>{props.children}</span>
        {props.icon ? <span className="flex">{props.icon}</span> : null}
      </span>
    </div>
  );
};
