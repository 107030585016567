import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  RouteManagerContext,
  useIonRouter,
} from "@ionic/react";
import { Avatar, Button, Divider, Group, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AutoAlignImage } from "../../../components/AutoAlignImage";
import { useCart } from "../CartContext";

const cropImageToDesiredSize = cropImageTo(38);
const cropImageToLocationLogo = cropImageTo(38);

type ActiveCartsModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
};

export const ActiveCartsModal = ({ forwardRef }: ActiveCartsModalProps) => {
  const router = useIonRouter();
  const { carts, dispatch } = useCart();

  const { routeInfo } = useIonRouter();
  const { findViewItemByRouteInfo } = React.useContext(RouteManagerContext);
  const { ionPageElement: presentingElement } =
    findViewItemByRouteInfo(routeInfo);

  return (
    <IonModal ref={forwardRef} presentingElement={presentingElement}>
      <IonHeader slot="start">
        <IonToolbar>
          <IonTitle className="px-0 text-start">Twoje koszyki</IonTitle>
          <IonButtons slot="end">
            <Button
              color="black"
              variant="transparent"
              onClick={() => forwardRef.current.dismiss()}
            >
              <IconX />
            </Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {carts.map((cart, idx) => (
          <div key={cart.id}>
            <Group align="start" className="mb-4">
              <Avatar>
                <AutoAlignImage
                  src={
                    cart.location.images.find((img) => img.size === "logo")?.url
                      ? cropImageToLocationLogo(
                          cart.location.images.find(
                            (img) => img.size === "logo",
                          )?.url,
                        )
                      : `https://picsum.photos/38/38?random=${Math.random()}`
                  }
                  style={{ width: 38, height: 38 }}
                />
              </Avatar>
              <Text>{cart.location.name}</Text>
            </Group>
            <Group gap={2}>
              {cart.items.slice(0, 8).map((item) => (
                <Avatar radius="xs" key={item.id} color="#FFF">
                  <AutoAlignImage
                    src={
                      item.offer.product?.primaryImage?.url
                        ? cropImageToDesiredSize(
                            item.offer.product?.primaryImage?.url,
                          )
                        : `https://picsum.photos/38/38?random=${Math.random()}`
                    }
                    style={{ width: 38, height: 38 }}
                  />
                </Avatar>
              ))}
              {cart.items.length > 8 && (
                <Avatar radius="xs" color="gray">
                  +{cart.items.length - 8}
                </Avatar>
              )}
            </Group>
            <Button
              onClick={async () => {
                await forwardRef.current.dismiss();
                dispatch({
                  type: "SET_CART_AS_ACTIVE",
                  payload: cart.id,
                });
                router.push(`/tabs/discovery/locations/${cart.location.id}`);
              }}
              radius="xl"
              size="md"
              fullWidth
              className="my-4"
            >
              Kontynuuj zakupy
            </Button>
            {idx === carts.length - 1 ? null : <Divider className="my-4" />}
          </div>
        ))}
      </IonContent>
    </IonModal>
  );
};
