import { IonModal, IonNav } from "@ionic/react";
import * as React from "react";

import { MapPage } from "./MapPage";

type DeliveryAddressModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
};

export const DeliveryAddressModal = ({
  forwardRef,
}: DeliveryAddressModalProps) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false);

  return (
    <IonModal
      ref={forwardRef}
      initialBreakpoint={1}
      onIonModalDidPresent={() => setIsModalOpened(true)}
      onIonModalWillDismiss={() => setIsModalOpened(false)}
      breakpoints={[0, 1]}
      style={{ "--border-radius": "20px" }}
    >
      <IonNav
        root={() => (
          <MapPage forwardRef={forwardRef} isModalOpened={isModalOpened} />
        )}
      />
    </IonModal>
  );
};
