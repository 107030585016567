import { useIonRouter } from "@ionic/react";
import { Card, Center, Skeleton, Stack, Text } from "@mantine/core";
import { noop } from "@zozia/fp";
import * as React from "react";

import { DiscoveryLocationQuery } from "../../../graphql/graphql";
import { DiscoveryLocationCardLogoImage } from "./DiscoveryLocationCardLogoImage";

type DiscoveryLocationCardProps = {
  loading?: boolean;
  location?: DiscoveryLocationQuery["discoveryLocation"]["categories"][0]["locations"][0];
};

export const DiscoveryLocationCard = ({
  loading = false,
  location,
}: DiscoveryLocationCardProps) => {
  const router = useIonRouter();

  const navigateToLocation = () => {
    router.push(`/tabs/discovery/locations/${location.id}`);
  };

  const logoImage = location?.images?.find((i) => i.size === "logo");

  return (
    <Stack
      className="relative block h-[280px] w-[190px]"
      onClick={loading ? noop : navigateToLocation}
    >
      <Stack className=" block">
        <Center className="relative z-20">
          <Card className="relative h-[148px] p-0" radius="xl">
            {loading ? (
              <Skeleton height={148} width={168} />
            ) : (
              <DiscoveryLocationCardLogoImage
                src={logoImage?.url}
                blurhash={logoImage?.blurhash}
                alt={location.name}
              />
            )}
          </Card>
        </Center>
        <Card
          shadow="md"
          radius="md"
          withBorder
          className="absolute left-0 top-12 h-[200px] w-[190px]"
        />
        <Stack justify="space-between" className="relative px-5 h-[90px]">
          <div className="line-clamp-2 pt-1 font-bold">
            {loading ? <Skeleton width={100} height={25} /> : location.name}
          </div>
          {loading ? (
            <Skeleton width={50} height={15} />
          ) : (
            <Text c="gray" className="pt-1 text-sm font-light">
              {location.distance
                ? location.distance < 1000
                  ? Math.round(location.distance) + " m"
                  : `${(Math.round(location.distance) / 1000).toFixed(2)} km`
                : null}
            </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
