import * as React from "react";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { Section } from "../components/Section";

export const NotificationsPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Powiadomienia</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Section
          title="Znizki i specjalne oferty"
          description="Nie przegap zadnej okacji w Zozia"
          className="mx-4"
          inset
        >
          <IonList>
            <IonItem>
              <IonToggle>Powiadomienia push</IonToggle>
            </IonItem>
            <IonItem>
              <IonToggle>Powiadomienia mailowe</IonToggle>
            </IonItem>
            <IonItem>
              <IonToggle>Powiadomienia SMS/MMS</IonToggle>
            </IonItem>
            <IonItem>
              <IonToggle>Kominikaty o produktach alkoholowych</IonToggle>
            </IonItem>
          </IonList>
        </Section>
      </IonContent>
    </IonPage>
  );
};
