import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonThumbnail,
  useIonViewWillEnter,
} from "@ionic/react";
import { Flex, Skeleton, Text, Title } from "@mantine/core";
import { isLocationOpen } from "@zozia/opening-hours";
import { range } from "@zozia/std";
import { cropImageTo } from "@zozia/ui";
import { bicycleOutline, timeOutline } from "ionicons/icons";
import * as React from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { AppPage } from "../../../../components/AppPage";
import { Snap } from "../../../../components/Snap";
import { SnapItem2, SnapItem3 } from "../../../../components/SnapItem";
import { useCart } from "../../../cart/CartContext";
import { StoryCircle } from "../../../stories/components/StoryCircle";
import { ProductCardList } from "../../components/ProductCardList";
import { useLocationData } from "../../hooks/useLocationData";
import { LocationCoverImage } from "./LocationCoverImage";

const cropImageToLogoSize = cropImageTo(51);

export const LocationDetailPage = () => {
  const { locationId } = useParams<{ locationId: string }>();

  const match = useRouteMatch();
  const {
    location,
    offers,
    isLoadingLocation,
    isLoadingProducts,
    refreshLocationPage,
    categories,
    isCategoriesLoading,
  } = useLocationData();
  const { updateLocation } = useCart();
  const ref = React.useRef(null);

  useIonViewWillEnter(() => {
    updateLocation(locationId);
  }, [locationId]);

  return (
    <AppPage title={location && `Lokalizacja ${location.name}`}>
      <IonContent fullscreen ref={ref}>
        <IonRefresher slot="fixed" onIonRefresh={refreshLocationPage}>
          <IonRefresherContent
            pullingText="Ściągnij aby odświeżyć"
            refreshingSpinner="circles"
            refreshingText="Odświeżam..."
          ></IonRefresherContent>
        </IonRefresher>
        {isLoadingLocation ? (
          <>
            <Skeleton
              height={200}
              style={{
                margin: 0,
              }}
            />
            <div className="relative h-[36px]">
              <div className="relative -top-[31px] mx-auto w-[62px] cursor-pointer">
                <StoryCircle>
                  <IonThumbnail slot="start" className="h-14 w-14">
                    <IonSkeletonText className="rounded-full" animated />
                  </IonThumbnail>
                </StoryCircle>
              </div>
            </div>
            <IonList lines="none">
              <IonItem className="h-[44.8px]">
                <IonIcon slot="start" icon={timeOutline} />
                <IonLabel>
                  <IonSkeletonText
                    style={{
                      width: "60%",
                      height: "15px",
                    }}
                  />
                </IonLabel>
              </IonItem>
              <IonItem className="h-[44.8px]">
                <IonIcon slot="start" icon={bicycleOutline} />
                <IonLabel>
                  <IonSkeletonText
                    style={{
                      width: "60%",
                      height: "15px",
                    }}
                  />
                </IonLabel>
              </IonItem>
            </IonList>
          </>
        ) : (
          <>
            <div className="h-[200px] w-full overflow-hidden">
              <LocationCoverImage
                image={{
                  src: location?.images.find((img) => img.size === "cover")
                    ?.url,
                }}
              />
            </div>
            <div className="relative h-[36px]">
              <div className="relative -top-[31px] mx-auto w-[62px] cursor-pointer">
                <StoryCircle
                  image={cropImageToLogoSize(
                    location?.images.find((i) => i.size === "logo")?.url ||
                      `https://picsum.photos/51/51?random=${location?.id}`,
                  )}
                />
              </div>
            </div>
            <IonList lines="none">
              {location?.openingHours ? (
                <IonItem>
                  <IonIcon slot="start" icon={timeOutline} />
                  <IonLabel>
                    {isLocationOpen(location?.openingHours.value)}
                  </IonLabel>
                </IonItem>
              ) : null}
              {location?.flags.isActiveSale ? (
                <IonItem>
                  <IonIcon slot="start" icon={bicycleOutline} />
                  <IonLabel>Dostawy</IonLabel>
                </IonItem>
              ) : null}
            </IonList>
          </>
        )}
        <Categories
          match={match}
          categories={categories}
          isCategoriesLoading={isCategoriesLoading}
        />
        {isLoadingProducts ? (
          range(5).map((i) => (
            <React.Fragment key={i}>
              <Title className="px-4 pb-2 font-extrabold">
                <IonSkeletonText
                  style={{
                    width: "70%",
                    height: 26,
                    margin: 0,
                  }}
                />
              </Title>
              <Snap className="mx-1 gap-1">
                {range(4).map((i) => (
                  <SnapItem3 key={i} width="half">
                    <div className="h-[120px]">
                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div className="py-1 px-3">
                      <div>
                        <IonSkeletonText className="line-clamp-2 min-h-[30px]" />
                        <IonSkeletonText className="h-[20px]" />
                      </div>
                      {/* {location?.flags.isActiveSale ? (
                              <UpdateProductCount product={product} />
                            ) : null} */}
                    </div>
                  </SnapItem3>
                ))}
              </Snap>
            </React.Fragment>
          ))
        ) : (
          <ProductCardList items={offers} match={match} contentRef={ref} />
        )}
      </IonContent>
    </AppPage>
  );
};

const Categories = ({ match, categories, isCategoriesLoading }) => {
  const router = useHistory();

  return isCategoriesLoading ? (
    <Snap className="space-x-3 px-2">
      {range(3).map((idx) => (
        <SnapItem2 key={idx}>
          <IonSkeletonText
            style={{
              width: 180,
              height: 45,
              margin: 0,
            }}
          />
        </SnapItem2>
      ))}
    </Snap>
  ) : (
    <Snap className="space-x-3 px-2">
      {categories.map((c) => {
        return (
          <SnapItem2 key={c.id}>
            <div
              className="relative block h-[45px] w-[180px] rounded-lg border-gray-400"
              style={{ background: "" }}
              onClick={() => {
                router.push(`${match.url}/categories/${c.id}`);
              }}
            >
              <Flex direction="column" align="center" gap={5}>
                {/* {c.meta?.icon ? (
                  <DynamicIcon
                    icon={c.meta.icon}
                    width={75}
                    className="-inset-[5.5px]"
                  />
                ) : null} */}
                <Text
                  className="text-center font-bold uppercase line-clamp-2"
                  c="black"
                >
                  {c.name}
                </Text>
              </Flex>
            </div>
          </SnapItem2>
        );
      })}
    </Snap>
  );
};

const DynamicIcon = React.memo(
  ({ icon, ...props }: { icon: string; width: number; className?: string }) => {
    const [Icon, setIcon] = React.useState(null);

    React.useEffect(() => {
      const init = async () => {
        const Icon = await import(`@zozia/icons/${icon}.js`).then((module) => {
          return module[icon];
        });
        setIcon(() => <Icon {...props} />);
      };

      init();
    }, []);

    return Icon ? Icon : null;
  },
  () => true,
);
