import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNavLink,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  Anchor,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useShallowEffect } from "@mantine/hooks";
import { IconArrowLeft, IconInfoCircle, IconUser } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { Price } from "@zozia/ui";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { AnimateChangeInHeight } from "../../../../components/AnimateChangeInHeight";
import { CondenseTitle } from "../../../../components/CondenseTitle/CondenseTitle";
import { List } from "../../../../components/List/List";
import { MobileSafeAnimatePresence } from "../../../../components/MobileSafeAnimatePresence";
import { SummarySection } from "../../../session/components/Section";
import { useUserAddress } from "../../../session/hooks/useUserDeliveryAddress";
import { useUser } from "../../../session/useUser";
import { useCart } from "../../CartContext";
import { ApplePayProcess } from "../../components/ApplePayProcess";
import { LocationDeliveryAddressSelector } from "../../components/LocationDeliveryAddressSelector";
import { LocationDeliveryOptionSelector2 } from "../../components/LocationDeliveryOptionSelector2";
import { LocationDeliveryPackingSelector2 } from "../../components/LocationDeliveryPackingSelector2";
import { LocationPaymentMethodSelector2 } from "../../components/LocationPaymentMethodSelector2";
import { PayProcess } from "../../components/PayProcess";
import { SetUpAdditionalDeliveryInformationModal } from "../../components/SetUpAdditionalDeliveryInformationModal";
import { SetUpPhoneNumberModal } from "../../components/SetUpPhoneNumberModal";
import selfCss from "./ActiveCartCheckoutPage.module.css";
import { ActiveCartPage } from "./ActiveCartPage";
import css from "./ActiveCartPage.module.css";
import { PaymentSuccessfullyModal } from "./PaymentSuccessfullyModal";

type ActiveCartPageProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  modalDeliveryAddressRef: React.RefObject<HTMLIonModalElement>;
};

export const ActiveCartCheckoutPage = ({
  forwardRef,
  modalDeliveryAddressRef,
}: ActiveCartPageProps) => {
  const {
    cart,
    setDeliveryOption,
    setDeliveryAddress,
    setDeliveryPackingOption,
    totalSum,
    setCartGroupPaymentMethod,
    totalDeliverySum,
    totalPackingSum,
    isCartLoading,
    itemsSum,
  } = useCart();
  const { isLoading: isDeliveryAddressLoading } =
    useUserAddress("DeliveryAddress");
  const paymentSuccessfulModalRef = React.useRef<HTMLIonModalElement>(null);
  const [user] = useUser();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [initialErrors, setErrors] = React.useState<Record<string, string>>({});

  const form = useForm({
    initialValues: {
      phoneNumber: null,
      deliveryAddress: null,
      deliveryOption: null,
      deliveryPackingOption: null,
      paymentMethodId: null,
    },
    initialErrors,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.phoneNumber) {
        errors.phoneNumber = "Numer telefonu jest wymagany";
      }

      if (!values.deliveryAddress) {
        errors.deliveryAddress = "Adres dostawy jest wymagany";
      }

      if (!values.deliveryOption) {
        errors.deliveryOption = "Metoda dostawy jest wymagana";
      }

      if (!values.deliveryPackingOption) {
        errors.deliveryPackingOption = "Metoda pakowania jest wymagana";
      }

      if (!values.paymentMethodId) {
        errors.paymentMethodId = "Metoda płatności jest wymagana";
      }

      setErrors(errors);

      return errors;
    },
  });

  useShallowEffect(() => {
    if (user) {
      form.setValues({
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user?.phoneNumber]);

  useShallowEffect(() => {
    form.setValues({
      deliveryAddress: cart?.deliveryAddressCopy ?? null,
    });
  }, [cart?.deliveryAddressCopy]);

  useShallowEffect(() => {
    form.setValues({
      deliveryOption: cart?.deliveryOptionCopy ?? null,
    });
  }, [cart?.deliveryOptionCopy]);

  useShallowEffect(() => {
    form.setValues({
      deliveryPackingOption: cart?.deliveryPackingOptionCopy ?? null,
    });
  }, [cart?.deliveryPackingOptionCopy]);

  useShallowEffect(() => {
    form.setValues({
      paymentMethodId: cart?.paymentMethodId ?? null,
    });
  }, [cart?.paymentMethodId]);

  return cart ? (
    <>
      <IonHeader>
        <IonToolbar
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <IonNavLink
            routerDirection="back"
            component={() => <ActiveCartPage forwardRef={forwardRef} />}
          >
            <IonButtons slot="start">
              <Button size="compact-xs" color="black" variant="transparent">
                <IconArrowLeft />
              </Button>
            </IonButtons>
          </IonNavLink>
          <IonTitle>Twoje zamówienie</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={css.content}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <CondenseTitle>Twoje zamówienie</CondenseTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding">
          <form>
            <List>
              {isDeliveryAddressLoading ? (
                <IonSpinner />
              ) : (
                <>
                  <IonItem lines="none">
                    <div slot="start" className="self-start mt-[10px]">
                      <IconUser />
                    </div>
                    <IonLabel>
                      {[user?.givenName, user?.familyName]
                        .filter(Boolean)
                        .join(" ")}
                      <Text>
                        {user?.phoneNumber
                          ? user.phoneNumber
                          : "Brak numeru telefonu"}
                      </Text>
                      {form.errors.phoneNumber ? (
                        <Text size="xs" c="red" className="shrink-0">
                          {form.errors.phoneNumber}
                        </Text>
                      ) : null}
                    </IonLabel>
                    <div className="self-start mt-[10px]">
                      {user?.phoneNumber ? null : <SetUpPhoneNumberModal />}
                    </div>
                  </IonItem>
                  <MobileSafeAnimatePresence>
                    <AnimateChangeInHeight>
                      <LocationDeliveryAddressSelector
                        addNewAddressModalRef={modalDeliveryAddressRef}
                        error={form.errors.deliveryAddress}
                        locationId={cart.location.id}
                        value={cart.deliveryAddressCopy}
                        onChange={({ deliveryAddressId }) => {
                          setDeliveryAddress({
                            cartId: cart.id as unknown as string,
                            deliveryAddressId,
                          });
                          setDeliveryOption({
                            cartGroupId: cart.id,
                            deliveryOptionId: null,
                          });
                        }}
                      />
                    </AnimateChangeInHeight>
                  </MobileSafeAnimatePresence>
                  <MobileSafeAnimatePresence>
                    <AnimateChangeInHeight>
                      {cart.deliveryAddressCopy ? (
                        <IonItem lines="none">
                          <IconInfoCircle slot="start" />
                          <IonLabel>
                            <h3>Dodatkowe informacje</h3>
                            <p>
                              {
                                cart.deliveryAddressCopy?.address
                                  ?.additionalInformation
                              }
                            </p>
                          </IonLabel>

                          <SetUpAdditionalDeliveryInformationModal
                            deliveryAddress={cart.deliveryAddressCopy}
                          />
                        </IonItem>
                      ) : null}
                    </AnimateChangeInHeight>
                  </MobileSafeAnimatePresence>
                </>
              )}
            </List>
            <Stack gap={8} className="mt-4">
              <MobileSafeAnimatePresence>
                <AnimateChangeInHeight>
                  <LocationDeliveryOptionSelector2
                    error={form.errors.deliveryOption}
                    locationId={cart.location.id}
                    value={cart.deliveryOption?.id}
                    onChange={({ deliveryOptionId }) => {
                      setDeliveryOption({
                        cartGroupId: cart.id,
                        deliveryOptionId,
                      });
                    }}
                  />
                </AnimateChangeInHeight>
              </MobileSafeAnimatePresence>

              <MobileSafeAnimatePresence>
                <AnimateChangeInHeight>
                  <LocationDeliveryPackingSelector2
                    locationId={cart.location.id}
                    error={form.errors.deliveryPackingOption}
                    value={cart.deliveryPackingOption?.id}
                    onChange={({ deliveryPackingOptionId }) => {
                      setDeliveryPackingOption({
                        cartGroupId: cart.id,
                        deliveryPackingOptionId,
                      });
                    }}
                  />
                </AnimateChangeInHeight>
              </MobileSafeAnimatePresence>
              <MobileSafeAnimatePresence>
                <AnimateChangeInHeight>
                  <LocationPaymentMethodSelector2
                    locationId={cart.location.id}
                    error={form.errors.paymentMethodId}
                    value={cart.paymentMethodId}
                    onChange={({ paymentMethodId }) => {
                      setCartGroupPaymentMethod({
                        cartGroupId: cart.id as unknown as string,
                        paymentMethodId,
                      });
                    }}
                  />
                </AnimateChangeInHeight>
              </MobileSafeAnimatePresence>
            </Stack>
          </form>
        </div>
        <div className="mt-4" />
        <SummarySection
          title="Podsumowanie"
          classNames={{
            section: "px-4",
          }}
        >
          <IonList className={selfCss.summary} lines="none">
            <IonItem lines="none">
              <IonLabel>Koszt dostawy</IonLabel>
              <Price value={totalDeliverySum.toNumber()} />
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Koszt pakowania</IonLabel>
              <Price value={totalPackingSum.toNumber()} />
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Wartość produktów</IonLabel>
              <Price value={itemsSum.toNumber()} />
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <Title order={1}>Razem</Title>
              </IonLabel>
              <Price value={totalSum.toNumber()} />
            </IonItem>
          </IonList>
          <Divider className="-mx-4 mt-1" />
          <Text size="xs" className="my-2">
            Klikając{" "}
            {cart.paymentMethod?.type === "ApplePay"
              ? '"Sfinalizuj zakup, uzywając Apple Pay"'
              : '"Zamów i zapłać"'}{" "}
            zgadzasz się na zakup zawartości koszyka i z poprawnością
            wprowadzonych danych. Potwierdzasz też, że jest Ci znana nasza{" "}
            <Anchor href="https://zozia.app/privacy-policy">
              polityka prywatności
            </Anchor>{" "}
            i <Anchor href="https://zozia.app/statute">regulamin</Anchor>.
          </Text>
        </SummarySection>
      </IonContent>
      <IonFooter className="bg-white">
        {isCartLoading ? (
          <>
            <Flex px="md" pb="md" pt="md" justify="center">
              <Loader size="md" />
            </Flex>
          </>
        ) : (
          <>
            {cart.paymentMethod?.type === "ApplePay" ||
            cart.paymentMethod?.type === "GooglePay" ? (
              <Group justify="space-between" className="px-4 pt-2 pb-4">
                <Text size="sm">Do zapłaty</Text>
                <Price as={Text} size="sm" value={totalSum.toNumber()} />
              </Group>
            ) : null}
            {(() => {
              switch (cart.paymentMethod?.type) {
                case "ApplePay":
                  return (
                    <Flex px="md" pb="md" justify="center" direction="column">
                      <ApplePayProcess
                        form={form}
                        onSuccess={async () => {
                          await forwardRef.current?.dismiss();
                        }}
                      />
                    </Flex>
                  );
                default:
                  return (
                    <Flex
                      px="md"
                      pb="md"
                      pt="lg"
                      justify="center"
                      direction="column"
                    >
                      <PayProcess
                        form={form}
                        onSuccess={async () => {
                          await forwardRef.current?.dismiss();
                        }}
                      />
                    </Flex>
                  );
              }
            })()}
          </>
        )}
      </IonFooter>
      <PaymentSuccessfullyModal
        forwardRef={paymentSuccessfulModalRef}
        onModalDidDismiss={async () => {
          await Promise.all([
            forwardRef.current?.dismiss(),
            queryClient.invalidateQueries({
              queryKey: ["MyOrders", null],
            }),
          ]);
          history.push("/tabs/profile");
        }}
      />
    </>
  ) : null;
};
