import * as React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { Price } from "@zozia/ui";

import { useLocationDeliveryOptionsQuery } from "../../graphql/graphql";
import { LoadingOverlay } from "@mantine/core";

export const LocationDeliveryOptionSelector = ({
  value,
  locationId,
  onChange,
}) => {
  const [_value, setValue] = React.useState(value);

  const { data, isLoading } = useLocationDeliveryOptionsQuery(
    {
      locationId,
    },
    {
      select: (data) => data.locationDeliveryOptions || [],
      enabled: !!locationId,
    },
  );

  return (
    <div className="relative">
      <LoadingOverlay visible={isLoading} />
      <IonSelect
        value={_value}
        interface="action-sheet"
        label="Metoda dostawy"
        placeholder="Odbiór osobisty"
        onIonChange={(e) => {
          onChange({
            deliveryOptionId: e.detail.value,
          });
          setValue(e.detail.value);
        }}
      >
        {data?.map((option) => (
          <IonSelectOption key={option.id} value={option.id}>
            {option.description} <Price value={option.deliveryPrice} />
          </IonSelectOption>
        ))}
      </IonSelect>
    </div>
  );
};
