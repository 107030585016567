import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconMapPin, IconX } from "@tabler/icons-react";
import * as React from "react";

import { useUpsertUserDeliveryAddressMutation } from "../../../../graphql/graphql";
import { useCart } from "../../../CartContext";

type AdditionalAddressInformationPageProps = {
  modalMapRef: React.RefObject<HTMLIonModalElement>;
  selectedAddress: any;
  userForcedPosition: any;
  currentLocation: any;
};
export const AdditionalAddressInformationPage = ({
  modalMapRef,
  userForcedPosition,
  selectedAddress,
  currentLocation,
}: AdditionalAddressInformationPageProps) => {
  const { setDeliveryAddress, cart } = useCart();
  const { mutateAsync: upsertUserDeliveryAddressMutateAsync } =
    useUpsertUserDeliveryAddressMutation();
  const form = useForm({
    initialValues: {
      apartmentNumber: "",
      floor: "",
      additionalInformation: "",
      shouldCreateNewAddress: false,
    },
  });

  const handleFormSubmit = async ({ shouldCreateNewAddress, ...values }) => {
    const formValues = prepareValuesForSubmit({
      address: selectedAddress,
      userForcedPosition,
      currentLocation,
    });
    if (shouldCreateNewAddress) {
      const { upsertUserDeliveryAddress } =
        await upsertUserDeliveryAddressMutateAsync({
          input: {
            ...values,
            ...formValues,
          },
        });
      await setDeliveryAddress({
        cartId: cart.id as unknown as string,
        deliveryAddressId: upsertUserDeliveryAddress.data.id,
      });
    } else {
      await setDeliveryAddress({
        cartId: cart.id as unknown as string,
        oneTimeDeliveryAddress: {
          ...values,
          ...formValues,
        },
      });
    }
    modalMapRef.current.dismiss();
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Button
              size="compact-xs"
              color="black"
              variant="transparent"
              onClick={() => modalMapRef.current.dismiss()}
            >
              <IconX />
            </Button>
          </IonButtons>
          <IonTitle>Dodatkowe informacje</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Stack gap={2}>
          <Group align="start">
            <IconMapPin className="mt-1" />
            <Stack gap={1}>
              <Text fw={600} size="lg">
                {selectedAddress?.primary}
              </Text>
              <Text>{selectedAddress?.secondary}</Text>
            </Stack>
          </Group>
        </Stack>
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
          <Stack>
            <Group grow wrap="nowrap">
              <TextInput
                label="Numer mieszkania"
                placeholder="Np. 12"
                {...form.getInputProps("apartmentNumber")}
              />
              <TextInput
                label="Piętro"
                placeholder="Np. 2"
                {...form.getInputProps("floor")}
              />
            </Group>
            <Textarea
              label="Dodatkowe informacje dla dostawcy"
              placeholder="Podaj wazne informacje, np. kod do klatki lub gdzie kurkier ma zostawić Twoje zakupy"
              {...form.getInputProps("additionalInformation")}
            />
            <Checkbox
              label="Zapisz wybraną lokalizację do moich adresów"
              {...form.getInputProps("shouldCreateNewAddress")}
            />
          </Stack>

          <Group wrap="nowrap" className="mt-4">
            <Button type="submit" fullWidth>
              Zapisz
            </Button>
          </Group>
        </form>
      </IonContent>
    </>
  );
};

function prepareValuesForSubmit({
  address,
  userForcedPosition,
  currentLocation,
}) {
  const splitedAddress = address.primary.split(" ");
  const [posibleHouseNumber] = splitedAddress.reverse();
  const street = posibleHouseNumber
    ? address.primary.split(" ").slice(0, splitedAddress.length - 1)
    : splitedAddress;
  const [zipNumber, ...city] = address.secondary.split(" ");
  const formValues = {
    zipNumber,
    country: "Polska",
    street: street.join(" "),
    streetNumber: posibleHouseNumber ?? "",
    city: city.join(" "),
    latitude: userForcedPosition?.lat || currentLocation.latitude,
    longitude: userForcedPosition?.lng || currentLocation.longitude,
  };

  return formValues;
}
