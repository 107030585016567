import { createAnimation } from "@ionic/react";

export const createSwipeAnimation = (side: "left" | "right") => {
  const swipeAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot!;
    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop"))
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper"))
      .keyframes([
        {
          offset: 0,
          transform: `translateX(${side === "left" ? "100%" : "-100%"})`,
        },
        { offset: 1, transform: "translateX(0px)" },
      ]);

    backdropAnimation.fromTo("opacity", 0.01, 0.4);

    return baseAnimation
      .addElement(baseEl)
      .easing("ease-in")
      .duration(300)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  return {
    enterAnimation: swipeAnimation,
    leaveAnimation: (baseEl: HTMLElement) => {
      return swipeAnimation(baseEl).direction("reverse");
    },
  };
};
