import { useIonRouter } from "@ionic/react";
import { Button, Stack } from "@mantine/core";
import cn from "classnames";
import * as React from "react";

import { useCurrentTabViewManager } from "./CurrentTabViewManager";
import css from "./TabButton.module.css";

type TabButtonProps = {
  idleState: {
    icon: React.ReactNode;
    label?: string;
  };
  activeState: {
    icon: React.ReactNode;
    label?: string;
  };
  href?: string;
  routerDirection?: "forward" | "back";
  classNames?: {
    button?: string;
    label?: string;
  };
  id?: string;
  onClick?: () => void;
};

export const TabButton = ({
  idleState,
  activeState,
  href,
  routerDirection = "forward",
  classNames = {},
  onClick,
  ...rest
}: TabButtonProps) => {
  const router = useIonRouter();
  const { navigatingToTab, activeTab } = useCurrentTabViewManager();

  return (
    <Button
      variant="transparent"
      classNames={{
        root: cn(css.button, classNames.button),
        label: cn(classNames.label),
      }}
      fullWidth
      disabled={!!navigatingToTab}
      onClick={() => {
        if (href) {
          router.push(href, routerDirection);
        } else {
          onClick?.();
        }
      }}
      {...rest}
    >
      <Stack gap={1} className="items-center">
        {href?.includes(activeTab) ? activeState.icon : idleState.icon}
        {activeState.label
          ? href?.includes(activeTab)
            ? activeState.label
            : idleState.label
          : null}
      </Stack>
    </Button>
  );
};
