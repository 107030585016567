import cn from "classnames";
import { motion } from "framer-motion";
import * as React from "react";

type AnimateChangeInHeightProps = {
  children: React.ReactNode;
  className?: string;
  order?: number;
};

export const AnimateChangeInHeight = ({
  children,
  className,
  order,
}: AnimateChangeInHeightProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState<number | "auto">("auto");

  React.useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <motion.div
      className={cn(className, "overflow-hidden")}
      style={{ height, opacity: 0 }}
      animate={{ height, opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.1, delay: order * 0.2}}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
};
