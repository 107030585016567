import { IonContent, IonHeader } from "@ionic/react";
import * as React from "react";

import { AppPage } from "../../../../components/AppPage";

type PageLayoutProps = {
  children: React.ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <AppPage title="Wyszukiwarka">
      <IonContent fullscreen>
        <IonHeader
          style={{ paddingTop: "var(--ion-safe-area-top)" }}
          className="mt-2 flex justify-center px-2 items-center"
        >
          <img
            src={require("../../../../../public/apple-touch-icon.png")}
            alt="Zozia"
            width={34}
            height={34}
          />
        </IonHeader>
        {children}
      </IonContent>
    </AppPage>
  );
};
