import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { isPlatform } from "@ionic/react";

export const MobileSafeAnimatePresence = ({ children, ...props }) => {
  const isDevelopment = process.env.NODE_ENV === "development";
  const isHybrid = isPlatform("hybrid");

  if (isDevelopment && isHybrid) {
    return children;
  }

  return <AnimatePresence {...props}>{children}</AnimatePresence>;
};
