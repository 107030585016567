import * as React from "react";

const formatter = new Intl.NumberFormat("pl", {
  style: "currency",
  currency: "PLN",
});

export const Price = ({ value }) => {
  return <>{formatter.format(value)}</>;
};
