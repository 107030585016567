export const cropImageTo =
  (height: number, width: number = 0) =>
  (url: string) => {
    if (!url) return null;
    if (url.includes("images.zozia.app")) return url;
    if (
      !url.includes("zozia.fra1.cdn.digitaloceanspaces.com") &&
      !url.includes("zozia.fra1.digitaloceanspaces.com")
    )
      return url;

    const formattedUrl = new URL(url);

    return new URL(
      `${width}x${height}${formattedUrl.pathname}`,
      "https://images.zozia.app",
    ).toString();
  };
