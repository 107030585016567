import { IonTabBar } from "@ionic/react";
import { Flex } from "@mantine/core";
import {
  IconMapPin,
  IconMapPinFilled,
  IconUser,
  IconUserFilled,
} from "@tabler/icons-react";
import * as React from "react";

import { TabButton } from "./TabButton";
import { OpenCartTabButton } from "./features/cart/modals/OpenCartTabButton";
import { useSession } from "./features/session";

type TabBarProps = {
  slot: "bottom";
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Flex.isTabButton = true;

export const TabBar = React.forwardRef(
  (
    { slot = "bottom" }: TabBarProps,
    ref: React.RefObject<HTMLIonTabBarElement>,
  ) => {
    const { snapshot } = useSession();

    return (
      <IonTabBar
        slot={slot}
        ref={ref}
        translucent
        className="bg-transparent outline outline-[0.5px] outline-black"
      >
        <Flex className="h-full w-full">
          <TabButton
            classNames={{
              button: "h-full",
            }}
            idleState={{
              icon: <IconMapPin />,
              label: "Odkrywaj",
            }}
            activeState={{
              icon: <IconMapPinFilled />,
              label: "Odkrywaj",
            }}
            href="/tabs/discovery"
            routerDirection="back"
          />
          <OpenCartTabButton />
          <TabButton
            classNames={{
              button: "h-full",
            }}
            idleState={{
              icon: <IconUser />,
              label: "Profil",
            }}
            activeState={{
              icon: <IconUserFilled />,
              label: "Profil",
            }}
            href={snapshot ? "/tabs/profile" : "/tabs/login"}
          />
        </Flex>
      </IonTabBar>
    );
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
TabBar.isTabBar = true;
