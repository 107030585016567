import * as React from "react";

export const PageContext = React.createContext({
  data: {},
  setData: () => {},
});

export const PageContextProvider = ({ children }) => {
  const [data, setData] = React.useState({});

  return (
    <PageContext.Provider value={{ data, setData }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => React.useContext(PageContext);
