import { IonContent, IonModal } from "@ionic/react";
import { Title } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import Lottie, { LottieRef } from "lottie-react";
import * as React from "react";

import successfullPayment from "./animation.json";

type PaymentSuccessfullyProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  onModalDidDismiss?: () => void;
};

export const PaymentSuccessfullyModal = ({
  forwardRef,
  onModalDidDismiss,
}: PaymentSuccessfullyProps) => {
  const lottieRef: LottieRef = { current: null };

  const [isOpened, setIsOpened] = React.useState(false);

  React.useEffect(() => {
    if (isOpened) {
      if (lottieRef.current) {
        lottieRef.current.animationItem.addEventListener("loopComplete", () => {
          setIsOpened(false);
          forwardRef.current.dismiss();
        });
      }
    }

    return () => {};
  }, [isOpened, lottieRef]);
  return (
    <IonModal
      onIonModalDidPresent={() => setIsOpened(true)}
      onIonModalDidDismiss={async () => {
        await onModalDidDismiss?.();
      }}
      ref={forwardRef}
      initialBreakpoint={0.7}
      style={{
        "--border-radius": "20px 20px 0 0",
        "--background": "white",
      }}
    >
      <IonContent>
        <Lottie lottieRef={lottieRef} animationData={successfullPayment} />
        <Title className="text-center">Płatność zakończona sukcesem!</Title>
      </IonContent>
    </IonModal>
  );
};
