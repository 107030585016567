import * as React from "react";
import { Card, Title, Text } from "@mantine/core";
import cn from "classnames";

type SectionProps = {
  title: React.ReactNode;
  description?: string;
  className?: string;
  children: React.ReactNode;
  inset?: boolean | "1" | "2" | "3" | "4" | "clear";
};
export const Section = ({
  title,
  description,
  children,
  className,
  inset,
}: SectionProps) => {
  const isInsetNumber = typeof inset === "string";
  return (
    <Card
      className={className}
      styles={{
        root: {
          background: "#f1f1f1",
        },
      }}
    >
      <Card.Section>
        <Title order={3}>{title}</Title>
        {description ? <Text size="sm">{description}</Text> : null}
      </Card.Section>
      <div
        className={
          inset
            ? isInsetNumber
              ? cn({
                  "-mx-1": parseInt(inset, 10) === 1,
                  "-mx-2": parseInt(inset, 10) === 2,
                  "-mx-3": parseInt(inset, 10) === 3,
                  "-mx-4": parseInt(inset, 10) === 4,
                })
              : "-mx-8"
            : null
        }
      >
        {inset === "clear" ? <Card.Section>{children}</Card.Section> : children}
      </div>
    </Card>
  );
};
