import { IonPage, useIonViewDidEnter } from "@ionic/react";
import * as React from "react";

type AppPageProps = {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardRef?: React.Ref<any>;
  title: string;
};
export const AppPage = ({ children, forwardRef, title }: AppPageProps) => {
  useIonViewDidEnter(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return <IonPage ref={forwardRef}>{children}</IonPage>;
};
