import { ActionIcon } from "@mantine/core";
import { IconMinus, IconTrash } from "@tabler/icons-react";
import * as React from "react";

type DecreaseQuantityProps = {
  quantity: number;
  onDecrease: (event: any) => Promise<void>;
};

export const DecreaseQuantity = ({
  quantity,
  onDecrease,
}: DecreaseQuantityProps) => {
  return (
    <ActionIcon size="xs" variant="transparent" c="black" onClick={onDecrease}>
      {quantity > 1 ? <IconMinus /> : <IconTrash />}
    </ActionIcon>
  );
};
