import { OrderStatus, PaymentStatus } from "../../graphql/graphql";

export const translateOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case "Accepted":
      return "Zaakceptowane";
    case "Canceled":
      return "Anulowane";
    case "Delivered":
      return "Dostarczone";
    case "Delivering":
      return "W drodze";
    case "Disputed":
      return "Rozpatrywane";
    case "Done":
      return "Zakończone";
    case "Ordered":
      return "Zamówione";
    case "Processing":
      return "Przetwarzane";
    case "ReadyToPickUp":
      return "Gotowe do odbioru";
    case "Rejected":
      return "Odrzucone";
    default:
      return status;
  }
};

export const translatePaymentStatus = (status: PaymentStatus) => {
  switch (status) {
    case "Paid":
      return "Zapłacone";
    case "Failed":
      return "Nieudane";
    case "Pending":
      return "Oczekujące";
    case "Refunded":
      return "Środki zwrócone";
    default:
      return status;
  }
};
