import * as React from "react";
import cn from "classnames";

type SnapTypes = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
export const Snap = React.forwardRef<HTMLDivElement, SnapTypes>(
  ({ children, className, style }, ref) => {
    const classNames = cn(
      "scrollbar-hide flex snap-x snap-mandatory overflow-y-hidden overflow-x-scroll",
      className,
    );

    // React.useEffect(() => {
    //   if (ref?.current) {
    //     ref.current.scrollLeft = 0;
    //   }
    // }, [ref]);

    return (
      <div ref={ref} className={classNames} style={style}>
        {children}
      </div>
    );
  },
);
