import { IonSearchbar } from "@ionic/react";
import * as React from "react";

type ReadonlySearchbarProps = {
  value: string;
};
export const ReadonlySearchbar = ({ value }: ReadonlySearchbarProps) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const run = async () => {
      const input = await ref.current.getInputElement();
      input.disabled = true;
    };

    run();
  }, [ref]);

  return <IonSearchbar ref={ref} value={value} showClearButton="never" />;
};
