import {
  CreatePaymentSheetOption,
  PaymentSheetEventsEnum,
} from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import * as React from "react";

import { useUserAddress } from "../../session/hooks/useUserDeliveryAddress";
import { useCart } from "../CartContext";
import { PayButton } from "./PayButton";

type PayProcessProps = {
  form: any;
  onSuccess?: (data: any) => void;
};
export const PayProcess = ({ form, onSuccess }: PayProcessProps) => {
  const { stripe, isApplePayAvailable } = useCapacitorStripe();
  const { cart, completeProcess } = useCart();
  const [isLoading, setIsLoading] = React.useState(false);

  const { address: myDeliveryAccess, isLoading: isDeliveryAddressLoading } =
    useUserAddress("DeliveryAddress");

  const pay = async () => {
    const { hasErrors } = form.validate();

    if (hasErrors) {
      return;
    }
    try {
      setIsLoading(true);

      const shouldPresentAlsoApplePay =
        isApplePayAvailable &&
        (cart.paymentMethod.type === "ApplePay" ||
          cart.paymentMethod.type === "Online");

      let paymentSheetData: CreatePaymentSheetOption = {
        paymentIntentClientSecret: cart.payment.metadata
          .paymentIntentClientSecret as unknown as string,
        customerEphemeralKeySecret: cart.payment.metadata.ephemeralKeySecret,
        returnURL: "com.zozia.app://tabs/discovery",
        merchantDisplayName: "Zozia Marketplace",
        countryCode: "PL",
        customerId: cart.customerId as unknown as string,
      };

      if (shouldPresentAlsoApplePay) {
        paymentSheetData = {
          ...paymentSheetData,
          applePayMerchantId: "merchant.com.zozia.app",
          enableApplePay: true,
        };
      }

      await stripe.createPaymentSheet(paymentSheetData);

      const { paymentResult } = await stripe.presentPaymentSheet();

      if (paymentResult === PaymentSheetEventsEnum.Completed) {
        await completeProcess(
          {
            cartId: cart.id as unknown as string,
            deliveryAddressId: myDeliveryAccess.id,
          },
          {
            onSuccess: async (data) => {
              await onSuccess?.(data);
            },
          },
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return <PayButton onClick={pay} isLoading={isLoading} />;
};
