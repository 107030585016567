import { IonButton, IonFooter, IonIcon, IonModal } from "@ionic/react";
import { Chip } from "@zozia/ui";
import * as React from "react";
import { chevronDownOutline } from "ionicons/icons";

type BrandSelectProps = {
  text: string;
  ModalContent: ({
    onToggle,
    items,
  }: {
    items: string[];
    onToggle: (e: any) => void;
  }) => React.ReactNode;
  onSubmit: (selectedItems: string[]) => void;
  onReset: () => void;
};

export const BrandSelect = ({
  text,
  ModalContent = () => null,
  onSubmit,
  onReset,
}: BrandSelectProps) => {
  const modal = React.useRef<HTMLIonModalElement>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedItems, setSelectedItem] = React.useState<string[]>([]);

  const toggleItem = (e) => {
    setSelectedItem((prev) => {
      if (prev.includes(e)) {
        return prev.filter((item) => item !== e);
      } else {
        return prev.concat(e);
      }
    });
  };

  return (
    <>
      <Chip
        onClick={() => setIsModalOpen(true)}
        icon={<IonIcon icon={chevronDownOutline} />}
        toggled={selectedItems.length > 0}
      >
        {text}
      </Chip>
      <IonModal
        ref={modal}
        isOpen={isModalOpen}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onIonModalWillDismiss={() => setIsModalOpen(false)}
      >
        <ModalContent items={selectedItems} onToggle={toggleItem} />
        <IonFooter>
          <div className="flex flex-col">
            <IonButton
              onClick={async () => {
                onSubmit(selectedItems);
                await modal.current.dismiss();
              }}
            >
              Zobacz wyniki
            </IonButton>
            <IonButton
              color="light"
              onClick={async () => {
                onReset();
                setSelectedItem([]);
                await modal.current.dismiss();
              }}
            >
              Zresetuj
            </IonButton>
          </div>
        </IonFooter>
      </IonModal>
    </>
  );
};
