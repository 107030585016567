import * as React from "react";
import { IonContent } from "@ionic/react";
import { Title, Button, Stack, Text, TextInput, PinInput } from "@mantine/core";
import { useCart } from "../../cart/CartContext";
import { IconPhone } from "@tabler/icons-react";
import {
  useSendVerificationCodeMutation,
  useSetPhoneNumberMutation,
} from "../../graphql/graphql";
import { useForm } from "@mantine/form";
import { useUser } from "../useUser";
import { useDisclosure, useInterval, useShallowEffect } from "@mantine/hooks";
import { match } from "react-router-dom";
import { RouteBackModal } from "../../../components/RouteBackModal";

type SetUserPhoneNumberModalProps = {
  isOpen: boolean;
  removeNestedOutlet: () => void;
  prevMatch: match;
  open: () => void;
};
export const SetUserPhoneNumberModal = ({
  opened,
  removeNestedOutlet = () => {},
  prevMatch,
  nestedRouteComponentProps,
}: SetUserPhoneNumberModalProps) => {
  const { openCartButton, closeCartButton } = useCart();
  const [user, { refetch }] = useUser();
  const [
    isVerificationCodeBlockedDueAlreadySent,
    {
      open: blockVerificationCodeDueAlreadySent,
      close: unblockVerificationCodeDueAlreadySent,
    },
  ] = useDisclosure();

  const {
    mutateAsync: sendVerificationCodeMutation,
    isLoading: isLoadingSendVerificationCode,
    isSuccess: isSuccessSendVerificationCode,
  } = useSendVerificationCodeMutation();
  const [
    isVerificationCodeIntervalVisible,
    {
      close: removeVerificationCodeInterval,
      open: showVerificationCodeInterval,
    },
  ] = useDisclosure(isSuccessSendVerificationCode);
  const { mutateAsync: setPhoneNumber, isLoading: isLoadingSetPhoneNumber } =
    useSetPhoneNumberMutation();

  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const form = useForm({
    initialValues: {
      phoneNumber: user?.phoneNumber || "",
      verificationCode: "",
    },
  });

  React.useEffect(() => {
    if (opened) {
      closeCartButton();
    }
    return openCartButton;
  }, [opened]);

  useShallowEffect(() => {
    if (user) {
      form.setValues({
        phoneNumber: user.phoneNumber || "",
        verificationCode: "",
      });
      form.resetDirty();
    }
  }, [user]);

  const isSendVerificationCodeDisabled =
    isVerificationCodeBlockedDueAlreadySent || !form.isDirty();

  const isVerificationCodeVisible = isSuccessSendVerificationCode;

  const isVerificationCodeInputVisible =
    isVerificationCodeIntervalVisible && isVerificationCodeVisible;

  return (
    <RouteBackModal
      ref={modalRef}
      prevMatch={prevMatch}
      removeNestedOutlet={removeNestedOutlet}
      nestedRouteComponentProps={nestedRouteComponentProps}
    >
      <IonContent>
        <Stack align="center" className="mt-8" gap={0}>
          <IconPhone size="65" strokeWidth={1} />
          <Title order={3}>Numer telefonu</Title>
          <Text className="text-center" c="gray">
            Weryfikacja numeru telefonu jest wymagana do złożenia zamówienia
          </Text>
        </Stack>
        <form
          className="mt-2 px-8"
          onSubmit={form.onSubmit(async (values) => {
            if (values.verificationCode) {
              await setPhoneNumber({
                input: {
                  phoneNumber: values.phoneNumber,
                  verificationCode: values.verificationCode,
                },
              });
              modalRef.current.close();
            } else {
              blockVerificationCodeDueAlreadySent();
              showVerificationCodeInterval();
              await sendVerificationCodeMutation({
                phoneNumber: values.phoneNumber,
              });
            }
            refetch();
          })}
        >
          <TextInput placeholder="+48" {...form.getInputProps("phoneNumber")} />
          <Button
            type="submit"
            fullWidth
            loading={isLoadingSendVerificationCode}
            disabled={isSendVerificationCodeDisabled}
            radius="lg"
            style={{
              fontWeight: 400,
            }}
            className="my-4"
          >
            Wyślij kod weryfikacyjny
          </Button>
          {isVerificationCodeVisible ? (
            <>
              {isVerificationCodeInputVisible ? (
                <VerificationCodeSendInterval
                  onFinish={() => {
                    unblockVerificationCodeDueAlreadySent();
                    removeVerificationCodeInterval();
                  }}
                />
              ) : null}
              <PinInput
                className="my-2"
                styles={{
                  root: {
                    "--group-justify": "center",
                  },
                }}
                oneTimeCode
                length={4}
                {...form.getInputProps("verificationCode")}
              />
              <Text className="text-center" c="gray" size="sm">
                Kod weryfikacyjny
              </Text>
              <Button
                type="submit"
                fullWidth
                radius="lg"
                loading={isLoadingSetPhoneNumber}
                style={{
                  fontWeight: 400,
                }}
                className="my-4"
              >
                Potwierdź numer telefonu
              </Button>
            </>
          ) : null}
        </form>
      </IonContent>
    </RouteBackModal>
  );
};

const VerificationCodeSendInterval = ({ onFinish }) => {
  const [seconds, setSeconds] = React.useState(60);
  const interval = useInterval(() => setSeconds((s) => s - 1), 1000);

  React.useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  React.useEffect(() => {
    if (seconds <= 0) {
      onFinish();
      interval.stop();
    }
  }, [seconds]);

  return (
    <Text c="gray" className="text-center">
      Ponowne wysłanie kodu możliwe będzie za {seconds} sekund
    </Text>
  );
};
