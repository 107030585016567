import { IonModal, IonNav } from "@ionic/react";
import * as React from "react";

import { useCart } from "../../CartContext";
import { DynamicModalProvider } from "../../components/SetUpPhoneNumberModal";
import { ActiveCartsModal } from "../ActiveCartsModal";
import { createSwipeAnimation } from "../createSwipeAnimation";
import { ActiveCartPage } from "./ActiveCartPage";
import { DeliveryAdditionalNote } from "./DeliveryAdditionalNote";
import { DeliveryAddressModal } from "./DeliveryAddressModal";
import { InstructionsModal } from "./InstructionsModal";
import { NoteModal } from "./NoteModal";
import { OfferModal } from "./OfferModal";
import { ReplacementModal } from "./ReplacementModal";

const { enterAnimation, leaveAnimation } = createSwipeAnimation("left");

type ActiveCartModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  presentingElement?: HTMLElement;
};

export const ActiveCartModal = ({
  forwardRef,
  presentingElement,
}: ActiveCartModalProps) => {
  const { cart, carts } = useCart();

  const modalInstructionRef = React.useRef<HTMLIonModalElement>();
  const modalNoteRef = React.useRef<HTMLIonModalElement>();
  const modalDeliveryAdditionalNoteRef = React.useRef<HTMLIonModalElement>();
  const modalReplacementRef = React.useRef<HTMLIonModalElement>();
  const activeCartsModalRef = React.useRef<HTMLIonModalElement>();
  const modalDeliveryAddressRef = React.useRef<HTMLIonModalElement>();
  const offerModalRef = React.useRef<HTMLIonModalElement>();

  const [selectedItem, setSelectedItem] = React.useState(null);

  // Prevents re-rendering of the Nav component
  const MemoizedNav = React.useMemo(() => {
    return (
      <IonNav
        root={() => (
          <ActiveCartPage
            forwardRef={forwardRef}
            modalInstructionRef={modalInstructionRef}
            activeCartsModalRef={activeCartsModalRef}
            modalDeliveryAddressRef={modalDeliveryAddressRef}
            offerModalRef={offerModalRef}
            setSelectedItem={setSelectedItem}
          />
        )}
      ></IonNav>
    );
  }, [selectedItem]);

  if (cart === null) {
    return null;
  }

  if (cart.items?.length === 0) {
    return;
  }

  if (carts.length === 0) {
    return null;
  }

  return (
    <>
      <DynamicModalProvider>
        <IonModal
          ref={forwardRef}
          presentingElement={presentingElement}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
        >
          {MemoizedNav}
        </IonModal>
      </DynamicModalProvider>
      <InstructionsModal
        forwardRef={modalInstructionRef}
        modalInstructionRef={modalInstructionRef}
        modalReplacementRef={modalReplacementRef}
        modalNoteRef={modalNoteRef}
        selectedItem={selectedItem}
      />
      <DeliveryAddressModal forwardRef={modalDeliveryAddressRef} />
      <DeliveryAdditionalNote forwardRef={modalDeliveryAdditionalNoteRef} />
      <NoteModal
        forwardRef={modalNoteRef}
        onDidDismiss={() => setSelectedItem(null)}
        selectedItem={selectedItem}
      />
      <ReplacementModal
        forwardRef={modalReplacementRef}
        onDidDismiss={() => setSelectedItem(null)}
        selectedItem={selectedItem}
      />
      <ActiveCartsModal forwardRef={activeCartsModalRef} />
      <OfferModal
        forwardRef={offerModalRef}
        presentingElement={forwardRef.current}
        selectedItem={selectedItem}
      />
    </>
  );
};
