import { createSafeContext } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import * as React from "react";

interface ReelsContextValue {
  state: {
    currentReelIndex: number;
  };
  setCurrentReelIndex: (index: number) => void;
  reelStory: ReelStory;
  getReel: (id: string) => Reel;
}

const [ReelsProvider, useReelsContext] = createSafeContext<ReelsContextValue>(
  "ReelsProvider component was not found in tree",
);

class Reel {
  id: string;
  seen: boolean = false;
  location: {
    id: string;
    name: string;
  };
  reels: {
    id: string;
    createdAt: string;
    source: string;
    type: string;
  }[];

  constructor(data: {
    id: string;
    index: number;
    location: {
      id: string;
      name: string;
    };
    story: {
      id: string;
      reels: {
        id: string;
        createdAt: string;
        source: string;
        type: string;
      }[];
    };
  }) {
    this.id = data.id;
    this.location = data.location;
    this.reels = data.story.reels;
  }
}

class ReelStory {
  loadedReels: any[] = [];
  addReel(reel) {
    const idx = this.loadedReels.some((r) => r.id === reel.id);
    if (!idx) {
      this.loadedReels.push(new Reel(reel));
    }
  }
}

export const Reels2 = ({ children }) => {
  const reelStory = React.useRef(new ReelStory());
  const [state, setState] = useSetState({ currentReelIndex: 0 });

  const currentReelStory = reelStory.current;

  const value = React.useMemo(
    () => ({
      reelStory: currentReelStory,
      state,
      getReel: (id: string) => {
        return currentReelStory.loadedReels.find((r) => r.id === id);
      },
      setCurrentReelIndex: (index: number) => {
        setState({ currentReelIndex: index });
      },
    }),
    [state, reelStory],
  );

  return <ReelsProvider value={value}>{children}</ReelsProvider>;
};

export { useReelsContext };
