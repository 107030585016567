import { RouteManagerContext, useIonRouter } from "@ionic/react";
import { IconShoppingBag, IconShoppingBagSearch } from "@tabler/icons-react";
import { motion } from "framer-motion";
import * as React from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";

import { TabButton } from "../../../TabButton";
import { MobileSafeAnimatePresence } from "../../../components/MobileSafeAnimatePresence";
import { useSession } from "../../session";
import { useCart } from "../CartContext";
import { ActiveCartModal } from "./ActiveCartModal";
import { ActiveCartsModal } from "./ActiveCartsModal";
import { ShowCartFloatingButton } from "./ShowCartFloatingButton";
import { ShowCartsFloatingButton } from "./ShowCartsFloatingButton";

const tryCreatePortalWrapper = (target = document.body, id?: string) => {
  const domId = ["react-portal", id].filter(Boolean).join("-");
  let domNode = document.getElementById(domId);

  if (!domNode) {
    domNode = document.createElement("div");
    domNode.setAttribute("id", domId);
    target.appendChild(domNode);
  }
  return domNode;
};

type PortalProps = {
  opened: boolean;
  target: HTMLElement;
  children: React.ReactNode;
};

const Portal = ({ target, children, opened }: PortalProps) => {
  if (!target) return null;

  const domNode = tryCreatePortalWrapper(target, "cart");

  return ReactDOM.createPortal(
    <MobileSafeAnimatePresence>
      {opened ? children : null}
    </MobileSafeAnimatePresence>,
    domNode,
  );
};

export const OpenCartTabButton = () => {
  const activeCartsModalRef = React.useRef<HTMLIonModalElement>(null);
  const activeCartModalRef = React.useRef<HTMLIonModalElement>(null);

  window.activeCartModalRef = activeCartModalRef;
  window.activeCartsModalRef = activeCartsModalRef;

  const { carts } = useCart();
  const { routeInfo } = useIonRouter();
  const { findViewItemByRouteInfo } = React.useContext(RouteManagerContext);
  const { ionPageElement: presentingElement } =
    findViewItemByRouteInfo(routeInfo);
  const { snapshot } = useSession();
  const location = useLocation();

  const varSafeBottom = getComputedStyle(
    document.documentElement,
  ).getPropertyValue("--ion-safe-area-bottom");

  const safeBottom = parseInt(varSafeBottom, 10) + 40;

  const loc = location.location || location;
  const currentPathname = loc.pathname;

  const isLocation = currentPathname.includes("/locations/");

  return (
    <>
      <Portal
        target={presentingElement}
        opened={carts.length > 0 && !!snapshot}
      >
        <motion.div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="absolute left-1/2"
          initial={{
            bottom: "-100px",
          }}
          animate={{
            opacity: 1,
            bottom: `${safeBottom}px`,
          }}
          exit={{
            opacity: 0,
            bottom: "-100px",
          }}
        >
          {isLocation ? (
            <ShowCartFloatingButton
              layoutId="show-carts"
              onClick={() => {
                activeCartModalRef.current.present();
              }}
              transition={{
                type: "spring",
                stiffness: 350,
                duration: 0.3,
                damping: 25,
              }}
            />
          ) : (
            <ShowCartsFloatingButton
              layoutId="show-carts"
              onClick={() => {
                activeCartsModalRef.current.present();
              }}
              transition={{
                type: "spring",
                stiffness: 350,
                duration: 0.3,
                damping: 25,
              }}
            />
          )}
        </motion.div>
      </Portal>
      <TabButton
        classNames={{
          button: "h-full",
        }}
        idleState={{
          icon: <IconShoppingBag />,
          label: "Koszyk",
        }}
        activeState={{
          icon: <IconShoppingBagSearch />,
          label: "Koszyk",
        }}
        onClick={() => activeCartsModalRef.current?.present()}
      />
      <ActiveCartsModal forwardRef={activeCartsModalRef} />
      <ActiveCartModal forwardRef={activeCartModalRef} />
    </>
  );
};
