const enOrdinalRules = new Intl.PluralRules("pl-PL");

const suffixes = new Map([
  ["one", "koszyk"],
  ["few", "koszyki"],
  ["many", "koszyków"],
]);

export const formatOrdinals = (n) => {
  const rule = enOrdinalRules.select(n);
  const suffix = suffixes.get(rule);

  return `${n} ${suffix}`;
};
