import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  RouteManagerContext,
  useIonRouter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import * as React from "react";

import { getCurrentUserLocation } from "../../../geolocation/epics";
import { CityListQuery } from "../../../graphql/graphql";

type SetManualMyLocationProps = {
  isOpen: boolean;
  onClose: () => void;
  cityList: CityListQuery["cityList"];
  isDenied: boolean;
  onAreaSelect: ({
    name,
    latitude,
    longitude,
  }: {
    name: string;
    latitude: number;
    longitude: number;
  }) => void;
  setSelectedName: (name: string) => void;
};

export const ShowCityListModal = ({
  cityList,
  isOpen,
  onClose,
  isDenied,
  onAreaSelect,
  setSelectedName,
}: SetManualMyLocationProps) => {
  const { routeInfo } = useIonRouter();
  const { findViewItemByRouteInfo } = React.useContext(RouteManagerContext);

  const { ionPageElement: presentingElement } =
    findViewItemByRouteInfo(routeInfo);

  return (
    <IonModal
      isOpen={isOpen}
      presentingElement={presentingElement}
      onIonModalWillDismiss={onClose}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Wybierz miasto</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {isDenied ? null : (
            <IonItem
              onClick={async () => {
                setSelectedName(null);
                await getCurrentUserLocation();
                onClose();
              }}
            >
              <IonLabel className="text-center">
                Użyj mojej lokalizacji
              </IonLabel>
            </IonItem>
          )}
          {(cityList || [])
            .sort((a, b) => a.name.localeCompare(b.name, "pl"))
            .map((city) => (
              <IonItem
                key={city.id}
                onClick={() => {
                  onAreaSelect({
                    name: city.name,
                    latitude: city.lat,
                    longitude: city.lng,
                  });
                  onClose();
                }}
              >
                <IonLabel className="text-center">{city.name}</IonLabel>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};
