import { Money } from "@zozia/money";
import * as React from "react";

import { type CartContextType } from "./types";

export const CartContext = React.createContext<CartContextType>(
  {} as CartContextType,
);

export const useCart = () => {
  return React.useContext(CartContext);
};
