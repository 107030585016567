import { AuthResult } from "@ionic-enterprise/auth";
import { IonSpinner } from "@ionic/react";
import { TokensStore } from "@zozia/oidc";
import * as React from "react";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { setupAuthConnect } from "./utils/auth";

type Props = { children?: ReactNode };
type Context = { session?: AuthResult };

const AuthContext = createContext<Context | undefined>(undefined);

const AuthProvider = ({ children }: Props) => {
  const [isSetup, setIsSetup] = useState<boolean>(false);

  useEffect(() => {
    setupAuthConnect().then(() => setIsSetup(true));
  }, []);

  return isSetup ? children : <IonSpinner />;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("useAuth must be used within AuthProvider");
  return context;
};

export default AuthProvider;
