import { useIsFetching, useIsMutating } from "@tanstack/react-query";

export const useIsActiveCartCalculating = ({ cartId }: { cartId: string }) => {
  const isMutatingIncreaseCartItem = useIsMutating(["IncreaseCartItem"]);
  const isMutatingDecreaseCartItem = useIsMutating(["DecreaseCartItem"]);
  const isLoadingCalculation = useIsFetching(["CalculateShoppingCart", cartId]);

  return (
    !!isMutatingIncreaseCartItem ||
    !!isMutatingDecreaseCartItem ||
    !!isLoadingCalculation
  );
};
