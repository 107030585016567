import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import {
  iosTransitionAnimation,
  isPlatform,
  setupIonicReact,
} from "@ionic/react";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { App } from "./App";
import { initializeVault } from "./features/session/utils/vault";
import "./lib/i18n";
import { initializeLiveUpdate } from "./liveUpdate";

if (process.env.NODE_ENV === "production") {
  Sentry.init(
    {
      dsn: "https://e3d7d02c48757f7715cc16b963ca3064@o4507090272518144.ingest.de.sentry.io/4507090274287696",
      integrations: [
        SentryReact.browserTracingIntegration({
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/api.zozia\.app\/graphql/,
            /^https:\/\/api.zozia\.app\/api/,
          ],
        }),
        SentryReact.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
    // Forward the init method from @sentry/react
    SentryReact.init,
  );
}

if (!isPlatform("hybrid")) {
  /**
   * Platform: Web
   * Configure and initialize the firebase app.
   * @param options - firebase web app configuration options
   * */
  FirebaseAnalytics.initializeFirebase({
    apiKey: "AIzaSyCuBITIIxAsnzJruT9Wd6OwUi1GUXPAUFM",
    authDomain: "zozia-694ff.firebaseapp.com",
    projectId: "zozia-694ff",
    storageBucket: "zozia-694ff.appspot.com",
    messagingSenderId: "19293718339",
    appId: "1:19293718339:web:313cbd60a96291e51311f6",
    measurementId: "G-XSLL0B0JVS",
  });
}

setupIonicReact({
  mode: "ios",
  swipeBackEnabled: true,
  navAnimation: (el, opts) => {
    const options = {
      ...opts,
      duration: 350,
    };
    return iosTransitionAnimation(el, options);
  },
});

const main = async () => {
  const rootElement = document.getElementById("app");
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);

    if (process.env.NODE_ENV === "production" && isPlatform("hybrid")) {
      await initializeLiveUpdate();
    }

    await initializeVault();

    root.render(<App />);
  }
};

main();

globalThis.__ZOZIA__ = {
  meta: {
    META_PROJECT_VERSION: process.env.META_PROJECT_VERSION,
    META_PROJECT_NAME: process.env.META_PROJECT_NAME,
    META_IMAGE_VERSION: process.env.META_IMAGE_VERSION,
  },
};
