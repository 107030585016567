import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Geolocation } from "@capacitor/geolocation";
import { Toast } from "@capacitor/toast";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { Avatar, Button, Center, Flex, Text } from "@mantine/core";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ModalAsRoute } from "../../discovery/components/ModalAsRoute";
import { watchGeolocationPermissionQuery$ } from "../../geolocation/epics";
import { Section } from "../components/Section";
import { SessionDebug } from "../components/SessionDebug";
import { useUser } from "../useUser";
import { SetUserPhoneNumberModal } from "./SetUserPhoneNumberModal";

const reload = async () => {
  await LiveUpdate.reload();

  await LiveUpdate.ready();
};
const AppUpdater = () => {
  const [nextBundleId, setNextBundleId] = React.useState<string | null>(null);

  const sync = async () => {
    const result = await LiveUpdate.sync();
    if (result.nextBundleId) {
      setNextBundleId(result.nextBundleId);
    }
  };

  return (
    <div>
      Updater
      <ul>
        <li>
          <button onClick={sync}>Sync</button>
        </li>
        {nextBundleId ? (
          <li>
            <button onClick={reload}>Reload</button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export const AccountPage = () => {
  const match = useRouteMatch();
  const [user] = useUser();
  const permission = useObservableState(watchGeolocationPermissionQuery$);
  const history = useHistory();
  const isLocationPermissionGranted = permission === "granted";

  const enable = () => {
    if (user) {
      FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      });

      FirebaseAnalytics.setUserId({
        userId: user.sub,
      });
    }
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Konto</IonTitle>
          </IonToolbar>
        </IonHeader>
        {user ? (
          <Center>
            <Flex direction="column">
              <Center>
                {user?.picture ? (
                  <Avatar src={user?.picture} size="xl" />
                ) : (
                  <Avatar size="xl">
                    {[user.givenName, user.familyName]
                      .filter(Boolean)
                      .map((text) => text[0])
                      .join("")}
                  </Avatar>
                )}
              </Center>
              <Text>{user.name}</Text>
            </Flex>
          </Center>
        ) : null}
        <Flex direction="column" justify="space-between" className="h-full">
          <Section title="Ustawienia" inset>
            <IonList>
              <IonItem>
                <IonToggle
                  disabled={isLocationPermissionGranted}
                  checked={isLocationPermissionGranted}
                  onIonChange={async () => {
                    if (!isLocationPermissionGranted) {
                      try {
                        const a = await Geolocation.requestPermissions({
                          permissions: ["location"],
                        });

                        if (a.location === "denied") {
                          await Toast.show({
                            text: "Aby pokazać Ci miejsca w okolicy, zezwól aplikacji Zozia na korzystanie z informacji o Twojej lokalizacji.",
                          });
                          await NativeSettings.open({
                            optionAndroid: AndroidSettings.ApplicationDetails,
                            optionIOS: IOSSettings.App,
                          });
                        }
                      } catch (a) {
                        await Toast.show({
                          text: "Aby pokazać Ci miejsca w okolicy, zezwól aplikacji Zozia na korzystanie z informacji o Twojej lokalizacji.",
                        });
                        await NativeSettings.open({
                          optionAndroid: AndroidSettings.ApplicationDetails,
                          optionIOS: IOSSettings.App,
                        });
                      }
                    }
                  }}
                >
                  Dostęp do lokalizacji
                </IonToggle>
              </IonItem>
              <IonItem>
                <IonInput
                  label="Numer telefonu"
                  {...(user?.phoneNumber
                    ? {
                        labelPlacement: "floating",
                      }
                    : {})}
                  value={user?.phoneNumber}
                  readonly
                />
                {!user?.phoneNumber ? (
                  <>
                    <Button
                      variant="subtle"
                      onClick={() =>
                        history.push(`${match.url}/setupPhoneNumber`)
                      }
                    >
                      Dodaj
                    </Button>
                  </>
                ) : null}
              </IonItem>
              <IonItem>
                <IonText>
                  {`Wersja: ${process.env.META_PROJECT_VERSION}.${process.env.META_IMAGE_VERSION}`}
                </IonText>
              </IonItem>
              <IonItem>
                <IonToggle onIonChange={enable}>
                  Zgadzam się na przetwarzanie moich danych osobowych
                </IonToggle>
              </IonItem>
            </IonList>
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" color="light">
                  <IonLabel>Aktualizacja</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <AppUpdater />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" color="light">
                  <IonLabel>Debugowanie sesji</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <SessionDebug />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </Section>
        </Flex>
      </IonContent>
      <ModalAsRoute
        prevMatch={match}
        routes={{
          [`${match.url}/setupPhoneNumber`]: [
            SetUserPhoneNumberModal,
            {
              initialBreakpoint: 0.7,
              breakpoints: [0, 0.7],
            },
          ],
        }}
      />
    </IonPage>
  );
};
