import { BrowserVault, Vault } from "@ionic-enterprise/identity-vault";
import { isPlatform } from "@ionic/react";

let vault;

export const createVault = (): Vault | BrowserVault => {
  if (vault) return vault;

  vault = isPlatform("hybrid") ? new Vault() : new BrowserVault();

  return vault;
};
