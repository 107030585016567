import { CapacitorHttp } from "@capacitor/core";

import { useSession } from "../features/session";

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"] & { url?: string },
): ((variables?: TVariables) => Promise<TData>) => {
  const { getAccessToken } = useSession();

  return async (variables?: TVariables) => {
    try {
      const accessToken = await getAccessToken();

      const { data } = await CapacitorHttp.request({
        url: process.env.DATA_SERVICE,
        ...options,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
        data: JSON.stringify({
          ...(query ? { query } : {}),
          variables,
        }),
      });
      return data.data;
    } catch (error) {
      return error;
    }
  };
};
