import { SplashScreen } from "@capacitor/splash-screen";
import { isPlatform } from "@ionic/react";
import * as React from "react";

type SplashContainerProps = { children?: React.ReactNode };

export const SplashContainer = ({ children }: SplashContainerProps) => {
  React.useEffect(() => {
    isPlatform("hybrid") && SplashScreen.hide();
  }, []);

  return <>{children}</>;
};
