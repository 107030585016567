import * as React from "react";

import { PageContent } from "./PageContent";
import { PageLayout } from "./PageLayout";

export const ListLocationsPageByProductsPage = () => {
  return (
    <PageLayout>
      <PageContent />
    </PageLayout>
  );
};
