import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useEventListener } from "@mantine/hooks";
import { IconHome, IconMapPin } from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import * as React from "react";

import { UserAddressCopy, useMyAddressesQuery } from "../../graphql/graphql";

type LocationDeliveryAddressSelectorProps = {
  value: UserAddressCopy;
  locationId: string;
  onChange: ({ deliveryAddressId }: { deliveryAddressId: string }) => void;
  addNewAddressModalRef: React.RefObject<HTMLIonModalElement>;
  error?: string;
};

export const LocationDeliveryAddressSelector = ({
  value,
  locationId,
  onChange,
  error,
  addNewAddressModalRef,
}: LocationDeliveryAddressSelectorProps) => {
  const modalRef = React.useRef<HTMLIonModalElement>(null);

  const { data: userDeliveryAddresses, isLoading } = useMyAddressesQuery(
    {
      type: "DeliveryAddress",
    },
    {
      enabled: !!locationId,
      select: (data) => data.myAddresses.filter(Boolean) || [],
    },
  );

  const selectedOption = userDeliveryAddresses?.find(
    (option) => option.id === value?.address?.id,
  );

  const { primary, secondary } = tryToGetMostAccurateAddress(
    selectedOption?.address || value?.address || {},
  );

  return (
    <div className="relative">
      <LoadingOverlay visible={isLoading} />

      <IonItem lines="none">
        <div slot="start" className="self-start mt-[10px]">
          <IconMapPin />
        </div>
        <IonLabel>
          {isLoading ? (
            <IonSkeletonText animated style={{ width: "100%" }} />
          ) : (
            primary || "Brak adresu dostawy"
          )}
          <Text>
            {isLoading ? (
              <IonSkeletonText animated style={{ width: "100%" }} />
            ) : (
              secondary || ""
            )}
          </Text>
          {error ? (
            <Text c="red" size="xs">
              {error}
            </Text>
          ) : null}
        </IonLabel>
        <div className="self-start mt-[10px]">
          <Button
            variant="subtle"
            onClick={async () => {
              await Haptics.impact({
                style: ImpactStyle.Light,
              });
              modalRef.current.present();
            }}
          >
            Zmień
          </Button>
        </div>
      </IonItem>

      <IonModal ref={modalRef} initialBreakpoint={0.7} breakpoints={[0, 0.7]}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Twoje adresy dostaw</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <Stack className="px-4">
            {userDeliveryAddresses?.map((option) => {
              const { primary, secondary } = tryToGetMostAccurateAddress(
                option.address || {},
              );

              return (
                <DeliveryAddressSelectorOption
                  onClick={() => {
                    onChange({ deliveryAddressId: option.id });
                    modalRef.current.dismiss();
                  }}
                  key={option.id}
                  firstLine={primary}
                  secondLine={secondary}
                  icon={<IconHome />}
                />
              );
            })}
            <NewDeliveryAddressButton
              modalRef={modalRef}
              addNewAddressModalRef={addNewAddressModalRef}
            />
          </Stack>
        </IonContent>
      </IonModal>
    </div>
  );
};

type NewDeliveryAddressButtonProps = {
  modalRef: React.RefObject<HTMLIonModalElement>;
  addNewAddressModalRef: React.RefObject<HTMLIonModalElement>;
};

const NewDeliveryAddressButton = ({
  modalRef,
  addNewAddressModalRef,
}: NewDeliveryAddressButtonProps) => {
  const ref = useEventListener("click", async () => {
    await modalRef.current.dismiss();
    addNewAddressModalRef.current.present();
  });

  return (
    <Stack className="border border-solid rounded-md py-4" ref={ref}>
      <Text className="text-center">Dodaj nowy adres</Text>
    </Stack>
  );
};

type DeliveryAddressSelectorOptionOptionProps = {
  onClick?: () => void;
  firstLine: string;
  secondLine: string;
  icon: React.ReactElement;
  rightSection?: React.ReactNode;
};
const DeliveryAddressSelectorOption = ({
  onClick = () => {},
  firstLine,
  secondLine,
  icon,
  rightSection,
}: DeliveryAddressSelectorOptionOptionProps) => {
  const onClickHandler = async () => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });

    onClick();
  };
  const ref = useEventListener("click", onClickHandler);

  return (
    <Group
      className="border border-solid rounded-md py-4 pl-4 pr-2"
      role="button"
      ref={ref}
    >
      <Group justify="space-between" wrap="nowrap" className="w-full">
        <div className="min-w-[30px]">{icon}</div>
        <Stack gap={0} className="w-full grow">
          <Text size="xs">{firstLine}</Text>
          <Text size="xs">{secondLine}</Text>
        </Stack>
        <div className="self-center">{rightSection ? rightSection : null}</div>
      </Group>
    </Group>
  );
};
