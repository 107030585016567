import { Toast } from "@capacitor/toast";
import { IonChip, IonIcon, IonModal, IonText } from "@ionic/react";
import { isPlatform } from "@ionic/react";
import { Button, Divider, Stack } from "@mantine/core";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { locationOutline } from "ionicons/icons";
import { useObservableState } from "observable-hooks";
import * as React from "react";

import {
  getCurrentUserLocation,
  watchGeolocationPermissionQuery$,
} from "../../../geolocation/epics";

type SetManualMyLocationProps = {
  onButtonPress: () => void;
};

export const SetAutomaticMyLocation = ({
  onButtonPress,
}: SetManualMyLocationProps) => {
  const locationPermissionStatus = useObservableState(
    watchGeolocationPermissionQuery$,
    "granted",
  );

  const modal = React.useRef<HTMLIonModalElement>(null);

  const tryRequestPermissionForLocation = async () => {
    if (locationPermissionStatus === "denied") {
      await Toast.show({
        text: "Aby pokazać Ci miejsca w okolicy, zezwól aplikacji Zozia na korzystanie z informacji o Twojej lokalizacji.",
      });
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    } else {
      getCurrentUserLocation();
    }
  };

  const openCityListModal = async () => {
    await modal.current?.dismiss();
    await onButtonPress();
  };

  return (
    <IonModal
      ref={modal}
      backdropDismiss={false}
      isOpen={locationPermissionStatus === "denied"}
      initialBreakpoint={0.5}
      style={{ "--border-radius": "20px" }}
    >
      <div className="flex flex-col justify-center p-2">
        <div className="flex flex-col items-center pt-4 md:pt-8">
          <IonIcon icon={locationOutline} size="large" />
          <IonText className="py-2 font-semibold">Gdzie jesteś?</IonText>
          <IonText className="text-center">
            Udostępnij swoją lokalizację, abyśmy mogli zlokalizować dla Ciebie
            sklepy w okolicy
          </IonText>
        </div>
        <Stack className="pt-4" gap={4}>
          {isPlatform("hybrid") ? (
            <Button
              onClick={tryRequestPermissionForLocation}
              radius="xl"
              size="md"
              className="my-4"
            >
              Ustaw automatycznie
            </Button>
          ) : (
            <div className="flex pb-2">
              <div className="flex flex-[35%] justify-center">
                <IonChip color="tertiary">Porada</IonChip>
              </div>
              <IonText>Zezwól stronie na dostęp do Twojej lokalizacji</IonText>
            </div>
          )}

          <Divider label="ALBO" />

          <Button
            onClick={openCityListModal}
            radius="xl"
            size="md"
            className="my-4"
          >
            Przeglądaj wybierając miasto
          </Button>
        </Stack>
      </div>
    </IonModal>
  );
};
