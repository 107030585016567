import { Geolocation } from "@capacitor/geolocation";
import type { PositionOptions } from "@capacitor/geolocation";

export class GeolocationService {
  getCurrentPosition(options: PositionOptions) {
    return Geolocation.getCurrentPosition(options).catch(() => null);
  }
  checkPermissions() {
    return Geolocation.checkPermissions();
  }
  requestPermissions() {
    return Geolocation.requestPermissions({ permissions: ["location"] });
  }
}

export const geolocationService = new GeolocationService();
