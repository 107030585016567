import { StringParam, useQueryParam, withDefault } from "use-query-params";

export const useStateQuerySearch = () => {
  const [state, setValue] = useQueryParam(
    "state",
    withDefault(StringParam, ""),
  );

  return {
    state,
    updateQuery: setValue,
  };
};
