import { IonSpinner } from "@ionic/react";
import { Button, Divider, Group, Loader, Stack, Text } from "@mantine/core";
import { cropImageTo } from "@zozia/ui";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";
import { location$ } from "../../../geolocation/epics";
import { useListNearOffersQuery } from "../../../graphql/graphql";
import { Distance } from "../../../session/utils/geo-distance";
import { OffersFromCategory } from "../../components/OffersFromCategory";
import { ReadonlySearchbar } from "../../components/ReadonlySearchbar";

const cropImageToAvatar = cropImageTo(100);

export const PageContent = () => {
  const { productId } = useParams<{ productId: string }>();
  const currentLocation = useObservableState(location$, null);
  const history = useHistory();

  const { data, isLoading } = useListNearOffersQuery(
    {
      input: {
        location: [currentLocation?.latitude, currentLocation?.longitude],
        productId,
        radius: 5000,
      },
    },
    {
      enabled: !!currentLocation,
    },
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-56px)]">
        <Loader type="dots" />
      </div>
    );
  }

  if (data?.listNearOffers?.length === 0) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-56px)]">
        <Text>Nie znaleziono ofert</Text>
      </div>
    );
  }

  if (!!data && !!data?.listNearOffers?.length) {
    const searchedProduct = data?.listNearOffers?.[0]?.product;

    return (
      <>
        <ReadonlySearchbar value={searchedProduct?.name} />
        <div className="px-3">
          {data?.listNearOffers
            .sort((a, b) => a.distance - b.distance)
            .map((item, idx, array) => (
              <React.Fragment key={item.location.id}>
                <Stack>
                  <Group wrap="nowrap">
                    <div className="leading-[0] w-[60px] h-[60px] rounded-md overflow-hidden">
                      <AutoAlignImage
                        src={cropImageToAvatar(item.location.images[0]?.url)}
                        alt="logo"
                      />
                    </div>
                    <Stack>
                      <Stack gap={3}>
                        <Text>{item.location.name}</Text>
                        <Text size="xs">{item.location.address}</Text>
                      </Stack>
                      <Text size="sm">
                        {Distance(item.distance, "m")
                          .human_readable("metric")
                          .toString()}
                      </Text>
                    </Stack>
                  </Group>
                  <OffersFromCategory
                    categoryId={searchedProduct?.category?.id}
                    locationId={item.location.id}
                  />
                  <Button
                    radius="xl"
                    size="md"
                    fullWidth
                    classNames={{
                      root: "p-0",
                    }}
                    onClick={() => {
                      history.push(
                        `/tabs/discovery/locations/${item.location.id}/offer/${item.offer.id}`,
                      );
                    }}
                  >
                    Przejdź do oferty
                  </Button>
                </Stack>

                {idx !== array.length - 1 ? <Divider className="my-4" /> : null}
              </React.Fragment>
            ))}
        </div>
      </>
    );
  }
};
