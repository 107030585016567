import { Device } from "@capacitor/device";
import { noop } from "@zozia/fp";
import { createI18n } from "@zozia/react-i18n";

const DEFAULT_LANGUAGE = "pl-PL";

const capacitorLanguageDetector = {
  type: "languageDetector",
  async: true, // flags below detection to be async
  detect: (callback) => {
    return Device.getLanguageCode().then(({ value }) => {
      if (value.toLowerCase().includes("pl")) {
        value = DEFAULT_LANGUAGE;
      }

      if (value.toLowerCase().includes("en")) {
        value = "en-US";
      }

      callback([value]);
    });
  },
  init: noop,
  cacheUserLanguage: noop,
};

export const i18n = createI18n({
  fallbackLng: DEFAULT_LANGUAGE,
  ns: ["common"],
  debug: process.env.NODE_ENV !== "production",
  languageDetector: capacitorLanguageDetector,
});
