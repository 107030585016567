import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IonModal } from "@ionic/react";
import { Button, Group, Stack, Text } from "@mantine/core";
import { IconNote, IconReplace } from "@tabler/icons-react";
import * as React from "react";

type InstructionsModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  modalInstructionRef: React.RefObject<HTMLIonModalElement>;
  modalReplacementRef: React.RefObject<HTMLIonModalElement>;
  modalNoteRef: React.RefObject<HTMLIonModalElement>;
  selectedItem: any;
};
export const InstructionsModal = ({
  forwardRef,
  modalInstructionRef,
  modalReplacementRef,
  selectedItem,
  modalNoteRef,
}: InstructionsModalProps) => {
  return (
    <IonModal
      ref={forwardRef}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      style={{ "--border-radius": "20px", "--height": "auto" }}
    >
      <Stack className="p-4">
        <Text className="pb-4">Instrukcje dla sklepu</Text>
        <Stack gap={14}>
          {/* TODO: Needs to implement this feature in near feature */}
          {/* <Group>
            <IconReplace />
            <Text
              component="span"
              fw={600}
              onClick={async () => {
                await Promise.all([
                  Haptics.impact({
                    style: ImpactStyle.Light,
                  }),
                  modalInstructionRef.current.dismiss(),
                ]);
                await modalReplacementRef.current.present();
              }}
            >
              {selectedItem?.replacement ? "Edytuj" : "Wybierz"} zamiennik
            </Text>
          </Group> */}

          <Group>
            <IconNote />
            <Text
              component="span"
              fw={600}
              onClick={async () => {
                await Promise.all([
                  Haptics.impact({
                    style: ImpactStyle.Light,
                  }),
                  modalInstructionRef.current.dismiss(),
                ]);
                await modalNoteRef.current.present();
              }}
            >
              Dodaj notatkę
            </Text>
          </Group>
        </Stack>
        <Button
          onClick={() => {
            modalInstructionRef.current.dismiss();
          }}
          radius="xl"
          size="md"
          fullWidth
          className="my-4"
        >
          Zamknij
        </Button>
      </Stack>
    </IonModal>
  );
};
