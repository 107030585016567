import {
  IconHourglassEmpty,
  IconTruckDelivery,
  IconPackage,
  IconCircleCheck,
  IconMoodLookDown,
  IconQuestionMark,
} from "@tabler/icons-react";
import * as React from "react";

import { OrderStatus } from "../../graphql/graphql";
import { Popover, Text } from "@mantine/core";
import { translateOrderStatus } from "./helpers";

export const OrderStatusIcon = ({ status }: { status: OrderStatus }) => {
  let icon = <></>;

  switch (status) {
    case "Ordered":
    case "Processing":
    case "Accepted":
      icon = <IconHourglassEmpty slot="start" />;
      break;
    case "Delivering":
      icon = <IconTruckDelivery slot="start" />;
      break;

    case "Delivered":
    case "ReadyToPickUp":
      icon = <IconPackage slot="start" />;
      break;
    case "Done":
      icon = <IconCircleCheck slot="start" />;
      break;
    case "Canceled":
      icon = <IconCircleCheck slot="start" />;
      break;
    case "Rejected":
      icon = <IconMoodLookDown slot="start" />;
      break;
    case "Disputed":
      icon = <IconQuestionMark slot="start" />;
      break;
  }

  return (
    <Popover width={150} position="left" withArrow shadow="md">
      <Popover.Target>{icon}</Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">{translateOrderStatus(status)}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
