import * as React from "react";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Flex } from "@mantine/core";
import { useRouteMatch } from "react-router-dom";

import { Section } from "../components/Section";
import { ModalAsRoute } from "../../discovery/components/ModalAsRoute";
import {
  CreateNewPaymentMethodModal,
  CreateSetupIntent,
} from "./CreateNewPaymentMethodModal";
import { IconCreditCard } from "@tabler/icons-react";

export const PaymentsPage = () => {
  const match = useRouteMatch();

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Metody płatności</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Flex direction="column" justify="space-between" className="h-full">
          <Section title="Zapisane metody płatności" inset>
            <CreateSetupIntent>
              <IonList>
                <IonItem button routerLink={`${match.url}/addNew`}>
                  <IconCreditCard slot="start" />
                  <IonText>Dodaj nową kartę</IonText>
                </IonItem>
              </IonList>
            </CreateSetupIntent>
          </Section>
        </Flex>
      </IonContent>
      <ModalAsRoute
        prevMatch={match}
        routes={{
          [`${match.url}/addNew`]: [CreateNewPaymentMethodModal],
        }}
      />
    </IonPage>
  );
};
