import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { Button, Group, Stack, Text, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconMapPin } from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { useObservableState } from "observable-hooks";
import * as React from "react";

import { location$ } from "../../geolocation/epics";
import { useCart } from "../CartContext";
import {
  useDynamicModal,
  useDynamicModalContext,
} from "./SetUpPhoneNumberModal";

type SetUpAdditionalDeliveryInformationModalProps = {
  deliveryAddress: any;
};

export const SetUpAdditionalDeliveryInformationModal = ({
  deliveryAddress,
}: SetUpAdditionalDeliveryInformationModalProps) => {
  const { present } = useDynamicModal(
    React.useMemo(
      () => (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Dodatkowe informacje</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <SetUpAdditionalDeliveryInformationModalForm
              deliveryAddress={deliveryAddress}
            />
          </IonContent>
        </>
      ),
      [deliveryAddress],
    ),
    { initialBreakpoint: 0.7, breakpoints: [0, 0.7, 1] },
  );
  return (
    <Button
      variant="subtle"
      onClick={async () => {
        await Haptics.impact({
          style: ImpactStyle.Light,
        });

        await present();
      }}
    >
      {deliveryAddress.id ? "Dodaj" : "Zmień"}
    </Button>
  );
};

type SetUpAdditionalDeliveryInformationModalFormProps = {
  deliveryAddress: any;
};

const SetUpAdditionalDeliveryInformationModalForm = ({
  deliveryAddress,
}: SetUpAdditionalDeliveryInformationModalFormProps) => {
  const { modalRef } = useDynamicModalContext();
  const { cart, setDeliveryAddress } = useCart();
  const currentLocation = useObservableState(location$, null);

  const form = useForm({
    initialValues: {
      apartmentNumber: deliveryAddress.address.apartmentNumber || "",
      floor: deliveryAddress.address.floor || "",
      additionalInformation:
        deliveryAddress.address.additionalInformation || "",
    },
  });

  const rawAddress = deliveryAddress.id
    ? deliveryAddress.address.address
    : deliveryAddress.address;

  const address = tryToGetMostAccurateAddress(rawAddress);

  return (
    <>
      <Stack gap={2}>
        <Group align="start">
          <IconMapPin className="mt-1" />
          <Stack gap={1}>
            <Text fw={600} size="lg">
              {address?.primary}
            </Text>
            <Text>{address?.secondary}</Text>
          </Stack>
        </Group>
      </Stack>
      <form
        className="mt-2"
        onSubmit={form.onSubmit(async (values) => {
          const formValues = prepareValuesForSubmit({
            address,
            currentLocation,
          });

          await setDeliveryAddress({
            cartId: cart.id as unknown as string,
            oneTimeDeliveryAddress: {
              ...values,
              ...formValues,
            },
          });
          modalRef.current.dismiss();
        })}
      >
        <Stack>
          <Group grow wrap="nowrap">
            <TextInput
              label="Numer mieszkania"
              placeholder="Np. 12"
              {...form.getInputProps("apartmentNumber")}
            />
            <TextInput
              label="Piętro"
              placeholder="Np. 2"
              {...form.getInputProps("floor")}
            />
          </Group>
          <Textarea
            label="Dodatkowe informacje dla dostawcy"
            placeholder="Podaj ważne informacje, np. kod do klatki lub gdzie kurkier ma zostawić Twoje zakupy"
            {...form.getInputProps("additionalInformation")}
          />
        </Stack>

        <Group wrap="nowrap" className="mt-4">
          <Button type="submit" fullWidth>
            Zapisz
          </Button>
        </Group>
      </form>
    </>
  );
};

function prepareValuesForSubmit({ address, currentLocation }) {
  const splitedAddress = address.primary.split(" ");
  const [posibleHouseNumber] = splitedAddress.reverse();
  const street = posibleHouseNumber
    ? address.primary.split(" ").slice(0, splitedAddress.length - 1)
    : splitedAddress;
  const [zipNumber, ...city] = address.secondary.split(" ");
  const formValues = {
    zipNumber,
    country: "Polska",
    street: street.join(" "),
    streetNumber: posibleHouseNumber ?? "",
    city: city.join(" "),
    latitude: currentLocation.latitude,
    longitude: currentLocation.longitude,
  };

  return formValues;
}
